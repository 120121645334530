import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const CustomInputField = (props) => {
  const handleChangeText = (event) => {
    props.textChange(event.target.value);
  };

  const handleKeyUp = (event) => {
    props.keyUpEvent(event);
  };

  return (
    <TextField
      label={props.fieldName}
      type={props.fieldType}
      onChange={handleChangeText}
      value={props.fieldValue}
      helperText={props.errorMessage}
      error={props.isError}
      variant="outlined"
      className="inputFields"
      size="small"
      onKeyUp={handleKeyUp}
      fullWidth
    />
  );
};

export default CustomInputField;

CustomInputField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textChange: PropTypes.func,
  keyUpEvent: PropTypes.func,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

CustomInputField.defaultProps = {
  fieldValue: '',
  textChange: () => {},
  keyUpEvent: () => {},
  isError: false,
  errorMessage: '',
};
