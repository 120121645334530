import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { editUserDetail, hideSuccessPopUpEditUser } from "../../store/actions";
import SubTitle from "../../hoc/SubTitle";
import BlueButton from "../../hoc/BlueButton";
import CustomInputField from "../../hoc/CustomInputField";
import { altisUrl, cantonList, userID } from "../../constants";
import { GETAltis } from "../../apis/ServerApis";
import {
  checkEmailisValid,
  handleException,
  handleFailure,
  handleResponseCode,
} from "../../utility";
import BackButton from "../../hoc/BackButton";
import SuccessPopup from "../../hoc/SuccessPopup";

// eslint-disable-next-line react/prefer-stateless-function
class EditMyInfo extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    console.log(this.props.userDetail);
    this.state = {
      userData: [],
      companyName: "",
      errorCompanyName: false,
      companyNameErrorMessage: "",
      address: "",
      errorAddress: false,
      addressErrorMessage: "",
      street: "",
      city: "",
      cp: "",
      canton: "",
      email: "",
      errorEmail: false,
      emailErrorMessage: "",
      number: "",
      errorNumber: false,
      numberErrorMessage: "",
      navigateToMyAccount: false,
    };
  }

  componentDidMount() {
    const userId = localStorage.getItem(userID);

    const url = `${altisUrl}users/${userId}`;

    GETAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.data) {
            this.setState({
              userData: response.data,
              companyName: response.data.name,
              address: response.data.address,
              street: response.data.street,
              city: response.data.city,
              cp: response.data.cp,
              canton: response.data.canton,
              email: response.data.email,
              number: response.data.phone,
            });
          }
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }

  // Input field handle - CompanyName
  handleCompanyNameChange = (newValue) => {
    this.setState({
      companyName: newValue,
      errorCompanyName: false,
      companyNameErrorMessage: "",
    });
  };

  // Input field handle - Address
  handleAddressChange = (newValue) => {
    this.setState({
      address: newValue,
      errorAddress: false,
      addressErrorMessage: "",
    });
  };

  // Input field handle - Street name
  handleStreetNameChange = (newValue) => {
    this.setState({
      street: newValue,
    });
  };

  // Input field handle - City
  handleCityChange = (newValue) => {
    this.setState({
      city: newValue,
    });
  };

  // Input field handle - Street name
  handleCPChange = (newValue) => {
    this.setState({
      cp: newValue,
    });
  };

  // Input field handle - Canton
  handleCantonChange = (newValue) => {
    this.setState({
      canton: newValue,
    });
  };

  // Input field handle - Email
  handleEmailChange = (newValue) => {
    this.setState({
      email: newValue,
      errorEmail: false,
      emailErrorMessage: "",
    });
  };

  // Input field handle - Number
  handleNumberChange = (newValue) => {
    this.setState({
      number: newValue,
      errorNumber: false,
      numberErrorMessage: "",
    });
  };

  // call an api for Edit Profile & Check validation
  handleContinue = () => {
    if (this.state.companyName === "") {
      this.setState({
        errorCompanyName: true,
        companyNameErrorMessage:
          "le nom de l'entreprise ne peut pas être vide.",
      });
      return;
    }
    if (
      this.state.address === "" ||
      this.state.street === "" ||
      this.state.city === "" ||
      this.state.cp === "" ||
      this.state.canton === ""
    ) {
      this.setState({
        errorAddress: true,
        addressErrorMessage:
          "L'adresse, la rue, la ville, le cp et le canton ne peuvent pas être vides.",
      });
      return;
    }

    if (this.state.email === "") {
      this.setState({
        errorEmail: true,
        emailErrorMessage: "L'e-mail ne peut pas être vide.",
      });
      return;
    }

    if (!checkEmailisValid(this.state.email)) {
      this.setState({
        errorEmail: true,
        emailErrorMessage: "L'email n'est pas valide",
      });
      return;
    }

    if (this.state.number === "") {
      this.setState({
        errorNumber: true,
        numberErrorMessage: "Le numéro de téléphone ne peut pas être vide",
      });
      return;
    }

    const clientParams = {
      name: this.state.companyName,
      email: this.state.email,
      phone: this.state.number,
      address: this.state.address,
      street: this.state.street,
      city: this.state.city,
      cp: this.state.cp,
      canton: this.state.canton,
      representative_first_name: this.state.userData.representative_first_name,
      representative_last_name: this.state.userData.representative_last_name,
      logo: this.state.userData.logo,
    };

    // call an action method
    // eslint-disable-next-line react/prop-types
    this.props.editUserDetail(clientParams);
  };

  closeSuccessPopup = () => {
    // eslint-disable-next-line react/prop-types
    this.props.hideSuccessPopUpEditUser();
    this.setState({
      navigateToMyAccount: true,
    });
  };

  nextSuccessPopup = () => {
    // eslint-disable-next-line react/prop-types
    this.props.hideSuccessPopUpEditUser();
    this.setState({
      navigateToMyAccount: true,
    });
  };

  render() {
    if (this.state.navigateToMyAccount) {
      return <Redirect to="/myaccount" />;
    }

    return (
      <div>
        <BackButton />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="myAccountContainer"
        >
          <div
            style={{
              // eslint-disable-next-line react/prop-types
              display: this.props.isEditUser ? "" : "none",
            }}
            className="addUserContainer"
          >
            <SuccessPopup
              titleText=""
              message="Vos informations ont bien été sauvegardées. "
              partnerListLinkMessage=""
              closeBtnClick={this.closeSuccessPopup}
              nextBtnClick={this.nextSuccessPopup}
            />
          </div>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ marginTop: "3em" }}
          >
            <div className="avtarLogo" />
            <SubTitle titleText="Mon compte" customeColor="#A7B6C6" />
            <div className="grayArrowIcon" />
            <SubTitle titleText="Modifier mes informations" />
          </Grid>
          <hr
            style={{
              backgroundColor: "#FFFFFF",
              height: 1,
            }}
          />

          {/* <Grid
                        container
                        direction="column"
                        alignItems="flex-start"
                        justify="center"
                        style={{ height: "100%" }}
                        className="formContainer"
                    > */}
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            item
            xs={8}
            sm={8}
            justify="center"
            className="myInfoView"
            style={{ marginTop: "4em" }}
          >
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="left"
              justify="center"
            >
              <Grid item xs={3} sm={3}>
                <div className="myInfoLabel editInfoLabel">
                  Nom de la société *
                </div>
              </Grid>
              <Grid item xs={5} sm={8}>
                <CustomInputField
                  fieldName="Nom de la société"
                  fieldType="CompanyName"
                  fieldValue={this.state.companyName}
                  isError={this.state.errorCompanyName}
                  errorMessage={this.state.companyNameErrorMessage}
                  textChange={this.handleCompanyNameChange}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="left"
              justify="center"
              style={{ marginTop: "1em" }}
            >
              <Grid item xs={3} sm={3}>
                <div className="myInfoLabel editInfoLabel">Adresse*</div>
              </Grid>
              <Grid item xs={3} sm={3}>
                <CustomInputField
                  fieldName="Rue"
                  fieldType="Street"
                  fieldValue={this.state.street}
                  textChange={this.handleStreetNameChange}
                />
              </Grid>
              <Grid item xs={2} sm={2}>
                <div className="myInfoLabel editInfoLabel">Numéro*</div>
              </Grid>
              <Grid item xs={3} sm={3}>
                <CustomInputField
                  fieldName="Numéro"
                  fieldType="Address"
                  fieldValue={this.state.address}
                  isError={this.state.errorAddress}
                  errorMessage={this.state.addressErrorMessage}
                  textChange={this.handleAddressChange}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="left"
              justify="center"
              style={{ marginTop: "1em" }}
            >
              <Grid item xs={3} sm={3}>
                <div className="myInfoLabel editInfoLabel">Ville*</div>
              </Grid>
              <Grid item xs={2} sm={2}>
                <CustomInputField
                  fieldName="Ville"
                  fieldType="City"
                  fieldValue={this.state.city}
                  textChange={this.handleCityChange}
                />
              </Grid>

              <Grid item xs={1} sm={1}>
                <div className="myInfoLabel editInfoLabel">CP*</div>
              </Grid>
              <Grid item xs={2} sm={2}>
                <CustomInputField
                  fieldName="CP"
                  fieldType="CP"
                  fieldValue={this.state.cp}
                  textChange={this.handleCPChange}
                />
              </Grid>

              <Grid item xs={1} sm={1}>
                <div className="myInfoLabel editInfoLabel">Canton*</div>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Autocomplete
                  options={cantonList}
                  id="controlled-demo"
                  value={this.state.canton}
                  onChange={(event, newValue) => {
                    this.handleCantonChange(newValue);
                  }}
                  renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Canton"
                      className="inputFields"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="left"
              justify="center"
              style={{ marginTop: "1em" }}
            >
              <Grid item xs={3} sm={3}>
                <div className="myInfoLabel editInfoLabel">
                  E-mail de contact*
                </div>
              </Grid>
              <Grid item xs={3} sm={3}>
                <CustomInputField
                  fieldName="E-mail de contact"
                  fieldType="Email"
                  fieldValue={this.state.email}
                  isError={this.state.errorEmail}
                  errorMessage={this.state.emailErrorMessage}
                  textChange={this.handleEmailChange}
                />
              </Grid>
              <Grid item xs={2} sm={2}>
                <div className="myInfoLabel editInfoLabel">Numéro de Tél.*</div>
              </Grid>
              <Grid item xs={3} sm={3}>
                <CustomInputField
                  fieldName="Numéro de Tél"
                  fieldType="Phone"
                  fieldValue={this.state.number}
                  isError={this.state.errorNumber}
                  errorMessage={this.state.numberErrorMessage}
                  textChange={this.handleNumberChange}
                />
              </Grid>
            </Grid>

            <div
              className="formLabelPopup"
              style={{ marginTop: "1em", color: "#FFFFFF" }}
            >
              Tous les champs marqués d’une * sont obligatoires
            </div>
            <div className="myInfoRow" style={{ marginLeft: "0px" }}>
              <BlueButton
                btnName="Valider"
                className="marginLeftZero"
                btnClick={this.handleContinue}
              />
            </div>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </div>
    );
  }
}

// Get userDetail object from Redux Store
const mapStateToProps = (state) => ({
  userDetail: state.user.userDetail,
  isEditUser: state.user.isEditUser,
});

// call an action for redux
const mapDispatchToProps = (dispatch) => ({
  editUserDetail: (userData) => dispatch(editUserDetail(userData)),
  hideSuccessPopUpEditUser: () => dispatch(hideSuccessPopUpEditUser()),
});

EditMyInfo.propTypes = {
  userDetail: PropTypes.objectOf(PropTypes.any),
};

EditMyInfo.defaultProps = {
  userDetail: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMyInfo);
