import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const borderButton = (props) => {
    const handleButtonClick = () => {
        props.btnClick();
    };

    return (
        <Button
            variant="outlined"
            className="borderButton"
            onClick={handleButtonClick}
            fullWidth={props.showFullWidth}
        >
            {props.btnName}
        </Button>
    );
};

export default borderButton;
borderButton.propTypes = {
    btnName: PropTypes.string.isRequired,
    btnClick: PropTypes.func.isRequired,
    showFullWidth: PropTypes.bool,
    endBtnIcon: PropTypes.element,
};

borderButton.defaultProps = {
    showFullWidth: false,
    endBtnIcon: null,
};
