/* eslint-disable no-param-reassign */
/* eslint-disable use-isnan */
/* eslint-disable prefer-destructuring */
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slider from "@material-ui/core/Slider";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import swal from "sweetalert";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";

import { changeStepTwoShow, changeStepThreeShow } from "../../store/actions";
import GrayButton from "../../hoc/GrayButton";
import BlueButton from "../../hoc/BlueButton";
import {
  handleResponseCode,
  handleException,
  AntSwitch,
  showAlert,
  stringToDate,
  renderMonthDropDown,
  setDateYYYYMMDD,
  showSpinner,
  handleFailure,
  checkLeapYears,
} from "../../utility";
import {
  altisUrl,
  selectedClientPartner,
  roleID,
  firstStep,
  applicationSetting,
  guId,
  forecastIds,
} from "../../constants";
import { GETAltis, POSTAltis } from "../../apis/ServerApis";
import LineChart from "../../hoc/LineChart";

const daysOfWeek = ["JU", "NE", "VD", "GE", "BE", "FR", "VS"];
const currentYears = new Date().getFullYear() - 1;
class HistoryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDay: "JU",
      isUTCTime: false,
      isHourlyInterval: false,
      isUnitKW: false,
      waitingYears: ["", ""],
      isManual: false,
      showHistory: false,
      // historyData: [],
      yearRange: [currentYears - 1, currentYears],
      manual: "", // It must be an integer & >= 1
      historyData: [],
      selectedYear: 2018,
      selectedMonth: 1,
    };
  }

  handleTimeChange = () => {
    this.setState((prevState) => ({ isUTCTime: !prevState.isUTCTime }));
  };

  weekDaySelected = (e) => {
    this.setState({
      selectedDay: e.target.innerText,
    });
  };

  handleHourlyIntervalChange = () => {
    this.setState((prevState) => ({
      isHourlyInterval: !prevState.isHourlyInterval,
    }));
  };

  handleUnitChange = () => {
    this.setState((prevState) => ({ isUnitKW: !prevState.isUnitKW }));
  };

  waitingYearChanged = (e) => {
    const index = Number(e.target.id);
    if (this.state.waitingYears.length === 2) {
      this.handleForTwoYearWeighting(index, e.target.value);
    } else {
      this.handleForThreeYearWeighting(index, e.target.value);
    }
  };

  // If Year Range = 2 years, so only 1 textfield can editable & 1 read only
  handleForTwoYearWeighting = (index, value) => {
    let zeroThValue = 0;
    let firstValue = 0;

    // Replace comma to . as per number format
    // 0th Index + 1st Index must === 1.0
    // so we have to automatically update 1stnd Index Value according to 0th

    zeroThValue = value.replace(",", ".");

    if (zeroThValue !== NaN) {
      if (zeroThValue === "0.") {
        zeroThValue = 0.0;
      }

      firstValue = 1.0 - Number(zeroThValue);

      firstValue = firstValue.toFixed(1).toString().replace(".", ",");
    }

    // const 2nd value = 1.0
    // eslint-disable-next-line react/no-access-state-in-setstate
    const waitingYears = [...this.state.waitingYears];
    waitingYears[index] = value;
    waitingYears[1] = firstValue;
    this.setState({ waitingYears });
  };

  // If Year Range = 3 years, so 2 textfield can editable & 1 read only
  handleForThreeYearWeighting = (index, value) => {
    let zeroThValue = 0;
    let firstValue = 0;
    let secondValue = 0;

    // Replace comma to . as per number format
    // 0th Index + 1st Index + 2nd Index must === 1.0
    // so we have to automatically update 2nd Index Value according to 0th and 1st
    if (index === 1) {
      firstValue = value.replace(",", ".");
      zeroThValue = this.state.waitingYears[0].replace(",", ".");
    } else {
      firstValue = this.state.waitingYears[1].replace(",", ".");
      zeroThValue = value.replace(",", ".");
    }
    if (firstValue !== NaN && zeroThValue !== NaN) {
      if (firstValue === "0.") {
        firstValue = 0.0;
      }
      if (zeroThValue === "0.") {
        zeroThValue = 0.0;
      }
      const totalValue = Number(zeroThValue) + Number(firstValue);
      secondValue = 1.0 - totalValue;
      console.log(" Values:", secondValue);
      secondValue = secondValue.toFixed(1).toString().replace(".", ",");
    }

    // const 2nd value = 1.0
    // eslint-disable-next-line react/no-access-state-in-setstate
    const waitingYears = [...this.state.waitingYears];
    waitingYears[index] = value;
    waitingYears[2] = secondValue;
    this.setState({ waitingYears });
  };

  handleManualChange = () => {
    this.setState((prevState) => ({ isManual: !prevState.isManual }));
  };

  // Manual Textfield
  handleManualValue = (e) => {
    const { value } = e.target;

    if (value === "") {
      this.setState({ manual: "1" });
    } else if (value.match(".")) {
      // eslint-disable-next-line radix
      this.setState({ manual: parseInt(value) });
    }
  };

  handleVisualiserHistorique = () => {
    const userId = JSON.parse(localStorage.getItem(selectedClientPartner)).id;

    let url = `${altisUrl}histories/get?client_id=${userId}`; // For Partner fetch clients

    // If Login user = Admin then fetch  Partner List
    if (localStorage.getItem(roleID) === "1") {
      url = `${altisUrl}histories/get?user_id=${userId}`;
    }

    this.setState({
      historyData: [],
    });
    this.getHistoryDataByMonth(
      url,
      1,
      this.state.yearRange.length > 0 ? this.state.yearRange[0] : "",
      "-1",
      "-1",
      [],
    );
    // this.getHistoryDataByMonth(url, 12, 2019, "-1", "-1", []);
  };

  getHistoryDataByMonth = (
    url,
    currentMonth,
    currentYear,
    lastMonth,
    lastYear,
    data,
  ) => {
    let historyUrl = url;
    if (currentMonth !== "") {
      historyUrl = `${url}&year=${currentYear}&month=${currentMonth}`;
    }

    historyUrl += `&duration=${
      this.state.isHourlyInterval ? "60min" : "15min"
    }`;

    GETAltis(historyUrl)
      .then((response) => {
        // console.log(response);
        if (handleResponseCode(response)) {
          if (response && response.data) {
            if (response.data && response.data.length) {
              data = data.concat(response.data);
            }

            if (currentMonth === "") {
              currentMonth = response.month;
              currentYear = response.year;
            }

            // if (response.last_month) {
            //     lastMonth = response.last_month;
            // }

            // if (response.last_year) {
            //     lastYear = response.last_year;
            // }

            if (this.state.yearRange.length > 1) {
              lastYear = this.state.yearRange[1];
            }
            lastMonth = 12;

            if (
              lastYear === "-1" ||
              lastMonth === "-1" ||
              lastYear === null ||
              lastMonth === null ||
              (lastYear === currentYear && lastMonth === currentMonth)
            ) {
              let firstYear = 2018;
              let firstMonth = 1;

              if (data.length) {
                firstYear = new Date(data[0].datetime).getFullYear();
                firstMonth = new Date(data[0].datetime).getMonth() + 1;
                // firstYear = stringToDate(data[0].datetime).getFullYear();
                // firstMonth = stringToDate(data[0].datetime).getMonth() + 1;
              }

              if (data && data.length) {
                this.setState({
                  historyData: data,
                  showHistory: true,
                  selectedYear: firstYear,
                  selectedMonth: firstMonth,
                });
              } else {
                showAlert(
                  "Pas de données disponibles",
                  "Veuillez importer des données à l'aide d'un fichier csv.",
                );
              }
            } else {
              currentMonth += 1;
              if (currentMonth === 13) {
                currentYear += 1;
                currentMonth = 1;
              }

              this.getHistoryDataByMonth(
                url,
                currentMonth,
                currentYear,
                lastMonth,
                lastYear,
                data,
              );
            }
          }
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  handleImportHistoriqueData = () => {
    // eslint-disable-next-line no-alert
    document.getElementById("importFile").click();
  };

  handleFileChange = (e) => {
    const fr = new FileReader();
    let recordValueType = 0;
    fr.onload = () => {
      const results = fr.result;

      const data = results.split(/\r\n|\n/);

      data.splice(0, 1);
      const jsonData = [];
      
      data.forEach((record) => {
        const recordValue = record.split(";")[1];
        const checkIntorFloatValue = Number.isInteger(recordValue) || /\d+\.\d+/.test(recordValue)
        if(!checkIntorFloatValue && record !== ""){ // record === " " comes at last line of file
            recordValueType += 1;
        }
        jsonData.push({
          datetime: record.split(";")[0],
          value: recordValue,
        });
      });

      const url = `${altisUrl}histories/store`;

      const clientParams = {};
      if (localStorage.getItem(roleID) === "1") {
        clientParams.user_id = JSON.parse(
          localStorage.getItem(selectedClientPartner),
        ).id;
      } else {
        clientParams.client_id = JSON.parse(
          localStorage.getItem(selectedClientPartner),
        ).id;
      }
      
      if(recordValueType > 0){
        showAlert("Avertissement", "Le fichier fourni contient des données manquantes ou incorrectes ou des dates érronées"); 
      } else {
        this.uploadMonthlyHistories(jsonData, 0, url, clientParams);
      }
      
    };

    fr.readAsText(e.target.files[0]);
  };

  uploadMonthlyHistories = (data, startIndex, url, clientParams) => {
    const failureMessages =
      "Veuillez vérifier le formatage de votre fichier. \n datetime : 01.01.2019 00:15  \n conso_kWh : 3.525";
      const missingDataMessage =
      "Le fichier fourni contient des données manquantes ou incorrectes ou des dates érronées";
    if (data.length <= startIndex) {
      return;
    }

    const firstElementDate = stringToDate(data[startIndex].datetime);
    const lastElementDate = data[data.length - 2].datetime;
    const currentYear = new Date().getFullYear();
  
    const totalYears = this.state.yearRange[1] - this.state.yearRange[0] + 1;
    const totalLeapYears = checkLeapYears(this.state.yearRange[0],this.state.yearRange[1]);
    
    // const totalYears = lastElementDate.getFullYear() - firstElementDate.getFullYear() + 1;
    // const totalLeapYears = checkLeapYears(firstElementDate.getFullYear(),lastElementDate.getFullYear());
    console.log(' total files record = ', data.length);
    
    if(this.state.isHourlyInterval){
      const mustTotalRecords = (365*24*totalYears) + (24*totalLeapYears);
      console.log('must total records :::: ', mustTotalRecords);
      if (mustTotalRecords !== data.length - 1 && lastElementDate === `${currentYear}-01-01 00:00:00+01:00`){
        showAlert("Avertissement", missingDataMessage); 
        return;
      }
    } else {
      const mustTotalRecords = (365*24*4*totalYears) + (24*4*totalLeapYears);
      console.log('must total records :::: ', mustTotalRecords);
      if (mustTotalRecords !== data.length -1 && lastElementDate === `${currentYear}-01-01 00:00:00+01:00`){
        showAlert("Avertissement", missingDataMessage);
        return;
      }
    }
    
    clientParams.year = firstElementDate.getFullYear();
    const month = firstElementDate.getMonth() + 1;
    const currentMonthData = [];
    const floatRegex = /^[+-]?\d+(\.\d+)?$/;
    while (
      data[startIndex] &&
      month === stringToDate(data[startIndex].datetime).getMonth() + 1
    ) {
      data[startIndex].datetime = setDateYYYYMMDD(
        stringToDate(data[startIndex].datetime),
      );

      if (!floatRegex.test(data[startIndex].value)) {
        showAlert("Erreur", failureMessages);

        // eslint-disable-next-line consistent-return
        return false;
      }

      data[startIndex].value = parseFloat(data[startIndex].value);
      currentMonthData.push(data[startIndex]);
      startIndex += 1;
    }

    console.log("currentMonthData: ", currentMonthData);

    clientParams.data = currentMonthData;
    clientParams.month = month;
    clientParams.duration = this.state.isHourlyInterval ? "60min" : "15min";

    if (
      startIndex !== 0 &&
      (!clientParams.data || clientParams.month.toString() === "NaN")
    ) {
      showAlert("Succès", "Fichier téléchargé avec succès");
      return;
    }

    POSTAltis(url, clientParams)
      .then((response) => {
        if (handleResponseCode(response)) {
          if (response && response.data) {
            this.uploadMonthlyHistories(data, startIndex, url, clientParams);
          }
        } else {
          // Swal.fire({
          //     text: "your custom image",
          //     // eslint-disable-next-line global-require
          //     imageUrl:{require("./images/csvformat.png")},
          //     imageWidth: 400,
          //     imageHeight: 200,
          //     // background: '#fff url(/images/csvformat.png)',
          //   });
          showAlert("Erreur", failureMessages);
          // handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  closeGraphPopup = () => {
    this.setState({
      showHistory: false,
      historyData: [],
    });
  };

  handleYearChange = (event, newValue) => {
    console.log("newValue :", newValue);

    if (newValue[1] === currentYears) {
      let weightingYearFields = [];
      if (newValue[0] === newValue[1]) {
        weightingYearFields = ["1,0"];
      } else if (newValue[1] - newValue[0] === 1) {
        weightingYearFields = ["0,2", "0,8"];
      } else {
        weightingYearFields = ["0,2", "0,3", "0,5"];
      }

      this.setState({
        yearRange: newValue,
        waitingYears: weightingYearFields,
      });
    }
  };

  handleCalculateOffer = () => {
    console.log(localStorage.getItem(applicationSetting));

    if(this.state.waitingYears[0] === ""){
      swal("Veuillez remplir la pondération des années historiques", "", "error");
      return;
    }

    // If loggedIn User = Admin then send user_Id of selected partner
    // If loggedIn User = Partner then send client_id of selected client
    let userId = null;
    let clientId = null;

    // If Login user = Partner then
    if (localStorage.getItem(roleID) === "2") {
      clientId = JSON.parse(localStorage.getItem(selectedClientPartner)).id;
    } else {
      userId = JSON.parse(localStorage.getItem(selectedClientPartner)).id;
    }
    const url = `${altisUrl}calculate/forecast`;
    const dict = {
      user_id: userId,
      client_id: clientId,
      first_year: this.state.yearRange[0],
      last_year: this.state.yearRange[1],
      timezone: this.state.isUTCTime ? "UTC" : "local",
      bank_holidays: this.state.selectedDay,
      granularity: this.state.isHourlyInterval ? "60min" : "15min",
      unit: this.state.isUnitKW ? "kW" : "kWh",
      weighting: this.state.waitingYears.map((e) => {
        return parseFloat(e.replace(",", "."));
      }),
      yearly_sum: this.state.isManual ? this.state.manual : 0,
      search_window: JSON.parse(localStorage.getItem(applicationSetting))
        .search_reference_days,
      outlier_factor: parseFloat(
        JSON.parse(localStorage.getItem(applicationSetting)).outlier_factor,
      ),
    };
    localStorage.setItem(firstStep, JSON.stringify(dict));
    console.log("dict 1st step :", dict);
    // eslint-disable-next-line react/prop-types
    this.props.changeStepTwoShow(false);
    // eslint-disable-next-line react/prop-types
    this.props.changeStepThreeShow(false);
    POSTAltis(url, dict)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          // {guuid: "60bb4e72f16fe", forecast_ids: Array(8)}
          localStorage.setItem(guId, response.data.guuid);
          localStorage.setItem(forecastIds, response.data.forecast_ids);
          // eslint-disable-next-line react/prop-types
          this.props.changeStepTwoShow(true);
        } else if (response.success && response.data === null) {
          swal("Veuillez vérifier les informations et paramètres", "", "error");
          // eslint-disable-next-line react/prop-types
          this.props.changeStepTwoShow(false);
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  renderYearDropDown = () => {
    if (!this.state.historyData.length)
      return (
        <option value="" key={-1}>
          {" "}
        </option>
      );

    let firstYear = new Date(this.state.historyData[0].datetime).getFullYear();
    const lastYear = new Date(
      this.state.historyData[this.state.historyData.length - 1].datetime,
    ).getFullYear();

    const yearOptions = [];
    do {
      console.log("firstYear: ", firstYear);
      yearOptions.push(firstYear);
      firstYear = 1 + firstYear;
    } while (firstYear < lastYear + 1);

    return yearOptions.map((data) => {
      return (
        <option value={data} key={`year${data}`}>
          {data}
        </option>
      );
    });
  };

  handleYearDropdownChange = (e) => {
    showSpinner(true);
    const self = this;
    setTimeout(() => {
      self.setState({
        selectedYear: e.target.value,
      });
    }, 100);
  };

  handleMonthDropdownChange = (e) => {
    showSpinner(true);
    const self = this;
    setTimeout(() => {
      self.setState({
        selectedMonth: e.target.value,
      });
    }, 100);
    // this.setState({
    //     selectedMonth: e.target.value,
    // });
  };

  // Show Alert when click on Info button for CSV
  showInfoForCSVUpload = () => {
    // const el = document.createElement('div')
    // el.innerHTML = "Here's a <a href='http://google.com'>link</a>"
    // swal({
    //     title: "Hello!",
    //     content: el,
    //   })
    swal("Veuillez-vous assurer que votre fichier soit correctement formaté", {
      buttons: {
        catch: {
          text: "Cliquez ici pour télécharger le modèle de 15 minutes",
          value: "catch",
        },
        catch60: {
          text: "Cliquez ici pour télécharger le modèle de 60 minutes",
          value: "catch60",
        },
        cancel: "Annuler",
      },
    }).then((value) => {
      switch (value) {
        case "catch":
          // eslint-disable-next-line no-case-declarations
          const link = document.createElement("a");
          link.download = "Sample_15minutes.csv";
          link.href = "./documents/template_15minutes.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          break;
        case "catch60":
          // eslint-disable-next-line no-case-declarations
          const links = document.createElement("a");
          links.download = "Sample_60minutes.csv";
          links.href = "./documents/template_60minutes.csv";
          document.body.appendChild(links);
          links.click();
          document.body.removeChild(links);
          break;

        default:
          break;
      }
    });
  };

  render() {
    console.log("this.state.yearRange: ", this.state.yearRange);
    const weekDaysList = daysOfWeek.map((weekDay) => {
      return (
        <Button
          className={
            this.state.selectedDay === weekDay
              ? "selectedBlock"
              : "unselectedBlock"
          }
          onClick={this.weekDaySelected}
        >
          {weekDay}
        </Button>
      );
    });

    const waitingYearsList = this.state.waitingYears.map((value, index) => {
      return index === this.state.waitingYears.length - 1 ? (
        <OutlinedInput
          placeholder="ex: 0,2"
          className="waightingYearInput"
          id={index}
          value={value}
          labelWidth={0}
          readOnly
        />
      ) : (
        <OutlinedInput
          placeholder="ex: 0,8"
          className="waightingYearInput"
          id={index}
          value={value}
          onChange={this.waitingYearChanged}
          labelWidth={0}
        />
      );
    });

    console.log("historyData: ", this.state.historyData);

    const chartData = [];

    this.state.historyData.forEach((data) => {
      if (
        new Date(data.datetime).getFullYear() === this.state.selectedYear &&
        new Date(data.datetime).getMonth() + 1 === this.state.selectedMonth
      ) {
        chartData.push({
          x: data.datetime,
          y: data.value,
        });
      }
    });

    chartData.splice(7000);

    console.log("chartData: ", chartData);

    const currentYear = new Date().getFullYear() - 1;
    const yearMarks = [];

    for (let i = currentYear - 2; i <= currentYear; i += 1) {
      yearMarks.push({
        value: i,
        label: i.toString(),
      });
    }

    const yearMonth = (
      <Grid
        item
        xs={10}
        sm={10}
        style={{
          marginLeft: "auto",
          marginRight: "1px",
          marginTop: "-0.5em",
        }}
      >
        <div className="yearSelectHistoryContainer">
          <span>Année</span>
          <Select
            variant="outlined"
            className="manualInputSelect yearSelectHistory"
            size="small"
            value={this.state.selectedYear}
            onChange={this.handleYearDropdownChange}
          >
            {this.renderYearDropDown()}
          </Select>
        </div>

        <div className="yearSelectHistoryContainer">
          <span>Mois</span>
          <Select
            variant="outlined"
            className="manualInputSelect yearSelectHistory"
            size="small"
            value={this.state.selectedMonth}
            onChange={this.handleMonthDropdownChange}
          >
            {renderMonthDropDown()}
          </Select>
        </div>
      </Grid>
    );

    // const sliderWidth = (currentYear - 2018) * 90;
    const sliderWidth = (currentYear - (currentYear - 2)) * 90;
    console.log("----> currentYear: ", currentYear);
    console.log("----> yearMarks: ", yearMarks);

    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: this.state.showHistory ? "" : "none",
            paddingTop: "1em",
          }}
          className="graphDiv"
        >
          {yearMonth}
          <LineChart
            chartData={chartData}
            closeBtnClick={this.closeGraphPopup}
            title="Historique"
            xTitle="Date et heure"
            yTitle="Consommation (kWh)"
          />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="left"
          justify="center"
        >
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Première année</div>
            </Grid>
            <Grid>
              <div
                style={{
                  marginLeft: "15px",
                  width: `${sliderWidth}px`,
                }}
              >
                <Slider
                  value={this.state.yearRange}
                  onChange={this.handleYearChange}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  marks={yearMarks}
                  min={currentYear - 2}
                  max={currentYear}
                />
              </div>
            </Grid>
          </Grid>

          {/* <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Horodatage</div>
            </Grid>
            <Grid>
              <Grid component="label" container alignItems="center" spacing={0}>
                <Grid
                  className={
                    this.state.isUTCTime
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingRight: "2em" }}
                >
                  Heure locale
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={this.state.isUTCTime}
                    onChange={this.handleTimeChange}
                  />
                </Grid>
                <Grid
                  className={
                    !this.state.isUTCTime
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingLeft: "2em" }}
                >
                  UTC
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Région jours feriés</div>
            </Grid>
            <Grid>{weekDaysList}</Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Granularité</div>
            </Grid>
            <Grid>
              <Grid component="label" container alignItems="center" spacing={0}>
                <Grid
                  className={
                    this.state.isHourlyInterval
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingRight: "2em" }}
                >
                  15 Minutes
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={this.state.isHourlyInterval}
                    onChange={this.handleHourlyIntervalChange}
                  />
                </Grid>
                <Grid
                  className={
                    !this.state.isHourlyInterval
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingLeft: "2em" }}
                >
                  Horaire
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Unité</div>
            </Grid>
            <Grid>
              <Grid component="label" container alignItems="center" spacing={0}>
                <Grid
                  className={
                    this.state.isUnitKW
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingRight: "2em" }}
                >
                  kWh
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={this.state.isUnitKW}
                    onChange={this.handleUnitChange}
                  />
                </Grid>
                <Grid
                  className={
                    !this.state.isUnitKW
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingLeft: "2em" }}
                >
                  kW
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">
                Pondération années historiques
              </div>
            </Grid>
            <Grid>
              {waitingYearsList}
              {/* <span
                                className="formLabelGreySteps"
                                style={{ fontSize: "0.7em", marginLeft: "3em" }}
                            >
                                La somme doit être égale à 1 !
                            </span> */}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Somme annuelle</div>
            </Grid>
            <Grid>
              <Grid component="label" container alignItems="center" spacing={0}>
                <Grid
                  className={
                    this.state.isManual
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingRight: "2em" }}
                >
                  Automatique
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={this.state.isManual}
                    onChange={this.handleManualChange}
                  />
                </Grid>
                <Grid
                  className={
                    !this.state.isManual
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingLeft: "2em" }}
                >
                  Manuelle
                </Grid>
                <OutlinedInput
                  placeholder="ex: 10000"
                  className="manualInput"
                  disabled={!this.state.isManual}
                  value={this.state.manual}
                  onChange={this.handleManualValue}
                  endAdornment={
                    <InputAdornment position="end">kWh</InputAdornment>
                  }
                  labelWidth={0}
                />
              </Grid>
            </Grid>
            {/* <Grid style={{ marginLeft: "auto" }}>
              <GrayButton
                btnName="Visualiser historique"
                btnClick={this.handleVisualiserHistorique}
              />
            </Grid> */}
          </Grid>

          <Grid style={{ marginTop: "1em", marginRight: "-8px" }}>
            <div style={{ float: "right" }}>
              <GrayButton
                btnName="Importer une courbe de charge"
                btnClick={this.handleImportHistoriqueData}
              />
              <input
                type="file"
                id="importFile"
                onChange={this.handleFileChange}
                style={{ display: "none" }}
                accept=".csv"
                // accept=".json"
              />
            </div>
            <div style={{ float: "right", marginRight: "1em" }}>
              <IconButton
                color="primary"
                aria-label="edit"
                onClick={this.showInfoForCSVUpload}
                className="infoBtn"
                style={{ marginLeft: "1em" }}
              >
                <InfoIcon />
              </IconButton>
            </div>
          </Grid>
          {/* <div style={{ marginTop: "2em" }}>
                        <IconButton
                            color="primary"
                            aria-label="edit"
                            onClick={this.editContact}
                            className="infoBtn"
                        >
                            <InfoIcon />
                        </IconButton>
                        <GrayButton
                            btnName="Importer une courbe de charge"
                            btnClick={this.handleImportHistoriqueData}
                        />
                        <input
                            type="file"
                            id="importFile"
                            onChange={this.handleFileChange}
                            style={{ display: "none" }}
                            accept=".csv"
                            // accept=".json"
                        />
                    </div> */}
        </Grid>
        <div className="stepSaveButton">
          <BlueButton
            btnName="Etape suivante"
            btnClick={this.handleCalculateOffer}
          />
        </div>
      </div>
    );
  }
}

// export default HistoryData;
// call an action for redux
const mapDispatchToProps = (dispatch) => ({
  changeStepTwoShow: (isShow) => dispatch(changeStepTwoShow(isShow)),
  changeStepThreeShow: (isShow) => dispatch(changeStepThreeShow(isShow)),
});

export default connect(null, mapDispatchToProps)(HistoryData);
