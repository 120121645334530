// Action name as a constant
export const GET_USER_DETAIL_START = "GET_ACCOUNT_DETAIL_START";
export const GET_USER_DETAIL_SUCCESS = "GET_ACCOUNT_DETAIL_SUCCESS";
export const GET_USED_DETAIL_FAIL = "GET_ACCOUNT_DETAIL_FAIL";
export const EDIT_USER_DETAIL_SUCCESS = "EDIT_ACCOUNT_DETAIL_SUCCESS";
export const HIDE_SUCCESSPOPUP_EDIT_USER =  "HIDE_SUCCESSPOPUP_EDIT_USER";
export const LOGOUT = "LOGOUT";

export const HIDE_SHOW_STEP_ONE = "HIDE_SHOW_STEP_ONE";
export const HIDE_SHOW_STEP_TWO = "HIDE_SHOW_STEP_TWO";
export const HIDE_SHOW_STEP_THREE = "HIDE_SHOW_STEP_THREE";
