import { Button } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";

import { changeStepTwoShow, changeStepThreeShow } from "../store/actions";
import {
    firstStep,
    guId,
    forecastIds,
    showSummerWinter,
    offerData,
} from "../constants";

class LeftSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigateToMyAccount: false,
            navigateToUserSelection: false,
        };
    }

    openMyAccount = () => {
        this.setState({
            navigateToMyAccount: true,
        });
    };

    openUserSelection = () => {
        this.removeLocalStorageField();
        // eslint-disable-next-line react/prop-types
        this.props.changeStepTwoShow(false);
        // eslint-disable-next-line react/prop-types
        this.props.changeStepThreeShow(false);
        this.setState({
            navigateToUserSelection: true,
        });
    };

    removeLocalStorageField = () => {
        localStorage.removeItem(firstStep);

        localStorage.removeItem(guId);
        localStorage.removeItem(forecastIds);
        localStorage.removeItem(offerData);
        localStorage.removeItem(showSummerWinter);
    };

    render() {
        if (this.state.navigateToMyAccount) {
            window.location.href = "#/myaccount";
            this.setState({
                navigateToMyAccount: false,
            });
        }
        if (this.state.navigateToUserSelection) {
            window.location.href = "#/userselection";
            this.setState({
                navigateToUserSelection: false,
            });
        }
        return (
            <div className="leftSideBar">
                <Button className="sideLogo" onClick={this.openUserSelection} />
                <Button
                    className="sideSettingLogo"
                    onClick={this.openMyAccount}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    changeStepTwoShow: (isShow) => dispatch(changeStepTwoShow(isShow)),
    changeStepThreeShow: (isShow) => dispatch(changeStepThreeShow(isShow)),
});
export default connect(null, mapDispatchToProps)(LeftSideBar);
