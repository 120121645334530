import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AppLogo from "../../hoc/AppLogo";
import SubTitle from "../../hoc/SubTitle";
import BlueButton from "../../hoc/BlueButton";
import GrayButton from "../../hoc/GrayButton";
import {
    altisUrl,
    roleID,
    userID,
    selectedClientPartner,
    loginUserName,
} from "../../constants";
import { GETAltis } from "../../apis/ServerApis";
import {
    handleResponseCode,
    handleException,
    handleFailure,
} from "../../utility";
import AddClient from "../Client/AddClient";
import AddPartner from "./AddPartner";
import SuccessPopup from "../../hoc/SuccessPopup";

class PartnerSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerClientName: "",
            navigateToAddClient: false,
            navigateToSuccessPopup: false,
            navigateToHomeScreen: false,
            partnerClientsData: [],
            navigateToPartnerList: false,
        };
    }

    componentDidMount() {
        const userId = localStorage.getItem(userID);
        let url = `${altisUrl}clients?user_id=${userId}`; // For Partner fetch clients
        // If Login user = Admin then fetch  Partner List
        if (localStorage.getItem(roleID) === "1") {
            url = `${altisUrl}users?user_id=${userId}`;
        }

        // const url = `${altisUrl}clients?user_id=${userId}`;

        GETAltis(url)
            .then((response) => {
                console.log(response);
                if (handleResponseCode(response)) {
                    console.log(response.data);
                    this.setState({
                        partnerClientsData: response.data,
                    });
                } else {
                    handleFailure(response);
                }
            })
            .catch((response) => {
                handleException(response);
            });
    }

    // Select a Partner or Client from drop down
    handleDropdownChange = (event) => {
        this.setState({ partnerClientName: event.target.value });
    };

    // handle button event
    handleContinue = () => {
        localStorage.removeItem(selectedClientPartner);
        const SelectedClientPartner = this.state.partnerClientsData.find(
            (element) => element.name === this.state.partnerClientName
        );
        localStorage.setItem(
            selectedClientPartner,
            JSON.stringify(SelectedClientPartner)
        );

        if (this.state.partnerClientName !== "") {
            this.setState({
                navigateToHomeScreen: true,
            });
        } else {
            // eslint-disable-next-line no-alert
            alert("Please select client name");
        }
    };

    // Navigate to add client screen
    handleAddClient = () => {
        this.setState({
            navigateToAddClient: true,
        });
    };

    userCreatedSuccessfully = (userData) => {
        localStorage.removeItem(selectedClientPartner);
        console.log("Added : ", userData);
        localStorage.setItem(selectedClientPartner, JSON.stringify(userData));
        this.setState({
            navigateToAddClient: false,
            navigateToSuccessPopup: true,
        });
    };

    closePopupAddClient = () => {
        this.setState({
            navigateToAddClient: false,
        });
    };

    closeSuccessPopup = () => {
        this.setState({
            navigateToSuccessPopup: false,
        });
    };

    nextSuccessPopup = () => {
        this.setState({
            navigateToSuccessPopup: false,
            navigateToHomeScreen: true,
        });
    };

    showPartnerList = () => {
        this.setState({
            navigateToSuccessPopup: false,
            navigateToPartnerList: true,
        })
    }

    // render dropdown according to clients which is fetched by an api
    renderDropDown() {
        return this.state.partnerClientsData.map((data) => {
            return (
                <MenuItem value={data.name} key={data.id}>
                    {data.name}
                </MenuItem>
            );
        });
    }

    // render AdminLogo if user is Admin
    // eslint-disable-next-line class-methods-use-this
    renderAdminLogo() {
        
        if(this.props.userDetail){
            return (
                <div
                    className="altisLogo"
                    // eslint-disable-next-line no-undef
                    style={{ backgroundPosition: "right", backgroundImage: `url(${this.props.userDetail.logo})`}}
                />
                   
            );
        }
        return <div/>;
        
    }

    // render Add Partner or Add Client Screen according to userRole
    renderAddPartnerClient() {
        if (localStorage.getItem(roleID) === "2") {
            return (
                <AddClient
                    closeBtnClick={this.closePopupAddClient}
                    userCreatedSuccessfully={this.userCreatedSuccessfully}
                />
            );
        }
        return (
            <AddPartner
                closeBtnClick={this.closePopupAddClient}
                userCreatedSuccessfully={this.userCreatedSuccessfully}
            />
        );
    }

    render() {
        let loginUser = localStorage.getItem(loginUserName);
       
        if (this.state.navigateToHomeScreen) {
            return <Redirect to="/home" />;
        }
        if(this.state.navigateToPartnerList){
            return <Redirect to="/mycontacts" />;
        }
        if (loginUser === null) {
            loginUser = "";
        }
        const userRole = localStorage.getItem(roleID);
        console.log(" UserRole : ", userRole);
        let txtSubtitle = `Bienvenue, ${loginUser}`;
        let txtDropdownSelection = "Sélectionnez votre partenaire";
        let btnAdd = "Ajouter un nouveau partenaire";
        let addPartnerClientSuccessMessage = "Ajouter une courbe de charge pour ce partenaire";
        let partnerListLinkMessage = "Les paramètres d’application par défaut sont enregistrés pour ce partenaire, pour modifier les paramètres veuillez cliquer-ici";
        if (userRole === "2") {
            txtSubtitle = `Bienvenue, ${loginUser}`;
            txtDropdownSelection = "Sélectionnez votre client";
            btnAdd = "Ajouter un nouveau client";
            addPartnerClientSuccessMessage = "Ajouter une courbe de charge pour ce client"
            partnerListLinkMessage = "";
        }
        return (
            <div>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: "100vh" }}
                    className="formContainer"
                >
                    <div
                        style={{
                            display: this.state.navigateToAddClient
                                ? ""
                                : "none",
                        }}
                        className="addUserContainer"
                    >
                        {this.renderAddPartnerClient()}
                    </div>
                    <div
                        style={{
                            display: this.state.navigateToSuccessPopup
                                ? ""
                                : "none",
                        }}
                        className="addUserContainer"
                    >
                        <SuccessPopup
                            titleText="Votre nouveau client a bien été pris en compte."
                            message={addPartnerClientSuccessMessage}
                            partnerListLinkMessage={partnerListLinkMessage}
                            closeBtnClick={this.closeSuccessPopup}
                            nextBtnClick={this.nextSuccessPopup}
                            partnerListBtnClicked={this.showPartnerList}
                        />
                    </div>
                    <AppLogo />
                    <SubTitle titleText="COSTING PLATFORM" />
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        xs={6}
                        style={{ marginTop: "3em" }}
                    >
                        <Grid item xs={7} sm={7} style={{ textAlign: "left" }}>
                            <SubTitle titleText={txtSubtitle} />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            {this.renderAdminLogo()}
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        xs={6}
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={4} sm={4}>
                            <div className="formLabel">
                                {txtDropdownSelection}
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Select
                                variant="outlined"
                                className="inputFields"
                                size="small"
                                fullWidth
                                value={this.state.partnerClientName}
                                onChange={this.handleDropdownChange}
                            >
                                {this.renderDropDown()}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        xs={6}
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={8} sm={8} alignItems="right">
                            <GrayButton
                                btnName={btnAdd}
                                btnClick={this.handleAddClient}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <BlueButton
                                btnName="Valider"
                                btnClick={this.handleContinue}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

// Get userDetail object from Redux Store
const mapStateToProps = (state) => ({
    userDetail: state.user.userDetail,
});

PartnerSelection.propTypes = {
    userDetail: PropTypes.objectOf(PropTypes.any),
};

PartnerSelection.defaultProps = {
    userDetail: null,
};

export default connect(mapStateToProps)(PartnerSelection);
