/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Redirect } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import SubTitle from "../../hoc/SubTitle";
import BlueButton from "../../hoc/BlueButton";
import { GETAltis, PUTAltis } from "../../apis/ServerApis";
import {
  handleResponseCode,
  handleException,
  showAlert,
  handleFailure,
} from "../../utility";
import { altisUrl } from "../../constants";

class MixSupply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: ["1 ère année", "2 ère année", "3 ère année", "4 ère année", "5 ère année"],
      mixSupplyData: [],
      navigateToContact: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.location.state || {};
    if (id) {
      const url = `${altisUrl}configs?user_id=${id}`;

      GETAltis(url)
        .then((response) => {
          console.log(response);
          if (handleResponseCode(response)) {
            console.log(response.data);
            this.setState({
              mixSupplyData: response.data,
            });
          } else {
            handleFailure(response);
          }
        })
        .catch((error) => {
          handleException(error);
        });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const index = Number(e.target.id);
    // eslint-disable-next-line react/no-access-state-in-setstate
    const mixSupplyData = [...this.state.mixSupplyData];
    mixSupplyData[index] = { ...mixSupplyData[index], [name]: value };
    this.setState({ mixSupplyData });
  };

  handleContinue = () => {
    const url = `${altisUrl}configs/submitConfig`;
    const appParams = {
      user_id: this.props.location.state.id,
      configs: this.state.mixSupplyData,
    };
    PUTAltis(url, appParams)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          showAlert("Succès", "Données enregistrées avec succès");
          this.setState({
            navigateToContact: true,
          });
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  // Go to Previous Page
  goToPreviousPage = () => {
    this.setState({
      navigateToContact: true,
    });
  };

  render() {
    if (this.state.navigateToContact) {
      return <Redirect to="/mycontacts" />;
    }
    const contactList = this.state.mixSupplyData.map((element, index) => {
      return (
        <div className="contactRowContainer">
          <span className="contactColumn contactCanton">
            {this.state.year[index]}
          </span>
          <span className="contactColumn contactName">
            <OutlinedInput
              className="manualInput settingsInputField"
              name="GdO_hydro_CH"
              id={index}
              value={element.GdO_hydro_CH}
              onChange={this.handleChange}
              labelWidth={0}
            />
          </span>
          <span className="contactColumn contactName">
            <OutlinedInput
              className="manualInput settingsInputField"
              name="GdO_hydro_EU"
              id={index}
              value={element.GdO_hydro_EU}
              onChange={this.handleChange}
              labelWidth={0}
            />
          </span>
          <span className="contactColumn contactName">
            <OutlinedInput
              className="manualInput settingsInputField"
              name="sans"
              id={index}
              value={element.sans}
              onChange={this.handleChange}
              labelWidth={0}
            />
          </span>
        </div>
      );
    });

    return (
      <div>
        <IconButton
          aria-label="back"
          class="backBtn"
          onClick={this.goToPreviousPage}
        >
          <ArrowBackIcon />
          Retour
        </IconButton>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="myAccountContainer"
        >
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ marginTop: "3em" }}
          >
            <div className="avtarLogo" />
            <SubTitle titleText="Mon compte" customeColor="#A7B6C6" />
            <div className="grayArrowIcon" />
            <SubTitle titleText="Mix de Fourniture" />
          </Grid>
          <hr
            style={{
              backgroundColor: "#FFFFFF",
              height: 1,
            }}
          />

          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="center"
            style={{ height: "100%" }}
          >
            <div className="contactRowContainer">
              <span className="contactColumnHeader contactCanton configHeaders">
                Années
              </span>
              <span className="contactColumnHeader contactName configHeaders">
                GdO hydro CH
              </span>
              <span className="contactColumnHeader contactName configHeaders">
                GdO hydro EU
              </span>
              <span className="contactColumnHeader contactName configHeaders">
                Nucléaire
              </span>
            </div>
            {contactList}
          </Grid>
          <div className="myInfoRow" style={{ marginLeft: "0px" }}>
            <BlueButton
              btnName="Valider"
              className="marginLeftZero"
              btnClick={this.handleContinue}
            />
          </div>
        </Grid>
      </div>
    );
  }
}

export default MixSupply;
