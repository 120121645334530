import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import reducers from "./store/reducers";

// Redux Store Set Up
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>
);

// Handle event of internet connection come back
const handleOnLine = () => {
    window.open("#/home", "_self");
};

// Handle event of internet connection goes out
const handleOffLine = () => {
    window.open("#/noconnection", "_self");
};

const renderReactDom = () => {
    // Set event of internet connection come back
    window.addEventListener("online", handleOnLine);
    // Set event of internet connection goes out
    window.addEventListener("offline", handleOffLine);

    ReactDOM.render(app, document.getElementById("root"));
};

renderReactDom();
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
