import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { showSpinner } from "../utility";

class LineChart extends Component {
    constructor(props) {
        super(props);

        console.log("this.props.chartData: ", this.props.chartData);

        this.state = {
            series: [
                {
                    name: this.props.yTitle, // 'Historique',
                    data: this.props.chartData,
                },
                this.props.gdOHydroCH,
                this.props.gdOHydroEU,
                this.props.sans,
                this.props.priceChartData,
            ],
            options: {
                title: {
                    text: this.props.title, // 'Historique',
                    align: "center",
                    margin: 10,
                    floating: false,
                    style: {
                        fontSize: "16px",
                        fontWeight: "bold",
                        fontFamily: undefined,
                        color: "#263238",
                    },
                },
                chart: {
                    height: 300,
                    type: "line",
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    width: 2,
                    curve: "straight",
                    dashArray: [this.props.strokeDashValue],
                },
                colors: this.props.lineColor,
                dataLabels: {
                    enabled: this.props.dataLabels,
                },
                legend: {
                    show: this.props.showLegends,
                    showForNullSeries: false,
                },
                xaxis: {
                    labels: {
                        show: true,
                        style: {
                            fontSize: this.props.xValueFontSize,
                        },
                    },
                    title: {
                        text: this.props.xTitle, // 'Date & Time',
                    },
                },
                yaxis: {
                    title: {
                        text: this.props.yTitle, // 'Consumption',
                    },
                    labels: {
                        style: {
                            fontSize: "12px",
                        },
                        formatter: (value) => `${value}`,
                        // formatter: () => `value`,
                    },
                },
            },
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.series.data !== nextProps.chartData) {
            const xAxisLabel = [];
            nextProps.chartData.forEach((element) => {
                xAxisLabel.push(element.x);
            });
            return {
                series: [
                    {
                        name: nextProps.yTitle, // 'Consumption',
                        data: nextProps.chartData,
                    },
                    nextProps.gdOHydroCH,
                    nextProps.gdOHydroEU,
                    nextProps.sans,
                    nextProps.priceChartData,
                ],
                options: {
                    ...prevState.options,
                    stroke: {
                        width: 2,
                        curve: "straight",
                        dashArray: [nextProps.strokeDashValue],
                    },
                    xaxis: {
                        categories: xAxisLabel,
                        labels: {
                            show: true,
                            style: {
                                fontSize: nextProps.xValueFontSize,
                            },
                        },
                        title: {
                            text: nextProps.xTitle, // 'Date & Time',
                        },
                    },
                },
            };
        }
        return null;
    }

    closeClicked = () => {
        // eslint-disable-next-line react/prop-types
        this.props.closeBtnClick();
    };

    componentDidUpdate = () => {
        showSpinner(false);
    };

    render() {
        const chartData = []
        this.state.series.forEach((series) => {
            if( typeof(series.data) !== 'undefined' && series.data.length){
                chartData.push(series);
            }
        });
       
        return (
            <div>
                <IconButton
                    color="primary"
                    aria-label="close"
                    onClick={this.closeClicked}
                    className="closeBtn"
                >
                    <CloseIcon />
                </IconButton>
                <ReactApexChart
                    options={this.state.options}
                    series={chartData}
                    type="line"
                    stacked
                    height={window.innerHeight * 0.8}
                    className="graphPopupContainer"
                />
            </div>
        );
    
        
    }
}

export default LineChart;

LineChart.propTypes = {
    lineColor: PropTypes.arrayOf(PropTypes.string),
    chartData: PropTypes.arrayOf(PropTypes.object),
    strokeDashValue: PropTypes.number,
    title: PropTypes.string,
    xTitle: PropTypes.string,
    yTitle: PropTypes.string,
    // yValueSufix: PropTypes.string,
    xValueFontSize: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    gdOHydroCH: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    gdOHydroEU: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    sans: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    priceChartData: PropTypes.object,
    dataLabels: PropTypes.bool,
    showLegends: PropTypes.bool,
};

LineChart.defaultProps = {
    lineColor: ["#43bfef", "#FF1654", "#247BA0"],
    chartData: [],
    strokeDashValue: 0,
    title: "",
    xTitle: "",
    yTitle: "",
    // yValueSufix: "",
    xValueFontSize: "7px",
    gdOHydroCH: {},
    gdOHydroEU: {},
    sans: {},
    priceChartData: {},
    dataLabels: false,
    showLegends: false,
};
