/* eslint-disable no-param-reassign */
/* eslint-disable use-isnan */
/* eslint-disable prefer-destructuring */
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import print from "print-js";
import swal from "sweetalert";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import GrayButton from "../../hoc/GrayButton";
import BlueButton from "../../hoc/BlueButton";
import {
  handleResponseCode,
  handleException,
  AntSwitch,
  generateOfferPdf,
  handleFailure,
} from "../../utility";
import {
  altisUrl,
  selectedClientPartner,
  roleID,
  firstStep,
  guId,
  forecastIds,
  showSummerWinter,
  offerData,
  offerFieldsList,
  offerCurrency,
  showDistinction,
  offerPrices,
  offerValidityMinutes,
  noOfYears,
} from "../../constants";
import { FILEDOWNLOADAltis, POSTAltis } from "../../apis/ServerApis";
import { changeStepThreeShow, changeStepTwoShow } from "../../store/actions";

class OfferDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerData: {
        years: [],
        offer_date: [],
        offer_validity: [],
        exchange_rate: [],
        annual_consumption: [],
        peak_consumption: [],
        offpeak_consumption: [],
        winter_peak_consumption: [],
        winter_offpeak_consumption: [],
        summer_peak_consumption: [],
        summer_offpeak_consumption: [],
        BSLD_power: [],
        BSLD_price_EUR: [],
        BSLD_price_CHF: [],
        avg_price_wo_risk_nor_margin: [],
        EA_extra_cost: [],
        consumption_HPFC_risk: [],
        "GoO price": [],
        risk_fee_price: [],
        handling_fee: [],
        sales_margin: [],
        peak_price: [],
        offpeak_price: [],
        winter_peak_price: [],
        winter_offpeak_price: [],
        summer_peak_price: [],
        summer_offpeak_price: [],
        final_price: [],
        isPartnerView: true,
        isOpenMyOffer: false,
      },
    };
  }

  componentDidUpdate = (prevProps) => {
    console.log("prevProps: ", prevProps);
    console.log("this.props: ", this.props);
    // eslint-disable-next-line react/prop-types
    if (prevProps !== this.props && this.props.offerDetails !== null) {
      // eslint-disable-next-line react/prop-types
      this.updateOfferDetails(this.props.offerDetails);
    }
  };

  updateOfferDetails = (data) => {
    this.setState({
      offerData: data,
    });
  };

  handleCalculateOffer = () => {
    // If loggedIn User = Admin then send user_Id of selected partner
    // If loggedIn User = Partner then send client_id of selected client
    let userId = null;
    let clientId = null;

    // If Login user = Partner then
    if (localStorage.getItem(roleID) === "2") {
      clientId = JSON.parse(localStorage.getItem(selectedClientPartner)).id;
    } else {
      userId = JSON.parse(localStorage.getItem(selectedClientPartner)).id;
    }

    let offersCurrency = "CHF";
    if (localStorage.getItem(offerCurrency) === "€") {
      offersCurrency = "EUR";
    }

    const dict = {
      user_id: userId,
      client_id: clientId,
      summer_winter:
        localStorage.getItem(showSummerWinter).toLowerCase() === "true" ? 1 : 0,
      offer_prices: localStorage.getItem(offerPrices),
      offer_validity_minutes: localStorage.getItem(offerValidityMinutes),
      no_of_years: parseInt(localStorage.getItem(noOfYears), 10),
      currency: offersCurrency,
      data: this.state.offerData,
    };

    const url = `${altisUrl}offers`;
    POSTAltis(url, dict)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          this.removeLocalStorageField();
          swal("Votre offre a été sauvegardée avec succès", {
            buttons: {
              catch: {
                text: "Calculer une nouvelle offre",
                value: "catch",
              },
              defeat: {
                text: "Voir mes offres",
                value: "defeat",
              },
            },
          }).then((value) => {
            switch (value) {
              case "defeat":
                this.setState({
                  isOpenMyOffer: true,
                });
                break;

              case "catch":
                this.handleModifyHistoryData();
                break;

              default:
                break;
            }
          });
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  printPage = () => {
    print("offerToPrint", "html");
  };

  generatePDF = () => {
    generateOfferPdf(
      offerFieldsList,
      this.state.offerData,
      this.state.isPartnerView,
      localStorage.getItem(showSummerWinter),
      localStorage.getItem(showDistinction),
    );
  };

  // Download a json, xml and xls file.
  handleOfferDownloadApi = (fileType) => {
    const url = `https://costing-app-api.altis.swiss/download?type=${fileType}`;
    const filename = fileType.replace('_', '.');
    FILEDOWNLOADAltis(url, filename)
      .then((response) => {
        
        console.log('response :::: ', response)
      })
      .catch((error) => {
        handleException(error);
      });
  }

  removeLocalStorageField = () => {
    localStorage.removeItem(firstStep);
    localStorage.removeItem(guId);
    localStorage.removeItem(forecastIds);
    localStorage.removeItem(offerData);
    localStorage.removeItem(showSummerWinter);
    localStorage.removeItem(offerCurrency);
    localStorage.removeItem(noOfYears);
    localStorage.removeItem(offerValidityMinutes);
    localStorage.removeItem(offerPrices);
  };

  handlePartnerClient = () => {
    this.setState((prevState) => ({
      isPartnerView: !prevState.isPartnerView,
    }));
  };

  // Hide 2nd & 3rd Step
  handleModifyHistoryData = () => {
    this.removeLocalStorageField();
    // eslint-disable-next-line react/prop-types
    this.props.changeStepTwoShow(false);
    // eslint-disable-next-line react/prop-types
    this.props.changeStepThreeShow(false);
  };

  // Hide 3rd Step
  handleModifyOfferParameter = () => {
    localStorage.removeItem(offerData);
    localStorage.removeItem(showSummerWinter);
    localStorage.removeItem(offerCurrency);
    localStorage.removeItem(noOfYears);
    localStorage.removeItem(offerValidityMinutes);
    localStorage.removeItem(offerPrices);
    // eslint-disable-next-line react/prop-types
    this.props.changeStepThreeShow(false);
  };

  render() {
    if (this.state.isPartnerView === undefined) {
      this.setState((prevState) => ({
        isPartnerView: true,
      }));
    }

    if (this.state.isOpenMyOffer) {
      this.handleModifyHistoryData();
      return <Redirect to="myoffer" />;
    }

    const offerCurrencyVal = localStorage.getItem(offerCurrency);

    const offerDetails = offerFieldsList.map((fieldObj) => {
      const result = this.state.offerData[fieldObj.fieldName].map(
        (value, index) => {
          const finalPrice =
            fieldObj.fieldName === "years" ? (
              <span style={{ color: "#44BFEF" }}>
                : {this.state.offerData.final_price[index]}
              </span>
            ) : (
              ""
            );
          return (
            <div>
              <div className="formLabelWhiteSteps offerValue">
                {value} {finalPrice}
              </div>
            </div>
          );
        },
      );

      console.log(" Result : ", result);
      const showDividerLine =
        fieldObj.fieldName === "final_price" ||
        fieldObj.fieldName === "offpeak_price" ||
        fieldObj.fieldName === "offer_date" ||
        fieldObj.fieldName === "winter_peak_price";

      const classNameContainer = "stepsRowContainer";

      const showLine = showDividerLine ? (
        <hr color="#587991" style={{ width: "100%", marginLeft: "-15px" }} />
      ) : (
        ""
      );

      const isSummerWinter = localStorage.getItem(showSummerWinter);

      const isDistinction = localStorage.getItem(showDistinction);

      console.log("isDistinction: ", isDistinction);

      if (this.state.isPartnerView === undefined) {
        this.state.isPartnerView = false;
      }
      return (
        <div
          style={{
            display:
              (this.state.isPartnerView === true &&
                fieldObj.showClient === false) ||
              (typeof fieldObj.showSummerWinter === "undefined"
                ? false
                : isSummerWinter !== fieldObj.showSummerWinter.toString()) ||
              (typeof fieldObj.showDistinction === "undefined"
                ? false
                : isDistinction !== fieldObj.showDistinction.toString())
                ? "none"
                : "",
          }}
        >
          {showLine}

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className={classNameContainer}
          >
            <Grid className="offerStepLblContainer">
              <div className="formLabelWhiteSteps">
                {fieldObj.name.replace(
                  "(CHF/MWh)",
                  `(${offerCurrencyVal}/MWh)`,
                )}
              </div>
            </Grid>

            {result}
          </Grid>
        </div>
      );
    });
    console.log("OfferDetail : ", offerDetails);

    return (
      <div style={{ width: "100%" }}>
        <div id="offerToPrint">
          <div>{offerDetails}</div>
        </div>

        <Grid
          component="label"
          container
          alignItems="center"
          spacing={0}
          style={{ marginLeft: "-9px", marginTop: "2em" }}
        >
          <Grid
            className={
              this.state.isPartnerView
                ? "formLabelGreySteps"
                : "formLabelWhiteSteps"
            }
            style={{ paddingRight: "1em" }}
          >
            Vue partenaire
          </Grid>
          <Grid item className="blueSwitch">
            <AntSwitch
              checked={this.state.isPartnerView}
              onChange={this.handlePartnerClient}
            />
          </Grid>
          <Grid
            className={
              !this.state.isPartnerView
                ? "formLabelGreySteps"
                : "formLabelWhiteSteps"
            }
            style={{ paddingLeft: "1em" }}
          >
            Vue client
          </Grid>
          {/* <Grid style={{ marginRight: "1em", float: "left" }}>
                        <GrayButton
                            btnName="Modifier les données"
                            btnClick={this.handleModifyHistoryData}
                        />
                    </Grid>
                    <Grid style={{ marginRight: "auto" }}>
                        <GrayButton
                            btnName="Modifier les paramètres"
                            btnClick={this.handleModifyOfferParameter}
                        />
                    </Grid> */}
        </Grid>
        

        <div className="stepSaveButton" style={{ width: "100%" }}>
          <div style={{ float: "left", marginRight: "1em" }}>
            <GrayButton btnName="Exporter en PDF" btnClick={this.generatePDF} />
          </div>
          <div style={{ float: "left", marginRight: "1em" }}>
            <GrayButton btnName="Imprimer" btnClick={this.printPage} />
          </div>

          <div style={{ float: "left", marginRight: "1em" }}>
            <GrayButton
              btnName="Modifier les données"
              btnClick={this.handleModifyHistoryData}
            />
          </div>

          <div style={{ float: "left", marginRight: "1em" }}>
            <GrayButton
              btnName="Modifier les paramètres"
              btnClick={this.handleModifyOfferParameter}
            />
          </div>

          <BlueButton
            btnName="Sauvegarder"
            btnClick={this.handleCalculateOffer}
          />
        </div>
        <div className="stepSaveButton" style={{ width: "100%", marginTop: '6em'}}>
          <div style={{ float: "left", marginRight: "1em" }}>
            <GrayButton btnName="proposer des prédictions dans XLSX" btnClick={() => this.handleOfferDownloadApi('preds_xlsx')} />
          </div>
          <div style={{ float: "left", marginRight: "1em" }}>
            <GrayButton btnName="offre en JSON" btnClick={() => this.handleOfferDownloadApi('offer_json')} />
          </div>

          <div style={{ float: "left", marginRight: "1em" }}>
            <GrayButton
              btnName="offre en XML"
              btnClick={() => this.handleOfferDownloadApi('offer_xml')}
            />
          </div>

          <div style={{ float: "left", marginRight: "1em" }}>
            <GrayButton
              btnName="offre en XLSX"
              btnClick={() => this.handleOfferDownloadApi('offer_xlsx')}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  changeStepTwoShow: (isShow) => dispatch(changeStepTwoShow(isShow)),
  changeStepThreeShow: (isShow) => dispatch(changeStepThreeShow(isShow)),
});
export default connect(null, mapDispatchToProps)(OfferDetails);
