import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import swal from "sweetalert";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import BackButton from "../../hoc/BackButton";
import GrayButton from "../../hoc/GrayButton";
import SubTitle from "../../hoc/SubTitle";
import { altisUrl, offerFieldsList } from "../../constants";
import { DELETEAltis, GETAltis } from "../../apis/ServerApis";
import {
  handleResponseCode,
  handleException,
  generateOfferPdf,
  printOffer,
  showAlert,
  handleFailure,
} from "../../utility";

class MyOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerData: [],
    };
  }

  componentDidMount() {
    this.GetOffers();
  }

  GetOffers = () => {
    const url = `${altisUrl}offers`;
    GETAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          this.setState({
            offerData: response.data,
          });
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  handleExportAsPDF = (value) => {
    const index = value.split(" ")[0];
    const user = value.split(" ")[1];
    let isClientView = false;
    if (user === "client") {
      isClientView = true;
    }

    generateOfferPdf(
      offerFieldsList,
      this.state.offerData[index].data,
      isClientView,
      this.state.offerData[index].summer_winter,
      this.state.offerData[index].offer_prices,
    );
  };

  handleImprimer = (value) => {
    const index = value.split(" ")[0];
    const user = value.split(" ")[1];
    let isClientView = false;
    if (user === "client") {
      isClientView = true;
    }
    printOffer(
      offerFieldsList,
      this.state.offerData[index].data,
      isClientView,
      this.state.offerData[index].summer_winter,
      this.state.offerData[index].offer_prices,
    );
  };

  handleDeleteOffer = (e) => {
    const id = e.currentTarget.getAttribute("contactid");
    console.log("index :", id);
    swal({
      title: "",
      text: "Voulez-vous supprimer cette offre ?",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.CallDeleteOfferApi(id);
      }
    });
  };

  handleOfferAccept = (index) => {
    const isAccepted = this.state.offerData[index].is_accepted;

    if (!isAccepted) {
      const offerId = this.state.offerData[index].id;
      console.log("OfferId : ------- ", offerId);
      const url = `${altisUrl}send-offer-mail/${offerId}`;
      const offerDataNew = [...this.state.offerData];
      const offerDict = { ...offerDataNew[index] };

      GETAltis(url)
        .then((response) => {
          console.log(response);
          if (handleResponseCode(response)) {
            showAlert("Succès", response.message);
            offerDict.is_accepted = 1;
            offerDataNew[index] = offerDict;
            this.setState({
              offerData: offerDataNew,
            });
          } else {
            handleFailure(response);
          }
        })
        .catch((error) => {
          handleException(error);
        });
    }
  };

  CallDeleteOfferApi = (id) => {
    const url = `${altisUrl}offers/${id}`;
    DELETEAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          this.GetOffers();
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  render() {
    console.log("OfferData : ", this.state.offerData);
    const offerList = this.state.offerData.map((offerObj, index) => {
      let btnOfferTitle = "Accepter";
      let btnBackgroundColor = "#a7b6c6";
      if (offerObj.is_accepted) {
        btnOfferTitle = "Acceptée";
        btnBackgroundColor = "#006400";
      }
      return (
        <div className="contactRowContainer">
          <span className="contactColumn contactId">{offerObj.id}</span>
          <span className="contactColumn contactDelete">
            {offerObj.user ? offerObj.user.name : offerObj.client.name}
          </span>
          <span className="contactColumn contactOffer">
            <GrayButton
              btnName="Exporter en PDF"
              label={index}
              btnClick={() => this.handleExportAsPDF(`${index} partner`)}
            />
            <GrayButton
              btnName="Imprimer"
              label={index}
              btnClick={() => this.handleImprimer(`${index} partner`)}
            />
          </span>
          <span
            className="contactColumn contactOffer"
            // style={{ paddingLeft: "2em" }}
          >
            <GrayButton
              btnName="Exporter en PDF"
              label={index}
              btnClick={() => this.handleExportAsPDF(`${index} client`)}
            />
            <GrayButton
              btnName="Imprimer"
              label={index}
              btnClick={() => this.handleImprimer(`${index} client`)}
            />
          </span>
          <span
            className="contactColumnHeader contactCanton"
            style={{ textAlign: "center", display: "block" }}
          >
            {offerObj.created_at.split("T")[0]}
          </span>
          <span
            className="contactColumn contactDelete"
            style={{ paddingLeft: "1.5em" }}
          >
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={this.handleDeleteOffer}
              className="deleteBtn"
              contactid={offerObj.id}
            >
              <DeleteIcon />
            </IconButton>
          </span>

          <span
            className="contactColumnHeader contactCanton"
            style={{ paddingRight: "2.5em" }}
          >
            <Button
              variant="contained"
              className="offerBtn"
              style={{ background: btnBackgroundColor }}
              onClick={() => this.handleOfferAccept(index)}
            >
              {btnOfferTitle}
            </Button>
            {/* <GrayButton
                        style={{background: "#00FF00", textAlign: "left"}}
                            btnName={btnOfferTitle}
                            label={index}
                            
                            btnClick={() =>
                                this.handleOfferAccept(index)
                            }
                        /> */}
          </span>
        </div>
      );
    });
    return (
      <div>
        <BackButton />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="myAccountContainer"
        >
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ marginTop: "3em" }}
          >
            <div className="avtarLogo" />
            <SubTitle titleText="Mon compte" customeColor="#A7B6C6" />
            <div className="grayArrowIcon" />
            <SubTitle titleText="Mes Offres" />
          </Grid>
          <hr
            style={{
              backgroundColor: "#FFFFFF",
              height: 1,
            }}
          />

          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="center"
            style={{ height: "100%" }}
          >
            <div className="contactRowContainer">
              <span className="contactColumnHeader contactId">#Id</span>
              <span className="contactColumnHeader contactDelete">Nom</span>
              <span
                className="contactColumnHeader contactOffer"
                style={{
                  textAlign: "center",
                  display: "block",
                }}
              >
                Vue partenaire
              </span>
              <span
                className="contactColumnHeader contactOffer"
                style={{
                  textAlign: "center",
                  display: "block",
                }}
                // style={{ paddingLeft: "1em" }}
              >
                Vue client
              </span>
              <span
                className="contactColumnHeader contactCanton"
                style={{ paddingLeft: "1em" }}
              >
                Date de création
              </span>
              <span className="contactColumnHeader contactDelete">Delete</span>

              <span
                className="contactColumnHeader contactCanton"
                style={{ paddingLeft: "1em" }}
              >
                Offre
              </span>
            </div>
            {offerList}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default MyOffer;
