import React, { Suspense, useEffect } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import Login from "./containers/Login/Login";
import PartnerClientSelection from "./containers/Partner/PartnerSelection";
import LeftSideBar from "./hoc/LeftSideBar";
import Home from "./containers/Home/Home";
import MyAccount from "./components/MyAccount/MyAccount";
import ViewMyInfo from "./components/MyInformation/ViewMyInfo";
import ContactsList from "./containers/Contacts/ContactsList";
import EditMyInfo from "./containers/MyInformation/EditMyInfo";
import MyOffer from "./containers/Offer/MyOffer";
import BasePriceSetting from "./containers/Config/BasePriceSetting";
import MixSupply from "./containers/Config/MixSupply";
import FAQ from "./containers/FAQ/FAQ";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import ForgetPassword from "./containers/ForgetPassword/ForgetPassword";
import ChangePassword from "./containers/ChangePassword/ChangePassword";
import { Debug } from "./constants";

function App() {
  useEffect(() => {
    if (window.location.protocol === "http:") {
      console.log(
        "you are accessing us via " +
          "an insecure protocol (HTTP). " +
          "Redirecting you to HTTPS.",
      );
      console.log(window.location);

      if (window.location.host !== "localhost:3000") {
        window.location.href = window.location.href.replace("http:", "https:");
      }
    }
  });

  // Disable console log
  if (!Debug) {
    console.log = function () {};
  }
  return (
    <div className="App">
      <div
        id="leftsidebar"
        style={{
          display:
            window.location.hash === "#/" ||
            window.location.hash === "/" ||
            window.location.hash === ""
              ? "none"
              : "",
        }}
      >
        <LeftSideBar />
      </div>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgetpassword" component={ForgetPassword} />
            <Route path="/userselection" component={PartnerClientSelection} />
            <Route path="/home" component={Home} />
            <Route path="/myaccount" component={MyAccount} />
            <Route path="/changepassword" component={ChangePassword} />
            <Route path="/myinformation" component={ViewMyInfo} />
            <Route path="/myoffer" component={MyOffer} />
            <Route path="/editmyinformation" component={EditMyInfo} />
            <Route path="/mycontacts" component={ContactsList} />
            <Route path="/basepricesetting" component={BasePriceSetting} />
            <Route path="/mixsupply" component={MixSupply} />
            <Route path="/faq" component={FAQ} />
            <Route path="/reset/:token" component={ResetPassword} />
          </Switch>
        </Suspense>
        <div
          id="spinner"
          className="loaderContainer"
          style={{ display: "none" }}
        >
          <div className="loader" />
          Chargement
        </div>
      </Router>
    </div>
  );
}

export default App;
