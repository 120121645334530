/* eslint-disable guard-for-in */
/* eslint-disable react/no-unescaped-entities */
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import swal from "sweetalert";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import { connect } from "react-redux";

import Settings from "../Settings/Settings";
import GrayButton from "../../hoc/GrayButton";
import BlueButton from "../../hoc/BlueButton";
import { changeStepThreeShow, changeStepTwoShow } from "../../store/actions";
import { GETAltis, POSTAltis } from "../../apis/ServerApis";
import {
  selectedClientPartner,
  altisUrl,
  applicationSetting,
  costingPrices,
  firstStep,
  guId,
  offerData,
  showSummerWinter,
  roleID,
  offerCurrency,
  showDistinction,
  userID,
  noOfYears,
  offerValidityMinutes,
  offerPrices,
} from "../../constants";

import {
  handleResponseCode,
  handleException,
  AntSwitch,
  renderMonthDropDown,
  showSpinner,
  handleFailure,
  showAlert,
} from "../../utility";
import LineChart from "../../hoc/LineChart";

let settingObj = "";
const supplyYears = ["1 an", "2 ans", "3 ans", "4 ans", "5 ans"];
const OfferValidityHours = ["0h", "3h", "6h", "12h", "24h", "48h", "72h"];
const mixOfSupply = ["GdO hydro CH", "GdO hydro EU", "Nucléaire", "Manuel"];
const currentYear = new Date().getFullYear();
let costingBsldPrices = [];
class OfferParameteres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: "1 an",
      years: [currentYear + 1],
      selectedValidityOfferHour: "",
      isPriceMix: false,
      // yearsData: ["1", "2", "3"],
      selectedMixOfSupply: "GdO hydro CH",
      isOfferCurrencyCHF: true,
      isDifferentTariff: false,
      isSummerDistinction: false,
      showPriceChart: false,
      showForcastHistory: false,
      showCoverageSituation: false,
      priceChartData: [],
      forcaseChartData: [],
      hourRangeMtoF: [6, 22],
      hourRangeSAndS: [6, 22],
      configData: [],
      selectedMixOfSupplyConfig: [],
      selectedMixOfSupplyData: [],
      manuelData: ["", "", "", ""],
      basePriceMixData: [],
      selectedBasePriceMix: [0, 0, 0, 0, 0],
      commercialMargin: [0, 0, 0, 0, 0],
      handlingFees: [0, 0, 0, 0, 0],
      gOO: [0,0,0,0,0],
      showSettings: false,
      showValidityHoursError: false,
      selectedForcastYear: "",
      selectedForcastMonth: 1,
      checkboxManual: [false, false, false, false, false],
    };
  }

  componentDidMount() {
    // If Loginuser = Admin then pass selectedPartnerId
    const partnerId = JSON.parse(localStorage.getItem(selectedClientPartner))
      .id;
    let url = `${altisUrl}configs?user_id=${partnerId}`;

    // If loginUser = Partner then take UserId for get Config data
    if (localStorage.getItem(roleID) === "2") {
      const userId = localStorage.getItem(userID);
      url = `${altisUrl}configs?user_id=${userId}`;
    }

    GETAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          this.createArray(response.data);
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }

  // Make an array for Base De prix = Mix
  createArray = (data) => {
    const arrData = data.map((value) => {
      const dict = [
        value.situation_marche,
        value.couverture_1,
        value.couverture_2,
        value.couverture_3,
        value.couverture_4,
      ];
      return dict;
    });

    // Array for Mix of supply = GdO_hydro_CH bydefault
    const arrData1 = data.map((value) => {
      return value.GdO_hydro_CH;
    });

    this.setState({
      basePriceMixData: arrData,
      selectedMixOfSupplyData: arrData1,
      selectedMixOfSupplyConfig: arrData1,
      configData: data, // Config Api Response store for  Mix de fourniture's 3 button
    });
  };

  // Change Years
  handleSupplyYear = (event, newValue) => {
    console.log("newValue :", newValue);
    const selectYear = supplyYears[newValue - 1];
    const arrYear = [];
    this.setState({ selectedYear: selectYear, years: arrYear });

    const year = newValue;
    console.log("Years :", year);
    for (let i = 1; i <= year; i += 1) {
      arrYear.push(currentYear + i);
    }

    this.removeItemFromArray(year);
    // Remove an element from array when year will decrease
    if (year < 4) {
      // this.removeItemFromArray(year);
      this.setState({
        years: arrYear,
      });
    } else {
      costingBsldPrices = JSON.parse(localStorage.getItem(costingPrices));

      this.setState({
        years: arrYear,
      });
    }
  };

  // Select a Validity Offer hours
  handleValidityHours = (e) => {
    if(/^[0-9]*$/.test((e.target.value))) {
      if(Number(e.target.value) >= 0 && Number(e.target.value) <= 168){
        this.setState({showValidityHoursError : false});
        this.setState({ selectedValidityOfferHour: e.target.value });
      } else {
        this.setState({showValidityHoursError : true});
        showAlert("Erreur", "L'offre de validité doit être comprise entre 0 et 168.");
      }
    } else {
      this.setState({showValidityHoursError : true});
      showAlert("Erreur", "L'offre de validité doit être comprise entre 0 et 168.");
    }
  };

  // Handle Price Base
  handlePriceBaseChange = (e) => {
    console.log('e :::: ', e);
    this.setState((prevState) => ({ isPriceMix: !prevState.isPriceMix }));
  };

  // Select from years drop down
  handleDropdownChange = (index) => (e) => {
    if(e.target.value >= 0){
      // eslint-disable-next-line react/no-access-state-in-setstate
      const selectedBasePriceMix = [...this.state.selectedBasePriceMix];
      selectedBasePriceMix[index] = e.target.value;
      this.setState({ selectedBasePriceMix });
    }
  };

  closePriceGraphPopup = () => {
    this.setState({
      showPriceChart: false,
    });
  };

  closeForcaseGraphPopup = () => {
    this.setState({
      showForcastHistory: false,
    });
  };

  closeCoverageSituationGraphPopup = () => {
    this.setState({
      showCoverageSituation: false,
    });
  };

  showForcastGraph = () => {
    this.getForcastDataByPage(
      altisUrl,
      localStorage.getItem("forecastIds").split(","),
      [],
    );
  };

  getForcastDataByPage = (url, forecastIdsArr, data) => {
    if (forecastIdsArr.length === 0) {
      const forcastDataArr = [];
      data.forEach((element) => {
        forcastDataArr.push(element);
      });

      let firstYear = new Date().getFullYear() + 1;
      if (forcastDataArr && forcastDataArr.length) {
        firstYear = new Date(forcastDataArr[0].datetime).getFullYear();
      }

      this.setState({
        forcaseChartData: forcastDataArr,
        showForcastHistory: true,
        selectedForcastYear: firstYear,
      });

      return;
    }

    const forcastUrl = `${url}forecastDatas/${forecastIdsArr.shift()}?guuid=${localStorage.getItem(
      guId,
    )}`;

    GETAltis(forcastUrl)
      .then((response) => {
        // console.log(response);
        if (handleResponseCode(response)) {
          if (response && response.data) {
            if (response.data) {
              // eslint-disable-next-line no-param-reassign
              data = data.concat(response.data);
              if (response.data) {
                this.getForcastDataByPage(altisUrl, forecastIdsArr, data);
              }
            }
          }
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  // Handle Market Situation
  handleMarketSituation = () => {
    let costingPricesArr = JSON.parse(localStorage.getItem(costingPrices));

    if (!costingPricesArr) {
      costingPricesArr = [];
    }

    let currentYearVal = new Date().getFullYear();

    const priceChartArrData = [];
    costingPricesArr.forEach((element) => {
      currentYearVal += 1;
      priceChartArrData.push({
        y: element,
        x: currentYearVal,
      });
    });

    if (priceChartArrData) {
      this.setState({
        priceChartData: priceChartArrData,
        showPriceChart: true,
      });
    }
  };

  // Handle Coverage Situation
  handleCoverageSituation = () => {
    this.setState({
      showCoverageSituation: true,
    });
  };

  // Handle Mix Of Supply
  handleMixOfSupply = (e) => {
    const str = e.target.innerText;

    if (str === "Manuel") {
      this.setState({
        selectedMixOfSupply: str,
      });
    } else {
      this.makeAnArrayMixOfSupply(str);
    }
  };

  // Create an array for MixOfSupply except Manual
  makeAnArrayMixOfSupply = (str) => {
    let selectedSupply = "";

    if (str === "Nucléaire") {
      selectedSupply = "sans";
    } else {
      console.log("str:", str);
      selectedSupply = str.replace(/ /g, "_");
    }

    // eslint-disable-next-line react/no-access-state-in-setstate
    let arrData = this.state.configData.map((value) => {
      return value[selectedSupply];
    });

    // eslint-disable-next-line react/no-access-state-in-setstate
    const arrConfigdata = this.state.configData.map((value) => {
      return value[selectedSupply];
    });

    const arrZero = [];
    for (let i = 0; i < 5 - this.state.years.length; i += 1) {
      arrZero.push("0");
    }

    console.log("Supply config data ------- ", arrConfigdata);
    arrData.splice(this.state.years.length);
    arrData = arrData.concat(arrZero);

    this.setState({
      selectedMixOfSupply: str,
      selectedMixOfSupplyData: arrData,
      selectedMixOfSupplyConfig: arrConfigdata,
      // set a no of value according to Selected no of years
    });
  };


  // Handle Commercial MArgin
  handleCommercialMargin = (e) => {
    if(e.target.value >= 0){
      const index = Number(e.target.id);
      // eslint-disable-next-line react/no-access-state-in-setstate
      const commercialMargin = [...this.state.commercialMargin];
      commercialMargin[index] = e.target.value;
      this.setState({ commercialMargin });
    }
  };

  // Handle Handling Fee
  handleHandlingFee = (e) => {
    if(e.target.value >= 0) {
      const index = Number(e.target.id);
      // eslint-disable-next-line react/no-access-state-in-setstate
      const handlingFees = [...this.state.handlingFees];
      handlingFees[index] = e.target.value;
      this.setState({ handlingFees });
    } 
  };

  // Handle Gurentees of GOO
  handleGoo = (e) => {
    if(e.target.value >= 0){
      const index = Number(e.target.id);
      // eslint-disable-next-line react/no-access-state-in-setstate
      const handlingGoo = [...this.state.gOO];
      handlingGoo[index] = e.target.value;
      this.setState({ gOO:handlingGoo });
    }
  };

  // handle Manuel Textfield according to year
  handleSupplyManuel = (e) => {
    if(e.target.value >= 0){
      const index = Number(e.target.id);
      // eslint-disable-next-line react/no-access-state-in-setstate
      const manuelDatas = [...this.state.manuelData];
      manuelDatas[index] = e.target.value;
      this.setState({ manuelData: manuelDatas });
    }
  };

  // Handle Offer Currency
  handleOfferCurrencyChange = () => {
    this.setState((prevState) => ({
      isOfferCurrencyCHF: !prevState.isOfferCurrencyCHF,
    }));
  };

  // Handle Tariff Profile
  handleTariffProfileChange = () => {
    this.setState((prevState) => ({
      isDifferentTariff: !prevState.isDifferentTariff,
    }));
  };

  // Handle CheckBox Summer/Winter Distinction
  handleSummerDistinction = () => {
    this.setState((prevState) => ({
      isSummerDistinction: !prevState.isSummerDistinction,
    }));
  };

  // handle Hour Change MonToFri
  handleHourChangeMonToFri = (event, newValue) => {
    this.setState({
      hourRangeMtoF: newValue,
    });
  };

  // handle Hour Change Sat & Sun
  handleHourChangeSatAndSun = (event, newValue) => {
    this.setState({
      hourRangeSAndS: newValue,
    });
  };

  // Handle to open setting - only admin can open for selected partner
  handleCostingSetting = () => {
    console.log("Hi");
    this.setState({ showSettings: true });
  };

  closeSuccessPopup = () => {
    console.log("closed");
    const newSettingObj = JSON.parse(localStorage.getItem(applicationSetting));

    // If user change in setting parameter then admin have to go on 1st step
    if (JSON.stringify(settingObj) !== JSON.stringify(newSettingObj)) {
      // eslint-disable-next-line react/prop-types
      this.props.changeStepTwoShow(false);
    }
    this.setState({
      showSettings: false,
    });
  };

  // Create a Dictionary for api onClick of Calcualte offer
  handleCalculateOffer = () => {
    localStorage.removeItem(showSummerWinter);
    localStorage.removeItem(showDistinction);
    localStorage.removeItem(noOfYears);

    localStorage.setItem(showSummerWinter, this.state.isSummerDistinction);
    if (this.state.isSummerDistinction) {
      localStorage.setItem(showDistinction, !this.state.isDifferentTariff);
    } else {
      localStorage.setItem(showDistinction, this.state.isDifferentTariff);
    }

    const firstStepDict = JSON.parse(localStorage.getItem(firstStep));
    console.log("FirstDict :", firstStepDict);
    const supplyPeriod = parseInt(
      this.state.selectedYear.substr(0, this.state.selectedYear.indexOf(" ")),
      10,
    );
    localStorage.setItem(noOfYears, supplyPeriod);
    // this.addZerosInArray(supplyPeriod);
    // const offerValidity = this.state.selectedValidityOfferHour.slice(
    //   0,
    //   this.state.selectedValidityOfferHour.length - 1,
    // );
    const offerValidity = Number(this.state.selectedValidityOfferHour);
    const offerValidityInMinutes = parseInt(offerValidity, 10) * 60;
    localStorage.setItem(offerValidityMinutes, offerValidityInMinutes);
    const marcketCostingPrices = JSON.parse(
      localStorage.getItem(costingPrices),
    );

    const selectedYear = parseInt(this.state.selectedYear.substring(0, 1), 10);

    const priceBasis = this.state.isPriceMix
      ? this.state.selectedBasePriceMix
      : marcketCostingPrices.map(function (e, index) {
          if (selectedYear > index) return parseFloat(e);
          return 0;
        });

    const costingBsldPricesArr = [];
    priceBasis.forEach((priceVal, index) => {
      if (parseFloat(priceVal).toString() !== "NaN") {
        costingBsldPricesArr[index] = parseFloat(priceVal);
      } else {
        // conversion of "CAL BSLD 1MW 57.89" to 57.89
        costingBsldPricesArr[index] = parseFloat(
          priceVal.substring(priceVal.lastIndexOf(" ") + 1, priceVal.length),
        );
      }
    });

    console.log("costingBsldPricesArr: ", costingBsldPricesArr);

    console.log("Handling Fees123 :", priceBasis);

    if (JSON.parse(localStorage.getItem(costingPrices)) === null) {
      localStorage.setItem(costingPrices, JSON.stringify([0, 0, 0, 0, 0]));
      costingBsldPrices = [0, 0, 0, 0, 0];
    }

    localStorage.setItem(
      offerCurrency,
      this.state.isOfferCurrencyCHF ? "CHF" : "€",
    );

    const offerPrice = this.state.isDifferentTariff ? "Distinction" : "average";
    localStorage.setItem(offerPrices, offerPrice);

    const dict = {
      supply_period: supplyPeriod,
      offer_validity: offerValidity,
      guuid: localStorage.getItem(guId),
      price_basis: priceBasis,
      is_priceBasis_manual: this.state.isPriceMix,
      // GoO:
      //   this.state.selectedMixOfSupply === "Manuel"
      //     ? this.state.manuelData.map((e) => {
      //         if (e === "") {
      //           return parseFloat(0);
      //         }
      //         return parseFloat(e);
      //       })
      //     : this.state.selectedMixOfSupplyData.map((e) => {
      //         return parseFloat(e);
      //       }),

      GoO: this.state.gOO.map((e) => {
        if (e === "") {
          return parseFloat(0);
        }
        return parseFloat(e);
      }),
      handling_fee: this.state.handlingFees.map((e) => {
        if (e === "") {
          return parseFloat(0);
        }
        return parseFloat(e);
      }),
      sales_margin: this.state.commercialMargin.map((e) => {
        if (e === "") {
          return parseFloat(0);
        }
        return parseFloat(e);
      }),
      offer_currency: this.state.isOfferCurrencyCHF ? "CHF" : "EUR",
      offer_prices: this.state.isDifferentTariff ? "Distinction" : "average",
      lower_hour_week: this.state.hourRangeMtoF[0],
      upper_hour_week: this.state.hourRangeMtoF[1],
      lower_hour_weekend: this.state.hourRangeSAndS[0],
      upper_hour_weekend: this.state.hourRangeSAndS[1],
      summer_winter: this.state.isSummerDistinction ? 1 : 0,
      search_window: JSON.parse(localStorage.getItem(applicationSetting))
        .search_reference_days,
      outlier_factor: parseFloat(
        JSON.parse(localStorage.getItem(applicationSetting)).outlier_factor,
      ),
      forecast_accuracy: parseFloat(
        JSON.parse(localStorage.getItem(applicationSetting))
          .forecasts_precision,
      ),
      consumption_risk: parseFloat(
        JSON.parse(localStorage.getItem(applicationSetting)).consumption_risk,
      ),
      price_and_HPFC_risk: parseFloat(
        JSON.parse(localStorage.getItem(applicationSetting)).price_risk,
      ),
      hedging_accuracy: parseFloat(
        JSON.parse(localStorage.getItem(applicationSetting)).coverage_accuracy,
      ),
      EA_factor: parseFloat(
        JSON.parse(localStorage.getItem(applicationSetting)).overcost_factor,
      ),
      sigma_BS: parseFloat(
        JSON.parse(localStorage.getItem(applicationSetting)).sigma_Bs,
      ),
      costing_bsld_prices: costingBsldPricesArr,
      // costing_bsld_prices: costingBsldPrices.map((e) => {
      //     return parseFloat(e);
      // }),
    };

    const finalDict = { ...firstStepDict, ...dict };
    console.log("finalDict: ", JSON.stringify(finalDict));

    this.callCalculateOffer(finalDict);
  };

  callCalculateOffer = (finalDict) => {
    const url = `${altisUrl}calculate/offer`;
    let numberAfterDecimal = 3;
    const fieldsForThreeDecimal = [
      "annual_consumption",
      "winter_peak_consumption",
      "winter_offpeak_consumption",
      "summer_peak_consumption",
      "summer_offpeak_consumption",
      "BSLD_power",
      // "risk_fee_price",
    ];
    showSpinner(true, "Calcul en cours");
    // eslint-disable-next-line react/prop-types
    this.props.changeStepThreeShow(false);
    POSTAltis(url, finalDict)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          if (response.data !== null) {
            // if (this.state.selectedYear.substring(0, 1) < 4) {
            // eslint-disable-next-line no-restricted-syntax
            // eslint-disable-next-line guard-for-in
            // eslint-disable-next-line no-restricted-syntax
            for (const dataField in response.data) {
              response.data[dataField] = response.data[dataField].splice(
                0,
                parseInt(this.state.selectedYear.substring(0, 1), 10),
              );
              response.data[dataField].forEach(
                // eslint-disable-next-line no-loop-func
                (dataVal, index) => {
                  if (dataField !== "offer_date" && dataField !== "years") {
                    if (fieldsForThreeDecimal.indexOf(dataField) > -1) {
                      numberAfterDecimal = 3;
                    } else if (dataField === "exchange_rate") {
                      numberAfterDecimal = 4;
                    } else {
                      numberAfterDecimal = 2;
                    }
                    response.data[dataField][index] = parseFloat(
                      dataVal,
                    ).toFixed(numberAfterDecimal);
                    console.log("--=-=-=-=-==-=-=-");
                    console.log("(parseFloat(dataVal)): ", parseFloat(dataVal));
                    console.log(
                      "(parseFloat(dataVal)).toFixed(numberAfterDecimal): ",
                      parseFloat(dataVal).toFixed(numberAfterDecimal),
                    );
                  }
                },
              );
            }
            // }

            // AC-40 Improvement: Add validity period on the offer summary
            const selectedyear = parseInt(
              this.state.selectedYear.substring(0, 1),
              10,
            );
            const validityPeriod = [];
            for (let i = 0; i < selectedyear; i += 1) {
              validityPeriod.push(this.state.selectedValidityOfferHour);
            }
            response.data.offer_validity = validityPeriod;
            console.log(" Offer Validity Period : ----------- ", response.data);
            localStorage.setItem(offerData, JSON.stringify(response.data));
            // eslint-disable-next-line react/prop-types
            this.props.changeStepThreeShow(true);
          }
        } else if (response.success && response.data === null) {
          swal("Veuillez vérifier les informations et paramètres", "", "error");
          // eslint-disable-next-line react/prop-types
          this.props.changeStepThreeShow(false);
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  // if user selected year < 4 so replace extra values with 0
  removeItemFromArray = (year) => {
    const arrZero = [];
    const arrQuotes = [];
    for (let i = 0; i < 5 - year; i += 1) {
      arrQuotes.push(0);
      arrZero.push(0);
    }

    let arrManuel = [];
    let arrCommMargin = [];
    let arrMixPrice = [];
    let arrHandleFee = [];
    let arrGoO = [];
    let arrMixofsupply = [];
    let arrCostingPrices = [];
    if (year < 5) {
      arrManuel = [...this.state.manuelData];
      arrManuel.splice(year);
      arrManuel = arrManuel.concat(arrQuotes);

      arrCommMargin = [...this.state.commercialMargin];
      arrCommMargin.splice(year);
      arrCommMargin = arrCommMargin.concat(arrQuotes);

      arrMixPrice = [...this.state.selectedBasePriceMix];
      arrMixPrice.splice(year);
      arrMixPrice = arrMixPrice.concat(arrZero);

      arrHandleFee = [...this.state.handlingFees];
      arrHandleFee.splice(year);
      arrHandleFee = arrHandleFee.concat(arrQuotes);

      arrGoO = [...this.state.gOO];
      arrGoO.splice(year);
      arrGoO = arrGoO.concat(arrQuotes);

      console.log("=-==-=-=-=-=-=-=-=-");
      console.log("Year: ", year);
      console.log(
        "this.state.selectedMixOfSupplyData: ",
        this.state.selectedMixOfSupplyData,
      );
      console.log(
        "this.state.selectedMixOfSupplyConfig: ",
        this.state.selectedMixOfSupplyConfig,
      );
      arrMixofsupply = [...this.state.selectedMixOfSupplyConfig];
      arrMixofsupply.splice(year);
      arrMixofsupply = arrMixofsupply.concat(arrZero);
      console.log("this.state.arrMixofsupply: ", arrMixofsupply);

      if (JSON.parse(localStorage.getItem(costingPrices)) !== null) {
        arrCostingPrices = JSON.parse(localStorage.getItem(costingPrices));

        arrCostingPrices.splice(year);
        arrCostingPrices = arrCostingPrices.concat(arrZero);
        costingBsldPrices = [...arrCostingPrices];
      } else {
        localStorage.setItem(costingPrices, JSON.stringify([0, 0, 0, 0, 0]));
        arrCostingPrices = [0, 0, 0, 0, 0];
        costingBsldPrices = [...arrCostingPrices];
      }

      this.setState({
        manuelData: arrManuel,
        commercialMargin: arrCommMargin,
        selectedBasePriceMix: arrMixPrice,
        handlingFees: arrHandleFee,
        gOO: arrGoO,
        selectedMixOfSupplyData: arrMixofsupply,
      });
    } else {
      arrMixofsupply = [...this.state.selectedMixOfSupplyConfig];
      this.setState({
        selectedMixOfSupplyData: arrMixofsupply,
      });
    }
  };

  showCostingButtonToAdmin = () => {
    if (localStorage.getItem(roleID) === "1") {
      return (
        <Grid style={{ marginRight: "1em" }}>
          <GrayButton
            btnName="Modifier les paramètres Costing"
            btnClick={this.handleCostingSetting}
          />
        </Grid>
      );
    }
    return null;
  };

  // render dropdown according to years which is fetched by an api
  renderDropDown = (index) => {
    const costingPricesArr = JSON.parse(localStorage.getItem(costingPrices));

    const basePrixData = this.state.basePriceMixData[index].map((data) => {
      // let val = "";
      // if(data.indexOf(":::") > -1) {
      //     // eslint-disable-next-line prefer-destructuring
      //     val = data.split(":::")[1];
      // }
      // else {
      //     val = data.replace(":::", " ");
      // }

      const data1 = data.replace(":::", " ");

      return (
        <MenuItem value={data1} key={data.id}>
          {data1}
        </MenuItem>
      );
    });

    if (
      costingPricesArr &&
      costingPricesArr.length &&
      costingPricesArr.length >= index
    ) {
      basePrixData.push(
        <MenuItem value={costingPricesArr[index]} key={basePrixData.length}>
          Prix marché
        </MenuItem>,
      );
    }

    return basePrixData;
  };

  renderYearDropDown = () => {
    if (!this.state.forcaseChartData.length)
      return (
        <option value="" key={-1}>
          {" "}
        </option>
      );

    let firstYear = new Date(
      this.state.forcaseChartData[0].datetime,
    ).getFullYear();
    const lastYear = new Date(
      this.state.forcaseChartData[
        this.state.forcaseChartData.length - 1
      ].datetime,
    ).getFullYear();

    const yearOptions = [];
    do {
      yearOptions.push(firstYear);
      firstYear = 1 + firstYear;
    } while (firstYear < lastYear + 1);

    return yearOptions.map((data) => {
      return (
        <option value={data} key={`year${data}`}>
          {data}
        </option>
      );
    });
  };

  handleYearDropdownChange = (e) => {
    this.setState({
      selectedForcastYear: e.target.value,
    });
  };

  handleMonthDropdownChange = (e) => {
    this.setState({
      selectedForcastMonth: e.target.value,
    });
  };

  render() {
    console.log("------------Set State Called ---------------");
    console.log(
      "selectedMixOfSupplyData :",
      this.state.selectedMixOfSupplyData,
    );
    console.log("Handling Fees :", this.state.handlingFees);
    console.log("GoO :", this.state.gOO);
    console.log("Manuel :", this.state.manuelData);

    // If User click Prix moyen seul then Distinction Summer must be false
    if (
      this.state.isDifferentTariff === false &&
      this.state.isSummerDistinction
    ) {
      this.setState({
        isSummerDistinction: false,
      });
    }

    // // supplyPeriod year = 1 & array has 4 item (In Bydefault Stage) so remove item from array
    if (
      this.state.selectedYear === "1 an" &&
      this.state.selectedBasePriceMix[4] === ""
    ) {
      this.removeItemFromArray(1);
    }

    const clientName = JSON.parse(localStorage.getItem(selectedClientPartner))
      .name;
    const yearsArr = [];
    supplyYears.forEach((yearVal, index) => {
      yearsArr.push({
        value: index + 1,
        label: yearVal,
      });
    });
    const years = supplyYears.map((year) => {
      return (
        <Button
          style={{ textTransform: "lowercase" }}
          className={
            this.state.selectedYear === year
              ? "selectedYearBlock"
              : "unselectedYearBlock"
          }
          onClick={this.handleSupplyYear}
        >
          {year}
        </Button>
      );
    });

    const validOfferHour = OfferValidityHours.map((hour) => {
      return (
        // <OutlinedInput
        //           placeholder="ex: 10000"
        //           className="manualInput"
        //           value={this.state.selectedValidityOfferHour}
        //           onChange={this.handleValidityHours}
        //           endAdornment={
        //             <InputAdornment position="end">h</InputAdornment>
        //           }
        //           labelWidth={0}
        //         />
        <Button
          style={{ textTransform: "lowercase" }}
          className={
            this.state.selectedValidityOfferHour === hour
              ? "selectedYearBlock"
              : "unselectedYearBlock"
          }
          onClick={this.handleValidityHours}
        >
          {hour}
        </Button>
      );
    });

    // If Base De Prix = Mix, so show the Years with dropdown according to selected Years
    let priceMix = "";
    if (this.state.isPriceMix) {
      priceMix = this.state.years.map((year, index) => {
        return (
          <Grid
            item
            xs={8}
            sm={6}
            style={{ marginLeft: "auto", marginRight: "1px" }}
          >
            <span className="formLabelBlueSteps">{year}</span>
            <OutlinedInput
              className="manualInputSelect"
              placeholder="ex: 0.25"
              name="manuel"
              type="number"
              id={`basedeprix${index}`}
              value={this.state.selectedBasePriceMix[index]}
              onChange={this.handleDropdownChange(index)}
              onKeyDown={(e) => {if (e.key === '-') e.preventDefault()}}
              labelWidth={0}
              style={{ width: "5.5em", marginLeft: "1em" }}
              readOnly={this.state.checkboxManual[index]}
            />
            <span
              className="formLabelGreySteps"
              style={{ marginLeft: "1em", marginRight: "1em" }}
            >
              CHF/MWh
            </span>
            {/* <Checkbox
              checked={this.state.checkboxManual[index]}
              // eslint-disable-next-line no-unused-expressions
              onChange={() => {
                // eslint-disable-next-line react/no-access-state-in-setstate
                const checkboxManualTemp = this.state.checkboxManual;
                checkboxManualTemp[index] = !checkboxManualTemp[index];
                const costingPricesArr = JSON.parse(
                  localStorage.getItem(costingPrices),
                );
                // eslint-disable-next-line react/no-access-state-in-setstate
                const selectedBasePriceMixTemp = this.state
                  .selectedBasePriceMix;
                selectedBasePriceMixTemp[index] = costingPricesArr[index];
                this.setState({
                  checkboxManual: checkboxManualTemp,
                  selectedBasePriceMix: selectedBasePriceMixTemp,
                });
              }}
              name="checkedBManual"
              color="primary"
            />
            <span className="formLabelGreySteps" style={{ marginRight: "1em" }}>
              marché
            </span> */}
            {/* <Select
              variant="outlined"
              className="manualInputSelect"
              size="small"
              value={this.state.selectedBasePriceMix[index]}
              onChange={this.handleDropdownChange(index)}
              style={{ marginLeft: "1em" }}
            >
              {this.renderDropDown(index)}
            </Select> */}
          </Grid>
        );
      });
    }

    const mixOfSupplies = mixOfSupply.map((item) => {
      return (
        <Button
          style={{ textTransform: "none", width: "8.5em" }}
          className={
            this.state.selectedMixOfSupply === item
              ? "selectedYearBlock"
              : "unselectedYearBlock"
          }
          onClick={this.handleMixOfSupply}
        >
          {item}
        </Button>
      );
    });

    // If Mix de fournitures = Manuel, so show the Years with textfield according to selected Years
    let supplyManuel = "";
    if (this.state.selectedMixOfSupply === "Manuel") {
      supplyManuel = this.state.years.map((year, index) => {
        return (
          <Grid
            item
            xs={7}
            sm={3}
            style={{
              marginLeft: "auto",
              marginRight: "5px",
              minWidth: "16em",
              marginBottom: "10px",
            }}
          >
            {/* <Grid item xs={8} sm={1}> */}
            <span
              className="formLabelBlueSteps"
              style={{ marginRight: "15px" }}
            >
              {year}
            </span>
            <OutlinedInput
              className="manualInputSelect"
              name="manuel"
              placeholder="ex: 0.55"
              id={index}
              value={this.state.manuelData[index]}
              onChange={this.handleSupplyManuel}
              onKeyDown={(e) => {if (e.key === '-') e.preventDefault()}}
              labelWidth={0}
              style={{ width: "5.5em" }}
            />
            <span
              className="formLabelGreySteps"
              style={{ marginLeft: "1.5em" }}
            >
              CHF/MWh
            </span>
          </Grid>
        );
      });
    }

    // GOO, so show the Years with textfield according to selected Years
    const gOo = this.state.years.map((year, index) => {
      return (
        <Grid
          item
          xs={2}
          sm={12}
          style={{ marginLeft: "5px", marginTop: "10px" }}
        >
          <span className="formLabelBlueSteps" style={{ marginRight: "15px" }}>
            {year}
          </span>
          <OutlinedInput
            className="manualInputSelect"
            name="manuel"
            type="number"
            placeholder="ex: 0.35"
            id={index}
            value={this.state.gOO[index]}
            onChange={this.handleGoo}
            onKeyDown={(e) => {if (e.key === '-') e.preventDefault()}}
            labelWidth={0}
            style={{ width: "5.5em" }}
          />
          <span className="formLabelGreySteps" style={{ marginLeft: "1em" }}>
            CHF/MWh
          </span>
        </Grid>
      );
    });

    // Commercial Margin, so show the Years with textfield according to selected Years

    const commercialMargin = this.state.years.map((year, index) => {
      return (
        <Grid
          item
          xs={2}
          sm={12}
          style={{ marginLeft: "5px", marginTop: "10px" }}
        >
          {/* <Grid item xs={8} sm={1}> */}
          <span className="formLabelBlueSteps" style={{ marginRight: "15px" }}>
            {year}
          </span>
          <OutlinedInput
            className="manualInputSelect"
            placeholder="ex: 0.25"
            name="manuel"
            type="number"
            id={index}
            value={this.state.commercialMargin[index]}
            onChange={this.handleCommercialMargin}
            onKeyDown={(e) => {if (e.key === '-') e.preventDefault()}}
            labelWidth={0}
            style={{ width: "5.5em" }}
          />
          <span className="formLabelGreySteps" style={{ marginLeft: "1em" }}>
            CHF/MWh
          </span>
        </Grid>
      );
    });

    // Handling Fee, so show the Years with textfield according to selected Years
    const handlingFee = this.state.years.map((year, index) => {
      return (
        <Grid
          item
          xs={2}
          sm={12}
          style={{ marginLeft: "5px", marginTop: "10px" }}
        >
          {/* <Grid item xs={8} sm={1}> */}
          <span className="formLabelBlueSteps" style={{ marginRight: "15px" }}>
            {year}
          </span>
          <OutlinedInput
            className="manualInputSelect"
            name="manuel"
            type="number"
            placeholder="ex: 0.35"
            id={index}
            value={this.state.handlingFees[index]}
            onChange={this.handleHandlingFee}
            onKeyDown={(e) => {if (e.key === '-') e.preventDefault()}}
            labelWidth={0}
            style={{ width: "5.5em" }}
          />
          <span className="formLabelGreySteps" style={{ marginLeft: "1em" }}>
            CHF/MWh
          </span>
        </Grid>
      );
    });

    const hourMarks = [];
    for (let i = 0; i <= 24; i += 1) {
      hourMarks.push({
        value: i,
        label: `${i.toString()}h`,
      });
    }
    const sliderWidth = 24 * 28;

    let showSettingsPopup = "";
    settingObj = JSON.parse(localStorage.getItem(applicationSetting));
    console.log("Hi", settingObj);
    if (this.state.showSettings) {
      showSettingsPopup = (
        <Settings
          settingsObj={settingObj}
          closeBtnClick={this.closeSuccessPopup}
        />
      );
    }

    const coverageSituationName = ["GdO hydro CH", "GdO hydro EU", "Nucléaire"];
    const coverageSituationValue = {
      GdO_hydro_CH: [],
      GdO_hydro_EU: [],
      sans: [],
    };

    let currentYearVal = new Date().getFullYear();

    this.state.configData.forEach((data) => {
      currentYearVal += 1;
      coverageSituationValue.GdO_hydro_CH.push({
        y: data.GdO_hydro_CH,
        x: currentYearVal,
      });
      coverageSituationValue.GdO_hydro_EU.push({
        y: data.GdO_hydro_EU,
        x: currentYearVal,
      });
      coverageSituationValue.sans.push({
        y: data.sans,
        x: currentYearVal,
      });
    });

    const extraData = [
      {
        name: coverageSituationName[0],
        data: coverageSituationValue.GdO_hydro_CH,
      },
      {
        name: coverageSituationName[1],
        data: coverageSituationValue.GdO_hydro_EU,
      },
      {
        name: coverageSituationName[2],
        data: coverageSituationValue.sans,
      },
    ];

    extraData.forEach((extraDataObj, index) => {
      extraDataObj.data.forEach((dataVal, dataIndex) => {
        extraData[index].data[dataIndex] = [dataVal.x, dataVal.y];
      });
    });

    const yearMonth = (
      <Grid
        item
        xs={10}
        sm={10}
        style={{
          marginLeft: "auto",
          marginRight: "1px",
          marginTop: "-0.5em",
        }}
      >
        <div className="yearSelectHistoryContainer">
          <span>Year</span>
          <Select
            variant="outlined"
            className="manualInputSelect yearSelectHistory"
            size="small"
            value={this.state.selectedForcastYear}
            onChange={this.handleYearDropdownChange}
          >
            {this.renderYearDropDown()}
          </Select>
        </div>

        <div className="yearSelectHistoryContainer">
          <span>Month</span>
          <Select
            variant="outlined"
            className="manualInputSelect yearSelectHistory"
            size="small"
            value={this.state.selectedForcastMonth}
            onChange={this.handleMonthDropdownChange}
          >
            {renderMonthDropDown()}
          </Select>
        </div>
      </Grid>
    );

    const chartData = [];
    this.state.forcaseChartData.forEach((data) => {
      if (
        new Date(data.datetime).getFullYear() ===
          this.state.selectedForcastYear &&
        new Date(data.datetime).getMonth() + 1 ===
          this.state.selectedForcastMonth
      ) {
        chartData.push({
          x: data.datetime,
          y: data.value,
        });
      }
    });

    chartData.splice(7000);

    console.log("this.state.priceChartData: ", this.state.priceChartData);
    console.log("extraData: ", extraData);
    console.log("chartData: ", chartData);
    // const priceChartData = {
    //     name: 'Price',
    //     data: [],
    // };

    // this.state.priceChartData.forEach((dataVal) => {
    //     console.log("dataVal: ", dataVal);
    //     priceChartData.data.push([dataVal.x, dataVal.y]);
    // });

    // console.log("priceChartData: ", priceChartData);

    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: this.state.showForcastHistory ? "" : "none",
            paddingTop: "1em",
          }}
          className="graphDiv"
        >
          {yearMonth}
          <LineChart
            // chartData={this.state.forcaseChartData}
            chartData={chartData}
            closeBtnClick={this.closeForcaseGraphPopup}
            title="Visualiser prévisions"
            xTitle="Date et heure"
            yTitle="Consommation (kWh)"
          />
        </div>

        <div
          style={{
            display: this.state.showCoverageSituation ? "" : "none",
          }}
          className="graphDiv"
        >
          <LineChart
            // chartData={coverageSituationValue.GdO_hydro_CH}
            closeBtnClick={this.closeCoverageSituationGraphPopup}
            title="Voir situation GdO"
            // yTitle={coverageSituationName[0]}
            // xTitle={coverageSituationName[0]}
            xTitle="Années"
            yTitle="Prix €"
            gdOHydroCH={extraData[0]}
            gdOHydroEU={extraData[1]}
            sans={extraData[2]}
            xValueFontSize="16px"
            showLegends
          />
        </div>

        <div
          style={{ display: this.state.showPriceChart ? "" : "none" }}
          className="graphDiv"
        >
          <LineChart
            chartData={this.state.priceChartData}
            // priceChartData={priceChartData}
            closeBtnClick={this.closePriceGraphPopup}
            title="Voir situation marché"
            xTitle="Années"
            yTitle="Prix € / MWh"
            yValueSufix="€ / MWh"
            xValueFontSize="16px"
            dataLabels
          />
        </div>
        {showSettingsPopup}
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="left"
          justify="center"
        >
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Client</div>
            </Grid>
            <Grid>
              <div className="formLabelBlueSteps">{clientName}</div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Durée de fourniture</div>
            </Grid>
            {/* <Grid>{years}</Grid> */}
            <Grid
              style={{
                marginLeft: "15px",
                width: `16em`,
              }}
            >
              <Slider
                track={false}
                onChange={this.handleSupplyYear}
                aria-labelledby="track-false-slider"
                marks={yearsArr}
                min={1}
                max={5}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Validité de l'offre</div>
            </Grid>
            <Grid>
              <OutlinedInput
                  required
                  error={this.state.showValidityHoursError}
                  placeholder="ex: 120h"
                  className="manualInputSelect"
                  value={this.state.selectedValidityOfferHour}
                  onChange={this.handleValidityHours}
                  style={{ width: "4.5em" }}
                  endAdornment={
                    <InputAdornment position="end">h</InputAdornment>
                  }
                  labelWidth={0}
                />
                <span className="formLabelGreySteps" style={{ marginLeft: "1em" }}>
            (entre 0 et 168 heures)
          </span>
                </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Base de prix</div>
            </Grid>
            <Grid item xs={4} sm={3} style={{ marginLeft: "0px" }}>
              <Grid component="label" container alignItems="center" spacing={0}>
                <Grid
                  className={
                    this.state.isPriceMix
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingRight: "1em" }}
                >
                  Marché
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={this.state.isPriceMix}
                    onChange={this.handlePriceBaseChange}
                  />
                </Grid>
                <Grid
                  className={
                    !this.state.isPriceMix
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingLeft: "1em" }}
                >
                  Manuelle
                </Grid>
              </Grid>
            </Grid>

            {priceMix}
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid style={{ marginRight: "auto" }}>
              <GrayButton
                btnName="Voir situation marché"
                btnClick={this.handleMarketSituation}
              />
            </Grid>
          </Grid>

          {/* <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            style={{ marginTop: "1.8em", minHeight: "1.8em" }}
          >
            <Grid style={{ marginRight: "auto" }}>
              <GrayButton
                btnName="Garanties d'Origine"
                btnClick={this.handleCoverageSituation}
              />
            </Grid>
          </Grid> */}

          {/* <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Mix de fournitures</div>
            </Grid>

            <Grid>{mixOfSupplies}</Grid>
            <div>{supplyManuel}</div>
          </Grid> */}

<         Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer" style={{ marginTop: "8px" }}>
              <div className="formLabelBlueSteps">Garanties d'Origine</div>
            </Grid>
            <Grid>{gOo}</Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer" style={{ marginTop: "8px" }}>
              <div className="formLabelBlueSteps">Marge commerciale</div>
            </Grid>
            <Grid>{commercialMargin}</Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer" style={{ marginTop: "8px" }}>
              <div className="formLabelBlueSteps">Frais de gestion</div>
            </Grid>
            <Grid>{handlingFee}</Grid>
          </Grid>

          {/* <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Devise de l’offre</div>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Grid component="label" container alignItems="center" spacing={0}>
                <Grid
                  className={
                    this.state.isOfferCurrencyCHF
                      ? "formLabelBlueSteps"
                      : "formLabelGreySteps"
                  }
                  style={{ paddingRight: "1em" }}
                >
                  CHF
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={!this.state.isOfferCurrencyCHF}
                    onChange={this.handleOfferCurrencyChange}
                  />
                </Grid>
                <Grid
                  className={
                    !this.state.isOfferCurrencyCHF
                      ? "formLabelBlueSteps"
                      : "formLabelGreySteps"
                  }
                  style={{ paddingLeft: "1em" }}
                >
                  €
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps">Profil tarifaire</div>
            </Grid>
            <Grid>
              <Grid component="label" container alignItems="center" spacing={0}>
                <Grid
                  className={
                    this.state.isDifferentTariff
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingRight: "1em" }}
                >
                  Prix moyen seul
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={this.state.isDifferentTariff}
                    onChange={this.handleTariffProfileChange}
                  />
                </Grid>
                <Grid
                  className={
                    !this.state.isDifferentTariff
                      ? "formLabelGreySteps"
                      : "formLabelBlueSteps"
                  }
                  style={{ paddingLeft: "1em" }}
                >
                  Tarifs différents
                </Grid>

                {this.state.isDifferentTariff === true && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isSummerDistinction}
                        onChange={this.handleSummerDistinction}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Distinction été/hiver"
                    className="formLabelGreySteps"
                    style={{ marginLeft: "2em" }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer" />
            <Grid>
              <span className="formLabelBlueSteps">Répartition</span>

              <span style={{ marginLeft: "2em" }}>Bas tarif</span>

              <span style={{ marginLeft: "2em" }}>Haut tarif</span>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer" />
            <Grid>
              <span className="formLabelBlueSteps">Du lundi au vendredi</span>
            </Grid>
            <Grid>
              <div
                style={{
                  width: `${sliderWidth}px`,
                  marginLeft: "2em",
                }}
              >
                <Slider
                  value={this.state.hourRangeMtoF}
                  onChange={this.handleHourChangeMonToFri}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  marks={hourMarks}
                  min={0}
                  max={24}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            className="stepsRowContainer"
          >
            <Grid className="stepsLblContainer" />
            <Grid>
              <span className="formLabelBlueSteps">Samedi et dimanche</span>
            </Grid>
            <Grid>
              <div
                style={{
                  width: `${sliderWidth}px`,
                  marginLeft: "2em",
                }}
              >
                <Slider
                  value={this.state.hourRangeSAndS}
                  onChange={this.handleHourChangeSatAndSun}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  marks={hourMarks}
                  min={0}
                  max={24}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            style={{ marginTop: "1.8em", minHeight: "1.8em" }}
          >
            <Grid className="stepsLblContainer">
              <div className="formLabelBlueSteps"> </div>
            </Grid>
            <Grid style={{ marginRight: "auto" }}>
              <GrayButton
                btnName="Visualiser prévisions"
                btnClick={this.showForcastGraph}
              />
            </Grid>
            {this.showCostingButtonToAdmin()}
          </Grid>
        </Grid>

        <div className="stepSaveButton">
          <BlueButton
            btnName="Calculer l’Offre"
            btnClick={this.handleCalculateOffer}
          />
        </div>
      </div>
    );
  }
}

// export default OfferParameteres;

const mapDispatchToProps = (dispatch) => ({
  changeStepTwoShow: (isShow) => dispatch(changeStepTwoShow(isShow)),
  changeStepThreeShow: (isShow) => dispatch(changeStepThreeShow(isShow)),
});

export default connect(null, mapDispatchToProps)(OfferParameteres);
