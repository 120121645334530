import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import axios from "axios";
// import qs from "qs";

import { getUserDetail } from "../../store/actions";
import { checkEmailisValid } from "../../utility";
import CustomInputField from "../../hoc/CustomInputField";
import AppLogo from "../../hoc/AppLogo";
import SubTitle from "../../hoc/SubTitle";
import BlueButton from "../../hoc/BlueButton";
import GrayButton from "../../hoc/GrayButton";
import { accessToken } from "../../constants";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "", // 'test@example.com',
      password: "", // '12345',
      errorEmail: false,
      emailErrorMessage: "",
      errorpassword: false,
      passwordErrorMessage: "",
      forgetPassword: false,
    };
  }

  componentDidMount = () => {
    localStorage.clear();
    document.getElementById("leftsidebar").style.display = "none";
  };

  // Input field handle - Email
  handleEmailChange = (newValue) => {
    this.setState({
      email: newValue,
      errorEmail: false,
      emailErrorMessage: "",
    });
  };

  // Input field handle - Password
  handlePasswordChange = (newValue) => {
    this.setState({
      password: newValue,
      errorpassword: false,
      passwordErrorMessage: "",
    });
  };

  // Password field handle enter event
  handleKeyUpEvent = (event) => {
    if (event.key === "Enter") this.handleContinue();
  };

  handleForgetPassword = () => {
    this.setState({ forgetPassword: true });
  };

  handleContinue = () => {
    // Validation for Login Inputs
    if (this.state.email === "") {
      this.setState({
        errorEmail: true,
        emailErrorMessage: "E-mail ne peut pas être vide.",
      });
      return;
    }

    if (!checkEmailisValid(this.state.email)) {
      this.setState({
        errorEmail: true,
        emailErrorMessage: "L'email n'est pas valide",
      });
      return;
    }
    if (this.state.password === "") {
      this.setState({
        errorpassword: true,
        passwordErrorMessage: "Le mot de passe ne peut pas être vide.",
      });
      return;
    }
    console.log("Clear");
    // const userCredential = new URLSearchParams();
    // userCredential.append("email", this.state.email);
    // userCredential.append("password", this.state.password);

    const userCredential = {
      email: this.state.email,
      password: this.state.password,
    };

    // call an action method
    // eslint-disable-next-line react/prop-types
    this.props.onGetUserDetail(userCredential);
  };

  render() {
    if (this.props.userDetail && localStorage.getItem(accessToken) !== null) {
      return <Redirect to="/userselection" />;
    }

    if (this.state.forgetPassword) {
      return <Redirect to="/forgetpassword" />;
    }

    return (
      <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="formContainer"
        >
          <AppLogo />
          <SubTitle titleText="COSTING PLATFORM" />
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justify="center"
            xs={6}
            style={{ marginTop: "3em" }}
          >
            <Grid item xs={5} sm={3}>
              <div className="formLabel">Login*</div>
            </Grid>
            <Grid item xs={6} sm={9}>
              <CustomInputField
                fieldName="E-mail or Username"
                fieldType="email"
                fieldValue={this.state.email}
                isError={this.state.errorEmail}
                errorMessage={this.state.emailErrorMessage}
                textChange={this.handleEmailChange}
                keyUpEvent={this.handleKeyUpEvent}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justify="center"
            xs={6}
            style={{ marginTop: "1em" }}
          >
            <Grid item xs={5} sm={3}>
              <div className="formLabel">Mot de passe*</div>
            </Grid>
            <Grid item xs={6} sm={9}>
              <CustomInputField
                fieldName="Password"
                fieldType="password"
                fieldValue={this.state.password}
                isError={this.state.errorpassword}
                errorMessage={this.state.passwordErrorMessage}
                textChange={this.handlePasswordChange}
                keyUpEvent={this.handleKeyUpEvent}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justify="center"
            xs={6}
            style={{ marginTop: "1em" }}
          >
            <Grid item xs={4} sm={4}>
              <div className="grayLabel">* Renseignements obligatoires</div>
            </Grid>

            <Grid item xs={4} sm={4}>
              <GrayButton
                btnName="Mot de passe oublié?"
                btnClick={this.handleForgetPassword}
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <BlueButton btnName="Valider" btnClick={this.handleContinue} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

// Get userDetail object from Redux Store
const mapStateToProps = (state) => ({
  userDetail: state.user.userDetail,
});

// call an action for redux
const mapDispatchToProps = (dispatch) => ({
  onGetUserDetail: (userCredential) => dispatch(getUserDetail(userCredential)),
});

Login.propTypes = {
  userDetail: PropTypes.objectOf(PropTypes.any),
};

Login.defaultProps = {
  userDetail: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
