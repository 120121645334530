import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";

import SubTitle from "../../hoc/SubTitle";
import GrayButton from "../../hoc/GrayButton";
import { altisUrl, roleID, userID } from "../../constants";
import { GETAltis } from "../../apis/ServerApis";
import {
  handleResponseCode,
  handleException,
  handleFailure,
} from "../../utility";
import BackButton from "../../hoc/BackButton";
import AddPartner from "../../containers/Partner/AddPartner";

class ViewMyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: "",
      isEditInformation: false,
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = () => {
    const userId = localStorage.getItem(userID);

    const url = `${altisUrl}users/${userId}`;

    GETAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.data) {
            this.setState({
              userData: response.data,
            });
          }
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  handleEditInformation = () => {
    this.setState({
      isEditInformation: true,
    });
  };

  // Close EditInfo Popup
  closePopupEditContact = () => {
    this.setState({
      isEditInformation: false,
    });
    this.getUserData();
  };

  render() {
    const renderAdminButtons = localStorage.getItem(roleID) === "2";
    if (this.state.isEditInformation) {
      const titleText = "Modifier mes inforrmations";
      return (
        <AddPartner
          titleTexts={titleText}
          contactObj={this.state.userData}
          closeBtnClick={this.closePopupEditContact}
        />
      );
      // if(renderAdminButtons){
      //     return (
      //         <AddPartner
      //             contactObj={this.state.userData}
      //             closeBtnClick={this.closePopupEditContact}
      //         />
      //     );

      // }
      // return <Redirect to="editmyinformation" />;
    }
    return (
      <div>
        <BackButton />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="myAccountContainer"
        >
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ marginTop: "3em" }}
          >
            <div className="avtarLogo" />
            <SubTitle titleText="Mon compte" customeColor="#A7B6C6" />
            <div className="grayArrowIcon" />
            <SubTitle titleText="Mes informations" />
          </Grid>
          <hr
            style={{
              backgroundColor: "#FFFFFF",
              height: 1,
            }}
          />

          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="center"
            style={{ height: "100%" }}
          >
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              item
              xs={6}
              sm={6}
              justify="center"
              className="myInfoView"
            >
              <div className="myInfoRow">
                <span className="myInfoLabel">Nom de la société *</span>
                <span className="myInfoValue">{this.state.userData.name}</span>
              </div>

              <div className="myInfoRow">
                <span className="myInfoLabel">Adresse*</span>
                <span className="myInfoValue">
                  {this.state.userData.street}
                </span>
                <span className="myInfoLabel">Numéro*</span>
                <span className="myInfoValue">
                  {this.state.userData.address}
                </span>
              </div>

              <div className="myInfoRow">
                <span className="myInfoLabel">Ville*</span>
                <span className="myInfoValue">{this.state.userData.city}</span>

                <span className="myInfoLabel">CP*</span>
                <span className="myInfoValue">{this.state.userData.cp}</span>

                <span className="myInfoLabel">Canton*</span>
                <span className="myInfoValue">
                  {this.state.userData.canton}
                </span>
              </div>

              <div className="myInfoRow">
                <span className="myInfoLabel">E-mail de contact*</span>
                <span className="myInfoValue">{this.state.userData.email}</span>

                <span className="myInfoLabel">Numéro de Tél.*</span>
                <span className="myInfoValue">{this.state.userData.phone}</span>
              </div>

              <div className="myInfoRow" style={{ marginLeft: "0px" }}>
                <GrayButton
                  btnName="Modifier mes informations"
                  className="marginLeftZero"
                  btnClick={this.handleEditInformation}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

// const ViewMyInfo = () => {
//     const [userData, setUserData] = useState([]);
//     const renderAdminButtons = localStorage.getItem(roleID) === "2";

//     useEffect(() => {
//         // side effect hook

//         const userId = localStorage.getItem(userID);

//         const url = `${altisUrl}users/${userId}`;

//         GETAltis(url)
//             .then((response) => {
//                 console.log(response);
//                 if (handleResponseCode(response)) {
//                     if (response.data) {
//                         setUserData(response.data);
//                     }
//                 } else {
//                     handleFailure(response);
//                 }
//             })
//             .catch((error) => {
//                 handleException(error);
//             });
//     }, [setUserData]);

//     console.log("UserData is :", userData);
//     const [isOpenEditMyInfo, setIsOpenEditMyContacts] = useState(false);

//     // Close EditContact Popup
//     const closePopupEditContact = () => {
//         setIsOpenEditMyContacts(false);
//     };

//     if (isOpenEditMyInfo) {
//         if(renderAdminButtons){
//             return (
//                 <AddPartner
//                     contactObj={userData}
//                     closeBtnClick={closePopupEditContact()}
//                 />
//             );

//         }
//         return <Redirect to="editmyinformation" />;

//     }

//     return (
//         <div>
//             <BackButton />
//             <Grid
//                 container
//                 spacing={0}
//                 direction="column"
//                 alignItems="flex-start"
//                 justify="center"
//                 style={{ minHeight: "100vh" }}
//                 className="myAccountContainer"
//             >
//                 <Grid
//                     container
//                     direction="row"
//                     alignItems="flex-start"
//                     style={{ marginTop: "3em" }}
//                 >
//                     <div className="avtarLogo" />
//                     <SubTitle titleText="Mon compte" customeColor="#A7B6C6" />
//                     <div className="grayArrowIcon" />
//                     <SubTitle titleText="Mes Informations" />
//                 </Grid>
//                 <hr
//                     style={{
//                         backgroundColor: "#FFFFFF",
//                         height: 1,
//                     }}
//                 />

//                 <Grid
//                     container
//                     direction="column"
//                     alignItems="flex-start"
//                     justify="center"
//                     style={{ height: "100%" }}
//                 >
//                     <Grid
//                         container
//                         direction="row"
//                         alignItems="flex-start"
//                         item
//                         xs={6}
//                         sm={6}
//                         justify="center"
//                         className="myInfoView"
//                     >
//                         <div className="myInfoRow">
//                             <span className="myInfoLabel">
//                                 Nom de la société *
//                             </span>
//                             <span className="myInfoValue">{userData.name}</span>
//                         </div>

//                         <div className="myInfoRow">
//                             <span className="myInfoLabel">Adresse*</span>
//                             <span className="myInfoValue">
//                                 {userData.street}
//                              </span>
//                             <span className="myInfoLabel">Numéro*</span>
//                             <span className="myInfoValue">
//                                 {userData.address}
//                             </span>
//                         </div>

//                         <div className="myInfoRow">
//                             <span className="myInfoLabel">Ville*</span>
//                             <span className="myInfoValue">{userData.city}</span>

//                             <span className="myInfoLabel">CP*</span>
//                             <span className="myInfoValue">{userData.cp}</span>

//                             <span className="myInfoLabel">Canton*</span>
//                             <span className="myInfoValue">
//                                 {userData.canton}
//                             </span>
//                         </div>

//                         <div className="myInfoRow">
//                             <span className="myInfoLabel">
//                                 E-mail de contact*
//                             </span>
//                             <span className="myInfoValue">
//                                 {userData.email}
//                             </span>

//                             <span className="myInfoLabel">Numéro de Tél.*</span>
//                             <span className="myInfoValue">
//                                 {userData.phone}
//                             </span>
//                         </div>

//                         <div
//                             className="myInfoRow"
//                             style={{ marginLeft: "0px" }}
//                         >
//                             <GrayButton
//                                 btnName="Modifier mes informations"
//                                 className="marginLeftZero"
//                                 btnClick={() => setIsOpenEditMyContacts(true)}
//                             />
//                         </div>
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </div>
//     );
// };

export default ViewMyInfo;
