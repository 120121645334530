import swal from "sweetalert";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import jsPDF from "jspdf";
import "jspdf-autotable";
import print from "print-js";

import {
    userID,
    accessToken,
    tokenID,
    tokenType,
    roleID,
    loginUserName,
    monthsList,
    offerFieldsList,
} from "./constants";


// Generate a Random Device Id - 16 digit
export const deviceIDRandomGenerator = () => {
    const minm = 1000000000000000;
    const maxm = 9999999999999999;
    const randomNo = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    return randomNo;
};

// Show spinnerß
export const showSpinner = (displaySpinner, loadingText) => {
    if (document.getElementById("spinner")) {
        if (
            displaySpinner &&
            document.getElementById("spinner").style.display === ""
        ) {
            return;
        }
        if (!loadingText) {
            // eslint-disable-next-line no-param-reassign
            loadingText = "Chargement";
        }
        document.getElementById(
            "spinner"
        ).innerHTML = `<div class="loader"></div>${loadingText}`;
        if (displaySpinner) {
            document.getElementById("spinner").style.display = "";
        } else document.getElementById("spinner").style.display = "none";
    }
};

// For HandleResponseCode and it return true or false
export const handleResponseCode = (response) => {
    console.log("handleResponseCode");
    if (response.success && response.data !== null) {
        return true;
    }
    return false;
};

// Show alert using sweetalert Plugin
export const showAlert = (title, message) => {
    swal(title, message.split("Ã©").join("é"));
};

// When api gets fail then used handle failure
export const handleFailure = (response) => {
    console.log(" Failure Response : ", response);
    console.log(" Failure Response Status: ", response.status);
    // if accessToken not available so spi gave Unauthenticated
    if(response.message === "Unauthenticated.") {
        window.location.href = "#/login";
        localStorage.clear();
        showAlert("Erreur", "Non authentifié. Merci de vous connecter pour continuer.");
    } else if (!response.success && response.message) {
        showAlert("Erreur", response.message);
    } else {
        showAlert("Erreur", "Erreur interne du serveur");
    }
};

// Handle Exception like if api fall in to catch block so we call it to show error message
export const handleException = (error) => {
    console.log("Error 122 :", error.status);
    if (typeof error === "string") {
        showAlert("Erreur", error);
    } else {
        // showAlert("Erreur", JSON.stringify(error));
        window.location.href = "#/login";
        localStorage.clear();
        showAlert("Erreur", "Erreur interne du serveur");
    }
};

export const checkEmailisValid = (email) => {
    const pattern = new RegExp(
        // eslint-disable-next-line comma-dangle
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (pattern.test(email)) {
        return true;
    }
    return false;
};

// Store data in LocalStorage like userId, accessToken while login or signup
export const storeTokenData = (data) => {
    // localStorage.setItem(userID, data.account_id);
    // localStorage.setItem(refreshToken, data.refresh_token);
    localStorage.setItem(accessToken, data.access_token);
    localStorage.setItem(tokenType, data.token_type);
    localStorage.setItem(tokenID, data.token_id);
    localStorage.setItem(userID, data.user.id);
    localStorage.setItem(loginUserName, data.user.name);
    localStorage.setItem(roleID, data.user.roles[0].id);
};

// Remove all data from local storge except deviceId
export const removeTokenData = () => {
    // const deviceIdOld = localStorage.getItem(deviceId);
    localStorage.clear();
    // localStorage.setItem(deviceId, deviceIdOld);
};

// Convert File or Image to Base64
export const getBase64 = (file) => {
    return new Promise((resolve) => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        const reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
        };
        console.log(fileInfo);
    });
};

export const checkLeapYears = (startYear, endYear) => {
    let totalLeapYears = 0;
    // eslint-disable-next-line no-plusplus
    for(let i=startYear; i<=endYear; i++){
        if (i % 100 === 0 ? i % 400 === 0 : i % 4 === 0){
            totalLeapYears += 1;
        }
    }
    console.log('leapyear :::: ', totalLeapYears);
    return totalLeapYears;
}

export const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        paddingBottom: 2,
        display: "flex",
    },
    switchBase: {
        padding: 2,
        color: "#43bfef",
        "&$checked": {
            transform: "translateX(12px)",
            color: "#43bfef",
            "& + $track": {
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: theme.palette.common.white,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: "none",
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

export const generateOfferPdf = (fieldsList, offerDetails, isPartnerView, summerWinter, isDistinction) => {
    console.log(" FieldList : ", fieldsList);
    console.log(" offerDetails : ", offerDetails);
    console.log(" isPartnerView : ", isPartnerView);
    console.log(" isPartnerView : ", summerWinter);
    console.log(" isPartnerView : ", isDistinction.toString());

    let isSummerWinter = summerWinter;
    if(typeof(summerWinter) === "number" ){
        isSummerWinter = summerWinter === 1 ? "true" : "false";
    }

    let isDistinctionNew = isDistinction;
    if(isDistinction === "Distinction"  || isDistinction === "average")
    {
        isDistinctionNew = isDistinction === "Distinction" ? "true" : "false";
    }
     

    console.log(" isSummerWinter : ", isSummerWinter);
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();

    // define the columns we want and their titles
    const tableColumn = ["Years"];
    // define an empty array of rows
    const tableRows = [];

    let rowObj = [];
    fieldsList.forEach((fieldObj) => {
        rowObj = [];
        rowObj.push(fieldObj.name);
        if (offerDetails[fieldObj.fieldName]) {
            offerDetails[fieldObj.fieldName].forEach((value, index) => {
                if (fieldObj.fieldName === "years") {
                    tableColumn.push(
                        `${value} : ${offerDetails.final_price[index]}`
                    );
                } else {
                    rowObj.push(value);
                }
            });
        }

        if (!isPartnerView || fieldObj.showClient) {
            if (fieldObj.fieldName !== "years") {
                if((typeof(fieldObj.showSummerWinter) === 'undefined' ? true : (isSummerWinter === fieldObj.showSummerWinter.toString() )) &&
                    (typeof(fieldObj.showDistinction) === 'undefined' ? true : (isDistinctionNew === fieldObj.showDistinction.toString()))){
                    tableRows.push(rowObj);
                }
            }
        }
    });

    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, {
        startY: 20,
        didParseCell(data) {
            const rows = data.table.body;
            if (data.row.index === rows.length - 1) {
                // eslint-disable-next-line no-param-reassign
                data.cell.styles.fillColor = [239, 154, 154];
            }
        },
    });
    const date = Date().split(" ");
    // we use a date string to generate our filename.
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
    // ticket title. and margin-top + margin-left
    // doc.text("Closed tickets within the last one month.", 14, 15);
    // we define the name of our PDF file.
    doc.save(`offer_${dateStr}.pdf`);
};

// Change dd.mm.yyyy hh:mm string to date
export const stringToDate = (datetime) => {
    return new Date(datetime.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3"));
};

// Change yyyy-mm-dd hh:mm string to date
export const setDateYYYYMMDD = (datetime) => {
    // const timezoneOffSet = datetime.getTimezoneOffset();
    // const timeZoneToAdd = `${  (timezoneOffSet * -1) >= 0 ? "+" : ""  }${ (timezoneOffSet * -1)/60 < 10 ? "0" : ""  }${parseInt((timezoneOffSet * -1)/60, 10)  }:${ (timezoneOffSet * -1)%60 < 10 ? "0" : ""  }${(timezoneOffSet * -1) % 60}`;
    // datetime.setHours(datetime.getHours() - (timezoneOffSet / 60));

    const timezoneOffSet = -60;
    const timeZoneToAdd = `${timezoneOffSet * -1 >= 0 ? "+" : ""}${
        (timezoneOffSet * -1) / 60 < 10 ? "0" : ""
    }${parseInt((timezoneOffSet * -1) / 60, 10)}:${
        (timezoneOffSet * -1) % 60 < 10 ? "0" : ""
    }${(timezoneOffSet * -1) % 60}`;
    datetime.setHours(datetime.getHours() - timezoneOffSet / 60);

    const month =
        datetime.getMonth() + 1 < 10
            ? `0${datetime.getMonth() + 1}`
            : datetime.getMonth() + 1;
    const day =
        datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate();
    const hour =
        datetime.getHours() < 10
            ? `0${datetime.getHours()}`
            : datetime.getHours();
    const min =
        datetime.getMinutes() < 10
            ? `0${datetime.getMinutes()}`
            : datetime.getMinutes();
    return `${datetime.getFullYear()}-${month}-${day} ${hour}:${min}:00${timeZoneToAdd}`;
};

export const renderMonthDropDown = () => {
    return monthsList.map((data) => {
        return (
            // eslint-disable-next-line react/react-in-jsx-scope
            <option value={data.value} key={`month${data.value}`}>
                {data.name}
            </option>
        );
    });
};

export const printOffer = (fieldsList, offerDetails, isClientView, summerWinter, isDistinction) => {
    console.log("OfferDetails : --------------   ", offerDetails);
    const header = ["name"];
    let isSummerWinter = summerWinter;
    if(typeof(summerWinter) === "number" ){
        isSummerWinter = summerWinter === 1 ? "true" : "false";
    }

    const isDistinctionNew = isDistinction === "Distinction" ? "true" : "false";
    
    offerDetails.years.forEach((year) => {
        header.push(year.toString());
    });

    const dataToPrint = [];
    let obj = {};
    fieldsList.forEach((data) => {
        obj = {};
        obj.name = data.name;

        for(let i = 0; i < offerDetails.years.length; i += 1){
            obj[offerDetails.years[i].toString()] = offerDetails[data.fieldName][i];
        }
       // eslint-disable-next-line prefer-destructuring
        // obj[offerDetails.years[0].toString()] = offerDetails[data.fieldName][0];
        // eslint-disable-next-line prefer-destructuring
        // obj[offerDetails.years[1].toString()] = offerDetails[data.fieldName][1];
        // eslint-disable-next-line prefer-destructuring
        // obj[offerDetails.years[2].toString()] = offerDetails[data.fieldName][2];
        // // eslint-disable-next-line prefer-destructuring
        // obj[offerDetails.years[3].toString()] = offerDetails[data.fieldName][3];
        console.log("obj: ", obj);

        if (isClientView) {
            if (data.showClient && data.fieldName !== "years") {
                if((typeof(data.showSummerWinter) === 'undefined' ? true : (isSummerWinter === data.showSummerWinter.toString() )) &&
                    (typeof(data.showDistinction) === 'undefined' ? true : (isDistinctionNew === data.showDistinction.toString()))){
                         dataToPrint.push(obj);
                }
            }
        } else if((typeof(data.showSummerWinter) === 'undefined' ? true : (isSummerWinter === data.showSummerWinter.toString() )) &&
                (typeof(data.showDistinction) === 'undefined' ? true : (isDistinctionNew === data.showDistinction.toString()))){
                    dataToPrint.push(obj);
             }

        // if (isClientView || data.showClient) {
        //     if (data.fieldName !== "years") {
        //         if((typeof(data.showSummerWinter) === 'undefined' ? true : (isSummerWinter === data.showSummerWinter.toString() )) ||
        //             (typeof(data.showDistinction) === 'undefined' ? true : (isDistinction === data.showDistinction.toString()))){
        //             dataToPrint.push(obj);
        //         }
        //     }
        // }
    });

    console.log("header: ", header);
    console.log("offerDetails: ", offerDetails);
    print({
        printable: dataToPrint,
        properties: header,
        type: "json",
    });
};
