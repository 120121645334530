import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import EditIcon from "@material-ui/icons/Edit";
import { Redirect } from "react-router-dom";

import SubTitle from "../../hoc/SubTitle";
import { altisUrl, roleID, userID } from "../../constants";
import { GETAltis, DELETEAltis } from "../../apis/ServerApis";
import {
  handleResponseCode,
  handleException,
  handleFailure,
} from "../../utility";
import Settings from "../Settings/Settings";
import BackButton from "../../hoc/BackButton";
import AddClient from "../Client/AddClient";
import AddPartner from "../Partner/AddPartner";
import GrayButton from "../../hoc/GrayButton";
import BlueButton from "../../hoc/BlueButton";

class ContactsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerClientsData: [],
      showSettings: false,
      settingsObj: {},
      showEditContact: false,
      contactObj: {},
      showBaseDePrix: false,
      showMixDeFourniture: false,
      selectedIdForBaseAndMix: "",
      addContact: false,
    };
  }

  componentDidMount() {
    this.getContacts();
  }

  getContacts = () => {
    const userId = localStorage.getItem(userID);
    let url = `${altisUrl}clients?user_id=${userId}`; // For Partner fetch clients
    // If Login user = Admin then fetch  Partner List
    if (localStorage.getItem(roleID) === "1") {
      url = `${altisUrl}users?user_id=${userId}`;
    }

    // const url = `${altisUrl}clients?user_id=${userId}`;

    GETAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          this.setState({
            partnerClientsData: response.data,
          });
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  // Handle Edit Contact
  editContact = (e) => {
    const id = e.currentTarget.getAttribute("contactid");
    let url = `${altisUrl}clients/${id}`;
    if (localStorage.getItem(roleID) === "1") {
      url = `${altisUrl}users/${id}`;
    }

    GETAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);

          this.setState({
            contactObj: response.data,
            showEditContact: true,
          });
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  deleteContact = (e) => {
    const id = e.currentTarget.getAttribute("contactid");
    const url =
      localStorage.getItem(roleID) === "2"
        ? `${altisUrl}clients/${id}`
        : `${altisUrl}users/${id}`;

    DELETEAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          this.getContacts();
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  userSettings = (e) => {
    // eslint-disable-next-line no-alert
    // alert(`id: ${  e.currentTarget.getAttribute("contactid")}`);
    const userId = e.currentTarget.getAttribute("contactid");
    const url = `${altisUrl}applicationSettings?user_id=${userId}`; // For Partner fetch clients

    // const url = `${altisUrl}clients?user_id=${userId}`;

    GETAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          if (!response.data.user_id) {
            response.data.user_id = userId;
          }
          this.setState({
            settingsObj: response.data,
            showSettings: true,
          });
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  closeSuccessPopup = () => {
    this.setState({
      showSettings: false,
    });
  };

  // Close EditContact Popup
  closePopupEditContact = () => {
    this.setState({
      showEditContact: false,
      addContact: false,
    });
  };

  // Create Client or Partner Successfully
  userCreatedSuccessfully = () => {
    this.setState({
      showEditContact: false,
      addContact: false,
    });
    this.getContacts();
  };

  // Open Add Partner or Client
  openAddContact = () => {
    this.setState({
      addContact: true,
    });
  };

  // Open Base De Prix
  goToBaseDePrix = (id) => {
    this.setState({
      showBaseDePrix: true,
      selectedIdForBaseAndMix: id,
    });
  };

  // Open MixDeFourniture
  goToMixDeFourniture = (id) => {
    this.setState({
      showMixDeFourniture: true,
      selectedIdForBaseAndMix: id,
    });
  };

  render() {
    if (this.state.showBaseDePrix) {
      return (
        <Redirect
          to={{
            pathname: "/basepricesetting",
            state: { id: this.state.selectedIdForBaseAndMix },
          }}
        />
      );
    }
    if (this.state.showMixDeFourniture) {
      return (
        <Redirect
          to={{
            pathname: "/mixsupply",
            state: { id: this.state.selectedIdForBaseAndMix },
          }}
        />
      );
    }

    const renderAdminButtons = localStorage.getItem(roleID) === "1";

    const userRole = localStorage.getItem(roleID); // admin = 1 & partner = 2
    let subTitles = "Partenaires";
    let columnName = "Nom du partenaire";
    let addPartner = "Ajouter un partenaire";
    if (userRole === "2") {
      subTitles = "Mes Contacts";
      columnName = "Nom du client";
      addPartner = "Ajouter un client";
    }

    const contactList = this.state.partnerClientsData.map((contactObj) => {
      return (
        <div className="contactRowContainer">
          <span className="contactColumn contactId">{contactObj.id}</span>
          <span className="contactColumn contactName">{contactObj.name}</span>
          <span className="contactColumn contactRepresentativeName">
            {contactObj.representative_first_name
              ? contactObj.representative_first_name.concat(
                  " ",
                  contactObj.representative_last_name,
                )
              : contactObj.contact_name}
          </span>
          <span className="contactColumn contactEmail">{contactObj.email}</span>
          <span className="contactColumn contactPhone">{contactObj.phone}</span>
          <span className="contactColumn contactCanton">
            {contactObj.canton}
          </span>
          <span className="contactColumn contactDelete">
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={this.userSettings}
              className="deleteBtn"
              contactid={contactObj.id}
              style={{
                display: localStorage.getItem(roleID) === "1" ? "" : "none",
              }}
            >
              <SettingsIcon />
            </IconButton>

            <IconButton
              color="primary"
              aria-label="edit"
              onClick={this.editContact}
              className="deleteBtn"
              contactid={contactObj.id}
            >
              <EditIcon />
            </IconButton>

            <IconButton
              color="primary"
              aria-label="delete"
              onClick={this.deleteContact}
              className="deleteBtn"
              contactid={contactObj.id}
            >
              <DeleteIcon />
            </IconButton>
          </span>
          <span
            className="contactColumn contactPhone"
            style={{ display: renderAdminButtons ? "" : "none" }}
          >
            <div>
              <GrayButton
                btnName="Base de prix"
                btnClick={() => this.goToBaseDePrix(contactObj.id)}
                showFullWidth
              />

              <div style={{ marginTop: "10px" }}>
                <GrayButton
                  btnName="Mix de fournitures"
                  btnClick={() => this.goToMixDeFourniture(contactObj.id)}
                  showFullWidth
                />
              </div>
            </div>
          </span>
        </div>
      );
    });

    let showSettingsPopup = "";
    if (this.state.showSettings) {
      showSettingsPopup = (
        <Settings
          settingsObj={this.state.settingsObj}
          closeBtnClick={this.closeSuccessPopup}
        />
      );
    }

    // Open Edit Contact Screen
    if (this.state.showEditContact) {
      if (renderAdminButtons) {
        return (
          <AddPartner
            contactObj={this.state.contactObj}
            closeBtnClick={this.closePopupEditContact}
          />
        );
      }
      return (
        <AddClient
          contactObj={this.state.contactObj}
          closeBtnClick={this.closePopupEditContact}
        />
      );
    }

    // Open Add Partner & Client
    if (this.state.addContact) {
      if (renderAdminButtons) {
        return (
          <AddPartner
            closeBtnClick={this.closePopupEditContact}
            userCreatedSuccessfully={this.userCreatedSuccessfully}
          />
        );
      }
      return (
        <AddClient
          closeBtnClick={this.closePopupEditContact}
          userCreatedSuccessfully={this.userCreatedSuccessfully}
        />
      );
    }

    return (
      <div>
        <BackButton />
        {showSettingsPopup}

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="myAccountContainer"
        >
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ marginTop: "3em" }}
          >
            <div className="avtarLogo" />
            <SubTitle titleText="Mon compte" customeColor="#A7B6C6" />
            <div className="grayArrowIcon" />
            <SubTitle titleText={subTitles} />

            <BlueButton btnName={addPartner} btnClick={this.openAddContact} />
          </Grid>
          <hr
            style={{
              backgroundColor: "#FFFFFF",
              height: 1,
            }}
          />

          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="center"
            style={{ height: "100%" }}
          >
            <div className="contactRowContainer">
              <span className="contactColumnHeader contactId">#Id</span>
              <span className="contactColumnHeader contactName">
                {columnName}
              </span>
              <span className="contactColumnHeader contactRepresentativeName">
                Nom du contact
              </span>
              <span className="contactColumnHeader contactEmail">
                E-mail du contact
              </span>
              <span className="contactColumnHeader contactPhone">
                Numéro de Tél.
              </span>
              <span className="contactColumnHeader contactCanton">Canton</span>
              <span className="contactColumnHeader contactDelete">Éditer</span>
              <span
                className="contactColumnHeader contactPhone"
                style={{ display: renderAdminButtons ? "" : "none" }}
              >
                Configuration
              </span>
            </div>
            {contactList}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ContactsList;
