import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import PropTypes from 'prop-types';

const BackButton = () => {
  const handleButtonClick = () => {
    window.location = "#/myaccount";
  };

  return (
    <IconButton aria-label="back" class="backBtn" onClick={handleButtonClick}>
      <ArrowBackIcon />Retour
    </IconButton>
  );
};

export default BackButton;
// BackButton.propTypes = {
//   btnClick: PropTypes.func.isRequired,
// };
