import * as actionType from "./actionTypes";

// Action For set step one hide/show store in Redux
export const changeStepOneShow = (isShow) => {
    return {
        type: actionType.HIDE_SHOW_STEP_ONE,
        stepOneShow: isShow,
    };
};

// Action For set step two hide/show store in Redux
export const changeStepTwoShow = (isShow) => {
    return {
        type: actionType.HIDE_SHOW_STEP_TWO,
        stepTwoShow: isShow,
    };
};

// Action For set step three hide/show store in Redux
export const changeStepThreeShow = (isShow) => {
    return {
        type: actionType.HIDE_SHOW_STEP_THREE,
        stepThreeShow: isShow,
    };
};
