import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const grayButton = (props) => {
  const handleButtonClick = () => {
    props.btnClick();
  };

  return (
    <Button
      variant="contained"
      className={`grayBtn ${  props.className}`}
      onClick={handleButtonClick}
      fullWidth={props.showFullWidth}
    >
      {props.btnName}
    </Button>
  );
};

export default grayButton;
grayButton.propTypes = {
  btnName: PropTypes.string.isRequired,
  btnClick: PropTypes.func.isRequired,
  showFullWidth: PropTypes.bool,
  endBtnIcon: PropTypes.element,
  className: PropTypes.string,
};

grayButton.defaultProps = {
  showFullWidth: false,
  endBtnIcon: null,
};
