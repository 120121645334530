// Base URL
export
const altisUrl = "https://api.costing-app.inera.ch/api/";



// Constants

export
const Debug = false;

export
const userID = "userID";

export
const loginUserName = "loginUserName";

export
const refreshToken = "refreshToken";

export
const accessToken = "accessToken";

export
const tokenType = "tokenType";

export
const tokenID = "tokenID";

export
const roleID = "roleID";
// 1=admin & 2=partner

export
const selectedClientPartner = "selectedClientPartner";

export
const firstStep = "firstStep";



export
const costingPrices = "costingPrices";

export
const applicationSetting = "applicationSetting";

export
const guId = "guId";

export
const forecastIds = "forecastIds";

export
const offerData = "offerData";

export
const showDistinction = "showDistinction";

export
const showSummerWinter = "showSummerWinter";

export
const offerCurrency = "offerCurrency";

export
const noOfYears = "noOfYears";

export
const offerValidityMinutes = "offerValidityMinutes";

export
const offerPrices = "offerPrices";



export
const offerFieldsList = [

    {

        name: "Années",

        showClient: true,

        fieldName: "years",

    },

    {

        name: "Date calcul offre",

        showClient: true,

        fieldName: "offer_date",

    },

    {

        name: "Validité de l'offre",

        showClient: true,

        fieldName: "offer_validity",

    },

    {

        name: "Taux de change €/CHf",

        showClient: false,

        fieldName: "exchange_rate",

    },

    {

        name: "Conso annuelle [MWh]",

        showClient: true,

        fieldName: "annual_consumption",

    },

    {

        name: "Conso hiver - Haut tarif [MWh]",

        showClient: true,

        fieldName: "winter_peak_consumption",

    },

    {

        name: "Conso hiver - Bas tarif [MWh]",

        showClient: true,

        fieldName: "winter_offpeak_consumption",

    },

    {

        name: "Conso été - Haut tarif [MWh]",

        showClient: true,

        fieldName: "summer_peak_consumption",

    },

    {

        name: "Conso été - Bas tarif [MWh]",

        showClient: true,

        fieldName: "summer_offpeak_consumption",

    },

    {

        name: "Puissance ruban baseload [MW]",

        showClient: false,

        fieldName: "BSLD_power",

    },

    {

        name: "Prix ruban baseload [€/MWh]",

        showClient: false,

        fieldName: "BSLD_price_EUR",

    },

    {

        name: "Prix ruban baseload [CHF/MWh]",

        showClient: false,

        fieldName: "BSLD_price_CHF",

    },

    {

        name: "Prix moyen sans risques ni marge [CHF/MWh]",

        showClient: false,

        fieldName: "avg_price_wo_risk_nor_margin",

    },

    {

        name: "Surcoût énergie d'ajustement [CHF/MWh]",

        showClient: false,

        fieldName: "EA_extra_cost",

    },

    {

        name: "Risques conso / HPFC [CHF/MWh]",

        showClient: false,

        fieldName: "consumption_HPFC_risk",

    },

    {

        name: "Garanties d'Origine [CHF/MWh]",

        showClient: false,

        fieldName: "GoO price",

    },

    {

        name: "Marge commerciale [CHF/MWh]",

        showClient: false,

        fieldName: "sales_margin",

    },

    {

        name: "Surcoût de validite [CHF/MWh]",

        showClient: true,

        fieldName: "risk_fee_price",

    },

    {

        name: "Frais de gestion [CHF/MWh]",

        showClient: false,

        fieldName: "handling_fee",

    },

    {

        name: "Prix Bas tarif [CHF/MWh]",

        showClient: true,

        fieldName: "offpeak_price",

        showDistinction: true,

        showSummerWinter: false,

    },

    {

        name: "Prix - Haut tarif [CHF/MWh]",

        showClient: true,

        fieldName: "peak_price",

        showDistinction: true,

        showSummerWinter: false,

    },

    {

        name: "Prix hiver - Haut tarif [CHF/MWh]",

        showClient: true,

        fieldName: "winter_peak_price",

        showSummerWinter: true,

        // showDistinction: false,

    },

    {

        name: "Prix hiver - Bas tarif [CHF/MWh]",

        showClient: true,

        fieldName: "winter_offpeak_price",

        showSummerWinter: true,

        // showDistinction: false,

    },

    {

        name: "Prix été Haut tarif - [CHF/MWh]",

        showClient: true,

        fieldName: "summer_peak_price",

        showSummerWinter: true,

        // showDistinction: false,

    },

    {

        name: "Prix été - Bas tarif [CHF/MWh]",

        showClient: true,

        fieldName: "summer_offpeak_price",

        showSummerWinter: true,

        // showDistinction: false,

    },

    {

        name: "Prix moyen total [CHF/MWh]",

        showClient: true,

        fieldName: "final_price",

    },

];



export
const monthsList = [

    {
        name: "Jan",
        value: 1
    },

    {
        name: "Fév",
        value: 2
    },

    {
        name: "Mar",
        value: 3
    },

    {
        name: "Avr",
        value: 4
    },

    {
        name: "Mai",
        value: 5
    },

    {
        name: "Juin",
        value: 6
    },

    {
        name: "Juil",
        value: 7
    },

    {
        name: "Août",
        value: 8
    },

    {
        name: "Sept",
        value: 9
    },

    {
        name: "Oct",
        value: 10
    },

    {
        name: "Nov",
        value: 11
    },

    {
        name: "Déc",
        value: 12
    },

];



export
const cantonList = [

    "Zurich",

    "Berne",

    "Lucerne",

    "Uri",

    "Schwytz",

    "Obwald",

    "Nidwald",

    "Glaris",

    "Zoug",

    "Fribourg",

    "Soleure",

    "Bâle",

    "Schaffhouse",

    "Appenzell",

    "Saint-Gall",

    "Grisons",

    "Argovie",

    "Thugovie",

    "Tessin",

    "Vaud",

    "Valais",

    "Neuchâtel",

    "Genève",

    "Jura",

];