import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import SubTitle from "../../hoc/SubTitle";
import BorderButton from "../../hoc/BorderButton";
import { accessToken, roleID, altisUrl } from "../../constants";
import { POSTAltis } from "../../apis/ServerApis";
import { logOut } from "../../store/actions";
import {
  showAlert,
  handleResponseCode,
  handleFailure,
  handleException,
} from "../../utility";

const MyAccount = () => {
  const dispatch = useDispatch();
  const [isOpenMyInfo, setIsOpenMyInfo] = useState(false);
  const [isOpenMyOffer, setIsOpenMyOffer] = useState(false);
  const [isOpenMyContacts, setIsOpenMyContacts] = useState(false);
  const [isOpenFAQ, setIsOpenFAQ] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);

  const logout = () => {
    const url = `${altisUrl}logout`;
    POSTAltis(url, {})
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          localStorage.clear();
          dispatch(logOut());
          setTimeout(() => {
            setIsLogout(true);
          }, 1000);
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  if (isClose) {
    return <Redirect to="/home" />;
  }

  if (isOpenMyOffer) {
    return <Redirect to="myoffer" />;
  }

  if (isOpenMyInfo) {
    return <Redirect to="myinformation" />;
  }

  if (isOpenMyContacts) {
    return <Redirect to="mycontacts" />;
  }

  if (isOpenFAQ) {
    return <Redirect to="faq" />;
  }

  if (isLogout) {
    return <Redirect to="login" />;
  }

  if (isChangePassword) {
    return <Redirect to="changepassword" />;
  }

  const renderAdminButtons = localStorage.getItem(roleID) === "1";

  if (localStorage.getItem(accessToken) === null) {
    window.location.href = "#/login";
    localStorage.clear();
    showAlert(
      "Erreur",
      "Non authentifié. Merci de vous connecter pour continuer.",
    );
  }

  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="flex-start"
        justify="center"
        style={{ minHeight: "100vh" }}
        className="myAccountContainer"
      >
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          style={{ marginTop: "3em" }}
        >
          <div className="avtarLogo" />
          <SubTitle titleText="Mon compte" />
        </Grid>

        <IconButton
          color="primary"
          aria-label="close"
          onClick={() => setIsClose(true)}
          className="closeBtn"
        >
          <CloseIcon />
        </IconButton>
        <hr
          style={{
            backgroundColor: "#FFFFFF",
            height: 1,
          }}
        />
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="flex-start"
          style={{ marginTop: "2em" }}
        >
          <BorderButton
            btnName="Mes Informations"
            btnClick={() => setIsOpenMyInfo(true)}
          />

          <BorderButton
            btnName="Mes Offres"
            btnClick={() => setIsOpenMyOffer(true)}
          />

          <BorderButton
            btnName={renderAdminButtons ? "Partenaires" : "Mes Contacts"}
            btnClick={() => setIsOpenMyContacts(true)}
          />

          <BorderButton btnName="F.A.Q." btnClick={() => setIsOpenFAQ(true)} />

          <BorderButton
            btnName="Changer le mot de passe"
            btnClick={() => setIsChangePassword(true)}
          />

          <BorderButton btnName="Se déconnecter" btnClick={() => logout()} />
        </Grid>
      </Grid>
    </div>
  );
};

export default MyAccount;
