import { combineReducers } from "redux";
import user from "./user";
import stepHideShowReducer from "./stepHideShowReducer";
import * as actionType from "../actions/actionTypes";

const appReducer = combineReducers({
    user,
    stepHideShowReducer,
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
