import * as actionType from "../actions/actionTypes";
import updateObject from "../utility";

// Set Initial State
const intialState = {
    stepOneShow: false,
    stepTwoShow: false,
    stepThreeShow: false,
};

const stepHideShowReducer = (state = intialState, action) => {
    switch(action.type){
        case actionType.HIDE_SHOW_STEP_ONE:
            return updateObject(state, { stepOneShow: action.stepOneShow});
        case actionType.HIDE_SHOW_STEP_TWO:
            return updateObject(state, { stepTwoShow: action.stepTwoShow});
        case actionType.HIDE_SHOW_STEP_THREE:
            return updateObject(state, { stepThreeShow: action.stepThreeShow});
        default:
            return state;
    }
};

export default stepHideShowReducer;