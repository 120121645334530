import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";
// import axios from "axios";

import CustomInputField from "../../hoc/CustomInputField";
import AppLogo from "../../hoc/AppLogo";
import SubTitle from "../../hoc/SubTitle";
import BlueButton from "../../hoc/BlueButton";
import { POSTAltis, GETAltis } from "../../apis/ServerApis";
import { altisUrl } from "../../constants";
import {
    handleResponseCode,
    handleException,
    showAlert,
    handleFailure,
} from "../../utility";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goToLogin: false,
            password: "",
            confirmPassword: "",
            errorPassword: false,
            passwordErrorMessage: "",
            errorConfirmPassword: false,
            confirmPasswordErrorMessage: "",
        };
    }

    componentDidMount = () => {
        document.getElementById("leftsidebar").style.display = "none";
        // eslint-disable-next-line react/prop-types
        console.log("this.props.params: ", this.props.match.params.token);
    };

    // Input field handle - Password
    handlePasswordChange = (newValue) => {
        this.setState({
            password: newValue,
            errorPassword: false,
            passwordErrorMessage: "",
        });
    };

    // Input field handle - Confirm Password
    handleConfirmPasswordChange = (newValue) => {
        this.setState({
            confirmPassword: newValue,
            errorConfirmPassword: false,
            confirmPasswordErrorMessage: "",
        });
    };

    handleContinue = () => {
        const self = this;

        if (this.state.password === "") {
            this.setState({
                errorPassword: true,
                passwordErrorMessage: "Le mot de passe ne peut pas être vide.",
            });
            return;
        }

        if (this.state.confirmPassword === "") {
            this.setState({
                errorConfirmPassword: true,
                confirmPasswordErrorMessage:
                    "Confirmez le mot de passe ne peut pas être vide.",
            });
            return;
        }

        if (this.state.confirmPassword !== this.state.password) {
            this.setState({
                errorConfirmPassword: true,
                confirmPasswordErrorMessage:
                    "Le mot de passe et le mot de passe de confirmation ne correspondent pas.",
            });
            return;
        }

        GETAltis(`${altisUrl}password/find/${self.props.match.params.token}`)
            .then((response) => {
                console.log(response);
                if (handleResponseCode(response)) {
                    console.log(response.data);
                    if (
                        response &&
                        typeof response.success !== "undefined" &&
                        response.success
                    ) {
                        if (response.data && response.data.email)
                            self.callApiToResetPassword(response.data.email);
                    }
                } else {
                    handleFailure(response);
                }
            })
            .catch((error) => {
                handleException(error);
            });
    };

    callApiToResetPassword = (emailParam) => {
        const self = this;
        const params = {
            email: emailParam,
            password: self.state.password,
            password_confirmation: self.state.confirmPassword,
            // eslint-disable-next-line react/prop-types
            token: self.props.match.params.token,
        };
        POSTAltis(`${altisUrl}password/reset`, params)
            .then((response) => {
                console.log(response);
                if (handleResponseCode(response)) {
                    console.log(response.data);
                    if (
                        response &&
                        typeof response.success !== "undefined" &&
                        response.success
                    ) {
                        self.setState({
                            goToLogin: true,
                        });
                        if (response.message) {
                            showAlert("Succès", response.message);
                        }
                    }
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (!response.success && response.errors) {
                        showAlert("Erreur", response.errors.join(" "));
                    } else if(response.message){
                        showAlert("Erreur", response.message);
                    } else {
                        showAlert("Erreur", "Erreur interne du serveur");
                    }
                }
            })
            .catch((error) => {
                handleException(error);
            });
    };

    render() {
        if (this.state.goToLogin) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: "100vh" }}
                    className="formContainer"
                >
                    <AppLogo />
                    <SubTitle titleText="COSTING PLATFORM" />
                    <SubTitle titleText="réinitialiser le mot de passe" />

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        xs={6}
                        style={{ marginTop: "3em" }}
                    >
                        <Grid item xs={6} sm={5}>
                            <div className="formLabel">Mot de passe*</div>
                        </Grid>
                        <Grid item xs={6} sm={7}>
                            <CustomInputField
                                fieldName="Password"
                                fieldType="password"
                                fieldValue={this.state.password}
                                isError={this.state.errorPassword}
                                errorMessage={this.state.passwordErrorMessage}
                                textChange={this.handlePasswordChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        xs={6}
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={5}>
                            <div className="formLabel">
                                Confirmez le mot de passe*
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={7}>
                            <CustomInputField
                                fieldName="ConfirmPassword"
                                fieldType="password"
                                fieldValue={this.state.confirmPassword}
                                isError={this.state.errorConfirmPassword}
                                errorMessage={
                                    this.state.confirmPasswordErrorMessage
                                }
                                textChange={this.handleConfirmPasswordChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        xs={6}
                        style={{ marginTop: "1em" }}
                    >
                        <BlueButton
                            btnName="réinitialiser le mot de passe"
                            btnClick={this.handleContinue}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default ResetPassword;
