import { showSpinner } from "../utility";
import { accessToken, tokenType, altisUrl } from "../constants";

// Api call for POST Request
const POSTAltis = (_apiname, _apiParam) =>
  new Promise((resolve, reject) => {
    showSpinner(true);
    const authorizationToken = `${localStorage.getItem(
      // eslint-disable-next-line comma-dangle
      tokenType,
    )} ${localStorage.getItem(accessToken)}`;
    const customHeader = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-app-version-number": 1.0,
      Authorization: authorizationToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    fetch(_apiname, {
      method: "POST",
      headers: customHeader,
      body: JSON.stringify(_apiParam),
      // eslint-disable-next-line consistent-return
    }).then((response) => {
      showSpinner(false);
      if (response.status >= 200 && response.status < 300) {
        if (response.headers.get("Content-Type") != null) {
          return resolve(response.json());
        }
      } else if (response.status === 500) {
        return reject(response.json());
      } else {
        if (
          response.headers.get("Content-Type") != null &&
          response.headers.get("Content-Type") ===
            "application/json;charset=UTF-8"
        ) {
          return reject(response.statusText);
        }
        if (response.headers.get("Content-Type") != null) {
          // console.log(
          //     "response StatusText :",
          //     response.statusText.indexOf("Ã©")
          // );
          return resolve(response.json());
        }

        return reject(response.blob());
      }
    });
  });

// Api call for GET Request
const GETAltis = (_apiname) =>
  new Promise((resolve, reject) => {
    showSpinner(true);
    const authorizationToken = `${localStorage.getItem(
      // eslint-disable-next-line comma-dangle
      tokenType,
    )} ${localStorage.getItem(accessToken)}`;

    const customHeader = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-app-version-number": 1.0,
      Authorization: authorizationToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    fetch(_apiname, {
      method: "GET",
      headers: customHeader,
      // eslint-disable-next-line consistent-return
    }).then((response) => {
      showSpinner(false);
      if (response.status >= 200 && response.status < 300) {
        if (response.headers.get("Content-Type") != null) {
          return resolve(response.json());
        }
      } else if (response.status === 500) {
        return reject(response.json());
      } else {
        if (
          response.headers.get("Content-Type") != null &&
          response.headers.get("Content-Type") ===
            "application/json;charset=UTF-8"
        ) {
          return reject(response.json());
        }
        if (response.headers.get("Content-Type") != null) {
          // return reject(response.statusText);
          return resolve(response.json());
        }
        return reject(response.blob());
      }
    });
  });

// Api call for PUT Request
const PUTAltis = (_apiname, _apiParam) =>
  new Promise((resolve, reject) => {
    showSpinner(true);
    const authorizationToken = `${localStorage.getItem(
      // eslint-disable-next-line comma-dangle
      tokenType,
    )} ${localStorage.getItem(accessToken)}`;
    const customHeader = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-app-version-number": 1.0,
      Authorization: authorizationToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    fetch(_apiname, {
      method: "PUT",
      headers: customHeader,
      body: JSON.stringify(_apiParam),
      // eslint-disable-next-line consistent-return
    }).then((response) => {
      showSpinner(false);
      if (response.status >= 200 && response.status < 300) {
        if (response.headers.get("Content-Type") != null) {
          return resolve(response.json());
        }
      } else if (response.status === 500) {
        return reject(response.json());
      } else {
        if (
          response.headers.get("Content-Type") != null &&
          response.headers.get("Content-Type") ===
            "application/json;charset=UTF-8"
        ) {
          return reject(response.json());
        }
        if (response.headers.get("Content-Type") != null) {
          // return reject(response.statusText);
          return resolve(response.json());
        }
        return reject(response.blob());
      }
    });
  });

// Api call for DELETE Request
const DELETEAltis = (_apiname) =>
  new Promise((resolve, reject) => {
    showSpinner(true);
    const authorizationToken = `${localStorage.getItem(
      // eslint-disable-next-line comma-dangle
      tokenType,
    )} ${localStorage.getItem(accessToken)}`;
    const customHeader = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-app-version-number": 1.0,
      Authorization: authorizationToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    fetch(_apiname, {
      method: "DELETE",
      headers: customHeader,
      // eslint-disable-next-line consistent-return
    }).then((response) => {
      showSpinner(false);
      if (response.status >= 200 && response.status < 300) {
        if (response.headers.get("Content-Type") != null) {
          return resolve(response.json());
        }
      } else if (response.status === 500) {
        return reject(response.json());
      } else {
        if (
          response.headers.get("Content-Type") != null &&
          response.headers.get("Content-Type") ===
            "application/json;charset=UTF-8"
        ) {
          return reject(response.json());
        }
        if (response.headers.get("Content-Type") != null) {
          // return reject(response.statusText);
          return resolve(response.json());
        }
        return reject(response.blob());
      }
    });
  });

  // Api call for File Download 
const FILEDOWNLOADAltis = (_apiname, _fileName) =>
new Promise((resolve, reject) => {
  // showSpinner(true);
  const authorizationToken = `${localStorage.getItem(
    // eslint-disable-next-line comma-dangle
    tokenType,
  )} ${localStorage.getItem(accessToken)}`;
  const customHeader = {
    Authorization: authorizationToken,
  };

  fetch(_apiname, {
    method: "GET",
    headers: customHeader,
    // eslint-disable-next-line consistent-return
  })
  .then(response => response.blob())
  .then(blob => {
    // Create a URL for the response Blob
    const url = window.URL.createObjectURL(blob);
    // Create a link element, set its attributes, and simulate click to trigger download
    const a = document.createElement('a');
    a.href = url;
    // a.download = 'preds.xlsx';
    // a.download = 'offer.json';
    // a.download = 'offer.xml';
    a.download = _fileName;
    document.body.appendChild(a);
    a.click();
    // Clean up: remove the created link and revoke the URL
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    return resolve('downloaded');
  })
  // return reject(response.json());
  .catch(error => {return reject(error)});
});


export { POSTAltis, GETAltis, PUTAltis, DELETEAltis, FILEDOWNLOADAltis };
