import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

class SuccessPopup extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    closeClicked = () => {
        // eslint-disable-next-line react/prop-types
        this.props.closeBtnClick();
    };

    nextClicked = () => {
        // eslint-disable-next-line react/prop-types
        this.props.nextBtnClick();
    };

    partnerListClicked = () => {
        // eslint-disable-next-line react/prop-types
        this.props.partnerListBtnClicked();
    };

    render() {
        // eslint-disable-next-line react/prop-types
        const titleTextValue = this.props.titleText;

        // eslint-disable-next-line react/prop-types
        const messageValue = this.props.message;

        // eslint-disable-next-line react/prop-types
        const partnerListLink = this.props.partnerListLinkMessage;
        let partnerData = "";
        if(partnerListLink !== ""){
            partnerData = 
            <div>
                <span>{partnerListLink}</span>
                <IconButton color="primary" aria-label="next" onClick={this.partnerListClicked}>
                    <span className="arrowIcon" />
                </IconButton>
            </div>
        }

        return (
            <div>
                <IconButton color="primary" aria-label="close" onClick={this.closeClicked} className="closeBtn">
                  <CloseIcon />
                </IconButton>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: "100vh" }}
                    className="successPopupContainer"
                >
                    <div className="popupTitle" style={{textAlign: 'center'}}>{titleTextValue}</div>
                   {partnerData}
                    <div>
                        <span>{messageValue}</span>
                        <IconButton color="primary" aria-label="next" onClick={this.nextClicked}>
                            <span className="arrowIcon" />
                        </IconButton>
                    </div>
                </Grid>
            </div>
        );
    }
}

export default SuccessPopup;
