import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";
// import axios from "axios";

import CustomInputField from "../../hoc/CustomInputField";
import SubTitle from "../../hoc/SubTitle";
import BlueButton from "../../hoc/BlueButton";
import { POSTAltis, GETAltis } from "../../apis/ServerApis";
import { altisUrl, userID } from "../../constants";
import {
  handleResponseCode,
  handleException,
  showAlert,
  handleFailure,
} from "../../utility";
import BackButton from "../../hoc/BackButton";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goToMyAccount: false,
      currentPassword: "",
      currentErrorPassword: false,
      currentPasswordErrorMessage: "",
      password: "",
      confirmPassword: "",
      errorPassword: false,
      passwordErrorMessage: "",
      errorConfirmPassword: false,
      confirmPasswordErrorMessage: "",
    };
  }

  componentDidMount = () => {
    // document.getElementById("leftsidebar").style.display = "none";
    // eslint-disable-next-line react/prop-types
    console.log("this.props.params: ", this.props.match.params.token);
  };

  handleCurrentPasswordChange = (newValue) => {
    this.setState({
      currentPassword: newValue,
      currentErrorPassword: false,
      currentPasswordErrorMessage: "",
    });
  };

  // Input field handle - Password
  handlePasswordChange = (newValue) => {
    this.setState({
      password: newValue,
      errorPassword: false,
      passwordErrorMessage: "",
    });
  };

  // Input field handle - Confirm Password
  handleConfirmPasswordChange = (newValue) => {
    this.setState({
      confirmPassword: newValue,
      errorConfirmPassword: false,
      confirmPasswordErrorMessage: "",
    });
  };

  handleContinue = () => {
    const self = this;

    if (this.state.currentPassword === "") {
      this.setState({
        currentErrorPassword: true,
        currentPasswordErrorMessage:
          "Le mot de passe actuel ne peut pas être vide.",
      });
      return;
    }

    if (this.state.password === "") {
      this.setState({
        errorPassword: true,
        passwordErrorMessage: "Le mot de passe ne peut pas être vide.",
      });
      return;
    }

    if (this.state.confirmPassword === "") {
      this.setState({
        errorConfirmPassword: true,
        confirmPasswordErrorMessage:
          "Confirmez le mot de passe ne peut pas être vide.",
      });
      return;
    }

    if (this.state.confirmPassword !== this.state.password) {
      this.setState({
        errorConfirmPassword: true,
        confirmPasswordErrorMessage:
          "Le mot de passe et le mot de passe de confirmation ne correspondent pas.",
      });
      return;
    }

    self.callApiToChangePassword();
  };

  callApiToChangePassword = () => {
    const self = this;
    const params = {
      current_password: self.state.currentPassword,
      password: self.state.password,
      password_confirmation: self.state.confirmPassword,
    };
    POSTAltis(
      `${altisUrl}password/change/${localStorage.getItem(userID)}`,
      params,
    )
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          if (
            response &&
            typeof response.success !== "undefined" &&
            response.success
          ) {
            self.setState({
              goToMyAccount: true,
            });
            if (response.message) {
              showAlert("Succès", response.message);
            }
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!response.success && response.errors && response.errors.length) {
            showAlert("Erreur", response.errors.join(" "));
          } else if (response.message) {
            showAlert("Erreur", response.message);
          } else {
            showAlert("Erreur", "Erreur interne du serveur");
          }
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  render() {
    if (this.state.goToMyAccount) {
      return <Redirect to="/myaccount" />;
    }

    return (
      <div>
        <BackButton />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="myAccountContainer"
        >
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ marginTop: "3em" }}
          >
            <div className="avtarLogo" />
            <SubTitle titleText="Mon compte" customeColor="#A7B6C6" />
            <div className="grayArrowIcon" />
            <SubTitle titleText="Changer le mot de passe" />
          </Grid>
          <hr
            style={{
              backgroundColor: "#FFFFFF",
              height: 1,
            }}
          />

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justify="center"
            className="changePassword"
            xs={6}
            style={{ marginTop: "3em" }}
          >
            <Grid item xs={6} sm={5}>
              <div className="formLabel">Mot de passe actuel*</div>
            </Grid>
            <Grid item xs={6} sm={7}>
              <CustomInputField
                fieldName="CurrentPassword"
                fieldType="password"
                fieldValue={this.state.currentPassword}
                isError={this.state.currentErrorPassword}
                errorMessage={this.state.currentPasswordErrorMessage}
                textChange={this.handleCurrentPasswordChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justify="center"
            className="changePassword"
            xs={6}
            style={{ marginTop: "1em" }}
          >
            <Grid item xs={6} sm={5}>
              <div className="formLabel">Nouveau mot de passe*</div>
            </Grid>
            <Grid item xs={6} sm={7}>
              <CustomInputField
                fieldName="Password"
                fieldType="password"
                fieldValue={this.state.password}
                isError={this.state.errorPassword}
                errorMessage={this.state.passwordErrorMessage}
                textChange={this.handlePasswordChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justify="center"
            className="changePassword"
            xs={6}
            style={{ marginTop: "1em" }}
          >
            <Grid item xs={6} sm={5}>
              <div className="formLabel">
                Confirmer le nouveau mot de passe*
              </div>
            </Grid>
            <Grid item xs={6} sm={7}>
              <CustomInputField
                fieldName="ConfirmPassword"
                fieldType="password"
                fieldValue={this.state.confirmPassword}
                isError={this.state.errorConfirmPassword}
                errorMessage={this.state.confirmPasswordErrorMessage}
                textChange={this.handleConfirmPasswordChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justify="center"
            xs={6}
            style={{ marginTop: "1em" }}
          >
            <BlueButton
              btnName="Changer le mot de passe"
              btnClick={this.handleContinue}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ChangePassword;
