import * as actionType from "./actionTypes";
import { altisUrl, userID } from "../../constants";

import { POSTAltis, PUTAltis } from "../../apis/ServerApis";
import {
    handleResponseCode,
    showAlert,
    handleException,
    storeTokenData,
    handleFailure,
} from "../../utility";

// Action For SetUserDetail store in Redux
export const setUserDetail = (userDetails) => {
    return {
        type: actionType.GET_USER_DETAIL_SUCCESS,
        userDetail: userDetails,
    };
};

// Action For EditUserDetail store in Redux
export const editUserDetailSuccess = (userDetails) => {
    return {
        type: actionType.EDIT_USER_DETAIL_SUCCESS,
        userDetail: userDetails,
    };
};

// Action for when GetUserDetail Failed
export const getUserDetailFailed = () => {
    return {
        type: actionType.GET_USED_DETAIL_FAIL,
    };
};

// Action for api call to getUserDetail when Login
export const getUserDetail = (userCredential) => {
    console.log("Called");
    return (dispatch) => {
        const url = `${altisUrl}login`;
        POSTAltis(url, userCredential)
            .then((response) => {
                if (handleResponseCode(response)) {
                    storeTokenData(response.data);
                    dispatch(setUserDetail(response.data.user));
                    document.getElementById("leftsidebar").style.display = "";
                } else {
                    handleFailure(response);
                }
            })
            .catch((error) => {
                handleException(error);
            });
    };
};

// Action for api call to EditUserDetail when updated
export const editUserDetail = (userData) => {
    console.log("Called");
    return (dispatch) => {
        const userId = localStorage.getItem(userID);
        const url = `${altisUrl}users/${userId}`;
        PUTAltis(url, userData)
            .then((response) => {
                if (handleResponseCode(response)) {
                    dispatch(editUserDetailSuccess(response.data));
                } else {
                    handleFailure(response);
                }
            })
            .catch((error) => {
                handleException(error);
            });
    };
};

// Action for Logout
export const logOut = () => {
    return {
        type: actionType.LOGOUT,
    };
};

export const hideSuccessPopUpEditUser = () => {
    return {
        type: actionType.HIDE_SUCCESSPOPUP_EDIT_USER,
    };
};
