import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from "prop-types";

import CustomInputField from "../../hoc/CustomInputField";
import BlueButton from "../../hoc/BlueButton";
import {
    checkEmailisValid,
    handleResponseCode,
    handleException,
    getBase64,
    showAlert,
    handleFailure,
} from "../../utility";
import { altisUrl, cantonList } from "../../constants";
import { POSTAltis, PUTAltis } from "../../apis/ServerApis";

class AddPartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: "",
            errorCompanyName: false,
            companyNameErrorMessage: "",
            address: "",
            errorAddress: false,
            addressErrorMessage: "",
            street: "",
            city: "",
            cp: "",
            canton: "",
            partnerFirstName: "",
            errorPartnerFirstName: false,
            partnerFirstNameErrorMessage: "",
            partnerLastName: "",
            errorPartnerLastName: false,
            partnerLastNameErrorMessage: "",
            partnerEmail: "",
            errorPartnerEmail: false,
            partnerEmailErrorMessage: "",
            partnerNumber: "",
            errorPartnerNumber: false,
            partnerNumberErrorMessage: "",
            base64URL: "",
        };
    }

    componentDidMount() {
        if (this.props.contactObj) {
            const partnerData = this.props.contactObj;
            console.log("PartnerData : ", partnerData);
            this.setState({
                companyName: partnerData.name,

                address: partnerData.address,

                street: partnerData.street,
                city: partnerData.city,
                cp: partnerData.cp,
                canton: partnerData.canton,
                partnerFirstName: partnerData.representative_first_name,

                partnerLastName: partnerData.representative_last_name,

                partnerEmail: partnerData.email,

                partnerNumber: partnerData.phone,

                base64URL: partnerData.logo,
            });
        }
    }

    // On file select (from the pop up)
    onImageChange = (event) => {
        // Update the state
        // this.setState({ selectedFile: event.target.files[0] });

        getBase64(event.target.files[0])
            .then((result) => {
                console.log("File Is", result);
                this.setState({
                    base64URL: result,
                });
                console.log("File Is", this.state.base64URL);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // Input field handle - CompanyName
    handleCompanyNameChange = (newValue) => {
        this.setState({
            companyName: newValue,
            errorCompanyName: false,
            companyNameErrorMessage: "",
        });
    };

    // Input field handle - Address
    handleAddressChange = (newValue) => {
        this.setState({
            address: newValue,
            errorAddress: false,
            addressErrorMessage: "",
        });
    };

    // Input field handle - Street name
    handleStreetNameChange = (newValue) => {
        this.setState({
            street: newValue,
        });
    };

    // Input field handle - City
    handleCityChange = (newValue) => {
        this.setState({
            city: newValue,
        });
    };

    // Input field handle - CP
    handleCPChange = (newValue) => {
        this.setState({
            cp: newValue,
        });
    };

    // Input field handle - Canton
    handleCantonChange = (newValue) => {
        console.log("Hi", newValue);
        this.setState({
            canton: newValue,
        });
    };

    // Input field handle - PartnerFirstName
    handlePartnerFirstNameChange = (newValue) => {
        this.setState({
            partnerFirstName: newValue,
            errorPartnerFirstName: false,
            partnerFirstNameErrorMessage: "",
        });
    };

    // Input field handle - PartnerLastName
    handlePartnerLastNameChange = (newValue) => {
        this.setState({
            partnerLastName: newValue,
            errorPartnerLastName: false,
            partnerLastNameErrorMessage: "",
        });
    };

    // Input field handle - PartnerEmail
    handlePartnerEmailChange = (newValue) => {
        this.setState({
            partnerEmail: newValue,
            errorPartnerEmail: false,
            partnerEmailErrorMessage: "",
        });
    };

    // Input field handle - PartnerNumber
    handlePartnerNumberChange = (newValue) => {
        this.setState({
            partnerNumber: newValue,
            errorPartnerNumber: false,
            partnerNumberErrorMessage: "",
        });
    };

    userCreatedSuccessfully = (userData) => {
        // eslint-disable-next-line react/prop-types
        this.props.userCreatedSuccessfully(userData);
    };

    // call an api for Add Client & Check validation
    handleContinue = () => {
        if (this.state.companyName === "") {
            this.setState({
                errorCompanyName: true,
                companyNameErrorMessage:
                    "le nom de l'entreprise ne peut pas être vide.",
            });
            return;
        }
        if (
            this.state.address === "" ||
            this.state.street === "" ||
            this.state.city === "" ||
            this.state.cp === "" ||
            this.state.canton === ""
        ) {
            this.setState({
                errorAddress: true,
                addressErrorMessage:
                    "L'adresse, la rue, la ville, le cp et le canton ne peuvent pas être vides.",
            });
            return;
        }
        if (this.state.partnerFirstName === "") {
            this.setState({
                errorPartnerFirstName: true,
                partnerFirstNameErrorMessage:
                    "Le prénom du partenaire ne peut pas être vide.",
            });
            return;
        }

        if (this.state.partnerLastName === "") {
            this.setState({
                errorPartnerLastName: true,
                partnerLastNameErrorMessage:
                    "Le nom de famille du partenaire ne peut pas être vide.",
            });
            return;
        }
        if (this.state.partnerNumber === "") {
            this.setState({
                errorPartnerNumber: true,
                partnerNumberErrorMessage:
                    "Le numéro de partenaire ne peut pas être vide.",
            });
            return;
        }

        if (this.state.partnerEmail === "") {
            this.setState({
                errorPartnerEmail: true,
                partnerEmail: "L'e-mail ne peut pas être vide.",
            });
            return;
        }

        if (!checkEmailisValid(this.state.partnerEmail)) {
            this.setState({
                errorPartnerEmail: true,
                partnerEmailErrorMessage: "L'email n'est pas valide",
            });
            return;
        }

        const partnerParams = {
            name: this.state.companyName,
            email: this.state.partnerEmail,
            phone: this.state.partnerNumber,
            address: this.state.address,
            street: this.state.street,
            city: this.state.city,
            cp: this.state.cp,
            canton: this.state.canton,
            representative_first_name: this.state.partnerFirstName,
            representative_last_name: this.state.partnerLastName,
            logo: this.state.base64URL,
        };

        if (this.props.contactObj) {
            // For Edit Partner
            const url = `${altisUrl}users/${this.props.contactObj.id}`;
            this.EditPartnerApiCall(url, partnerParams);
        } else {
            // To Add New Partner
            const url = `${altisUrl}users`;
            this.AddPartnerApiCall(url, partnerParams);
        }
    };

    // Api call for add new partner
    AddPartnerApiCall = (url, partnerParams) => {
        POSTAltis(url, partnerParams)
            .then((response) => {
                console.log(response);
                if (handleResponseCode(response)) {
                    console.log(response.data);
                    if (response && response.data && response.data.id) {
                        if(window.location.hash === "#/mycontacts")
                        {
                            showAlert("Succès", "Partenaire ajouté avec succès");
                             // eslint-disable-next-line react/prop-types
                            this.props.userCreatedSuccessfully();
                        } else {
                             // eslint-disable-next-line no-restricted-globals
                        this.userCreatedSuccessfully(response.data);
                        }
                        
                    }
                } else {
                    handleFailure(response);
                }
            })
            .catch((error) => {
                handleException(error);
            });
    };

    // Api call for edit partner
    EditPartnerApiCall = (url, partnerParams) => {
        PUTAltis(url, partnerParams)
            .then((response) => {
                console.log(response);
                if (handleResponseCode(response)) {
                    console.log(response.data);
                    showAlert("Succès", "Données enregistrées avec succès");
                    // eslint-disable-next-line react/prop-types
                    this.props.closeBtnClick();
                } else {
                    handleFailure(response);
                }
            })
            .catch((error) => {
                handleException(error);
            });
    };

    closeClicked = () => {
        // eslint-disable-next-line react/prop-types
        this.props.closeBtnClick();
    };

    render() {
       let title = "Ajouter un nouveau partenaire"
       if(this.props.contactObj){
           title = "Modifier un partenaire";
           if(this.props.titleTexts) {
                title = this.props.titleTexts;
           }
       }
        return (
            <div>
                <IconButton
                    color="primary"
                    aria-label="close"
                    onClick={this.closeClicked}
                    className="closeBtn"
                >
                    <CloseIcon />
                </IconButton>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className="formPopupContainer"
                >
                    <div className="popupTitle">
                        {title}
                    </div>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">
                                Nom du partenaire*
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName="Nom de la compagnie"
                                fieldType="companyName"
                                fieldValue={this.state.companyName}
                                isError={this.state.errorCompanyName}
                                errorMessage={
                                    this.state.companyNameErrorMessage
                                }
                                textChange={this.handleCompanyNameChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">Adresse*</div>
                        </Grid>
                        <Grid item xs={3} sm={4}>
                            <CustomInputField
                                fieldName="Rue"
                                fieldType="Street"
                                fieldValue={this.state.street}
                                textChange={this.handleStreetNameChange}
                            />
                        </Grid>
                        <Grid item xs={3} sm={4}>
                            <CustomInputField
                                fieldName="Numéro"
                                fieldType="Address"
                                fieldValue={this.state.address}
                                isError={this.state.errorAddress}
                                errorMessage={this.state.addressErrorMessage}
                                textChange={this.handleAddressChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="right"
                        justify="center"
                        style={{ marginTop: "0.2em" }}
                    >
                        <Grid item xs={6} sm={4} />
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName="Ville"
                                fieldType="City"
                                fieldValue={this.state.city}
                                textChange={this.handleCityChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="right"
                        justify="center"
                        style={{ marginTop: "0.2em" }}
                    >
                        <Grid item xs={6} sm={4} />
                        <Grid item xs={3} sm={4}>
                            <CustomInputField
                                fieldName="CP"
                                fieldType="cp"
                                fieldValue={this.state.cp}
                                textChange={this.handleCPChange}
                            />
                        </Grid>
                        <Grid item xs={3} sm={4}>
                        
                        <Autocomplete
                            options={cantonList}
                            id="controlled-demo"
                            value={this.state.canton}
                            onChange={(event, newValue) => {
                                this.handleCantonChange(newValue);
                            }}
                            renderInput={(params) => (
                                 // eslint-disable-next-line react/jsx-props-no-spreading
                                 <TextField {...params} label="Canton" className="inputFields" size="small" />
                            )}
                        />
                            
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">
                                Prénom du représentant du partenaire*
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName=""
                                fieldType="Partner First Name"
                                fieldValue={this.state.partnerFirstName}
                                isError={this.state.errorPartnerFirstName}
                                errorMessage={
                                    this.state.partnerFirstNameErrorMessage
                                }
                                textChange={this.handlePartnerFirstNameChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">
                                Nom du représentant du partenaire*
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName=""
                                fieldType="Partner Last Name"
                                fieldValue={this.state.partnerLastName}
                                isError={this.state.errorPartnerLastName}
                                errorMessage={
                                    this.state.partnerLastNameErrorMessage
                                }
                                textChange={this.handlePartnerLastNameChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">
                                Numéro de téléphone du partenaire*
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName=""
                                fieldType=""
                                fieldValue={this.state.partnerNumber}
                                isError={this.state.errorPartnerNumber}
                                errorMessage={
                                    this.state.partnerNumberErrorMessage
                                }
                                textChange={this.handlePartnerNumberChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">
                                Email du partenaire*
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName=""
                                fieldType=""
                                fieldValue={this.state.partnerEmail}
                                isError={this.state.errorPartnerEmail}
                                errorMessage={
                                    this.state.partnerEmailErrorMessage
                                }
                                textChange={this.handlePartnerEmailChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">Logo*</div>
                        </Grid>
                        <Grid item xs={6} sm={8} style={{ textAlign: "left" }}>
                            <input
                                type="file"
                                onChange={this.onImageChange}
                                accept="image/png, image/jpeg"
                            />
                        </Grid>
                    </Grid>
                    <div className="formLabelPopup" style={{ marginTop: "1em" }}>Tous les champs marqués d’une * sont obligatoires</div>
                    <BlueButton
                        btnName="Valider"
                        btnClick={this.handleContinue}
                    />
                </Grid>
            </div>
        );
    }
}

AddPartner.propTypes = {
    contactObj: PropTypes.objectOf(PropTypes.any),
    titleTexts: PropTypes.string,
};

AddPartner.defaultProps = {
    contactObj: null,
    titleTexts: null,
};

export default AddPartner;
