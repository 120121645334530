import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import SubTitle from "../../hoc/SubTitle";
import BackButton from "../../hoc/BackButton";

const faqData = [
  {
    position: 1,
    question: "WHO IS RESPONSIBLE FOR YOUR DATA?",
    answer:
      "Altis is responsible for processing your personal data. Altis has its registered office in The Netherlands, at Schenkkade 65, 2595 AS The Hague. Tel. no +31 70 513 03 03 , Email: info@altis.ch, website: www.AltisIM.com. Altis is a subsidiary of NN Investment Partners Holdings N.V.",
  },
  {
    position: 2,
    question: "WHAT IS PERSONAL DATA?",
    answer:
      "Personal data concerns all direct and indirect information about a person. Examples of such information are your name and email address. Information that you provide to us when you enter into an agreement or use our website.",
  },
  {
    position: 3,
    question: "FOR WHAT PURPOSES DO WE PROCESS PERSONAL DATA?",
    answer:
      "Altis processes your personal data for the following purposes: For the performance of our contractual obligations This includes taking steps to enter into a contract and provide you access to our online services. For this purpose we use personal data such as your name, address, place of residence, phone number(s), e-mail addresses, login credentials, and technical data needed for the functioning of the portal.",
  },
  {
    position: 4,
    question: "WITH WHOM MAY WE SHARE YOUR PERSONAL DATA?",
    answer:
      "We may provide your personal data to the persons, companies and institutions named below. We do that only if:",
  },
  {
    position: 5,
    question: "HOW WE TAKE CARE OF YOUR PERSONAL DATA?",
    answer:
      "We spend a lot of time and effort on the security of our systems and the personal data stored within them. Our mother company NN IP also has a special team that monitors security and the use of personal data. We keep a constant eye on the security of our data traffic. We take immediate action should something ever go wrong. We resolve and register data breaches. That is also our legal duty. We also report them to the supervisory authority and to you, if necessary.",
  },
  {
    position: 6,
    question: "HOW LONG DO WE KEEP YOUR PERSONAL DATA?",
    answer:
      "We keep your data as long as we are required by law to do so and as long as necessary for the purpose for which we use the data. This may vary by product. This is based on the following rules:",
  },
  {
    position: 7,
    question: "WHERE DO WE PROCESS YOUR DATA? ",
    answer:
      "Your personal data is generally processed within the European Union (EU). In some cases personal data is processed outside the EU. Some of our suppliers and business partners are also located outside the EU or provide these services outside the EU. The regulations in those countries do not always provide the same level of personal data protection as European regulations. To ensure that your personal data is safe nevertheless, we take measures in such cases by entering into contracts where we make similar arrangements about the security of personal data, just like we do within the EU. We call these EU model contracts.",
  },
];

// eslint-disable-next-line react/prefer-stateless-function
class FAQ extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      expandedPosition: 1,
    };
  }

  accordianChange = (selectedPosition) => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      expandedPosition:
        // eslint-disable-next-line react/no-access-state-in-setstate
        this.state.expandedPosition === selectedPosition
          ? -1
          : selectedPosition,
    });
  };

  render() {
    const faqHtml = faqData.map((data) => {
      return (
        <div className="stepsContainer">
          <Accordion
            className="stepsAccordion"
            style={{ marginTop: "2em" }}
            onChange={() => this.accordianChange(data.position)}
            expanded={this.state.expandedPosition === data.position}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className="stepNumber">{data.position}</span>
              <span className="stepTitle">{data.question}</span>
            </AccordionSummary>
            <AccordionDetails
              className="stepAccordionDetails"
              style={{ textAlign: "left" }}
            >
              {data.answer}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    });

    return (
      <div>
        <BackButton />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="myAccountContainer"
        >
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ marginTop: "3em" }}
          >
            <div className="avtarLogo" />
            <SubTitle titleText="Mon compte" customeColor="#A7B6C6" />
            <div className="grayArrowIcon" />
            <SubTitle titleText="Questions fréquemment posées" />
          </Grid>
          <hr
            style={{
              backgroundColor: "#FFFFFF",
              height: 1,
            }}
          />

          {faqHtml}
        </Grid>
      </div>
    );
  }
}

export default FAQ;
