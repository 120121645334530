import React from 'react';
import PropTypes from 'prop-types';

const SubTitle = (props) => <div className="subTitle" style={{color: props.customeColor}}>{props.titleText}</div>;

export default SubTitle;
SubTitle.propTypes = {
  titleText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  customeColor: PropTypes.string,
};
