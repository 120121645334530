import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";

import {
  checkEmailisValid,
  handleResponseCode,
  handleException,
  showAlert,
} from "../../utility";
import { altisUrl } from "../../constants";
import { POSTAltis } from "../../apis/ServerApis";
import CustomInputField from "../../hoc/CustomInputField";
import AppLogo from "../../hoc/AppLogo";
import SubTitle from "../../hoc/SubTitle";
import BlueButton from "../../hoc/BlueButton";
import GrayButton from "../../hoc/GrayButton";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "", // 'test@example.com',
      errorEmail: false,
      emailErrorMessage: "",
      goToLogin: false,
    };
  }

  componentDidMount = () => {
    localStorage.clear();
    document.getElementById("leftsidebar").style.display = "none";
  };

  // Input field handle - Email
  handleEmailChange = (newValue) => {
    this.setState({
      email: newValue,
      errorEmail: false,
      emailErrorMessage: "",
    });
  };

  handleGoToLogin = () => {
    this.setState({
      goToLogin: true,
    });
  };

  callApiToForgetPassword = (emailParam) => {
    const self = this;
    const params = {
      email: emailParam,
    };
    POSTAltis(`${altisUrl}password/forget`, params)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          if (
            response &&
            typeof response.success !== "undefined" &&
            response.success
          ) {
            self.setState({
              goToLogin: true,
            });
            if (response.message) {
              showAlert("Succès", response.message);
            }
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!response.success && response.errors && response.errors.length) {
            showAlert("Erreur", response.errors.join(" "));
          } else if (response.message) {
            showAlert("Erreur", response.message);
          } else {
            showAlert("Erreur", "Erreur interne du serveur");
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        handleException(error);
      });
  };

  handleContinue = () => {
    // Validation for Login Inputs
    if (this.state.email === "") {
      this.setState({
        errorEmail: true,
        emailErrorMessage: "E-mail ne peut pas être vide.",
      });
      return;
    }

    if (!checkEmailisValid(this.state.email)) {
      this.setState({
        errorEmail: true,
        emailErrorMessage: "L'email n'est pas valide",
      });
      return;
    }

    this.callApiToForgetPassword(this.state.email);
  };

  render() {
    if (this.state.goToLogin) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="formContainer"
        >
          <AppLogo />
          <SubTitle titleText="COSTING PLATFORM" />
          <SubTitle titleText="Mot de passe oublié" />
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justify="center"
            xs={6}
            style={{ marginTop: "3em" }}
          >
            <Grid item xs={5} sm={3}>
              <div className="formLabel">E-mail*</div>
            </Grid>
            <Grid item xs={6} sm={9}>
              <CustomInputField
                fieldName="E-mail or Username"
                fieldType="email"
                fieldValue={this.state.email}
                isError={this.state.errorEmail}
                errorMessage={this.state.emailErrorMessage}
                textChange={this.handleEmailChange}
                keyUpEvent={this.handleKeyUpEvent}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justify="center"
            xs={6}
            style={{ marginTop: "1em" }}
          >
            <Grid item xs={4} sm={4}>
              <div className="grayLabel">* Renseignements obligatoires</div>
            </Grid>
            <Grid item xs={4} sm={4}>
              <GrayButton
                btnName="Aller à la page de connexion"
                btnClick={this.handleGoToLogin}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <BlueButton btnName="Valider" btnClick={this.handleContinue} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ForgetPassword;
