import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Redirect } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PropTypes from "prop-types";

import SubTitle from "../../hoc/SubTitle";
import BlueButton from "../../hoc/BlueButton";
import { GETAltis, PUTAltis } from "../../apis/ServerApis";
import {
  handleResponseCode,
  handleException,
  showAlert,
  handleFailure,
} from "../../utility";
import { altisUrl } from "../../constants";

class BasePriceSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: ["1 ère année", "2 ère année", "3 ère année", "4 ère année", "5 ère année"],
      basePriceData: [],

      navigateToContact: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.location.state || {};

    if (id) {
      const url = `${altisUrl}configs?user_id=${id}`;

      GETAltis(url)
        .then((response) => {
          console.log(response);
          if (handleResponseCode(response)) {
            console.log(response.data);
            this.setState({
              basePriceData: response.data,
            });
          } else {
            handleFailure(response);
          }
        })
        .catch((error) => {
          handleException(error);
        });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const index = Number(e.target.id);

    const [finalName, finalValue] = this.concateTwoValue(name, value, index);

    console.log("finalValue", finalValue);
    console.log("name1:", finalName);
    // eslint-disable-next-line react/no-access-state-in-setstate
    const basePriceData = [...this.state.basePriceData];
    basePriceData[index] = {
      ...basePriceData[index],
      [finalName]: finalValue,
    };
    this.setState({ basePriceData });
  };

  concateTwoValue = (name, value, index) => {
    let name1 = name;
    let finalValue = "";
    const noOfUnderscore = name1.split("_").length - 1;
    if (noOfUnderscore === 2) {
      const lastIndex = name1.lastIndexOf("_");
      name1 = name1.substring(0, lastIndex);
      const value1 = this.state.basePriceData[index][name1];
      if (value1 !== null && value1.indexOf(":::") > -1) {
        const firstValue = value1.split(":::")[0];
        finalValue = `${firstValue}:::${value}`;
      } else if (value1 !== null) {
        finalValue = `${value1}:::${value}`;
      } else {
        finalValue = `:::${value}`;
      }
    } else {
      const value1 = this.state.basePriceData[index][name1];
      if (value1 !== null && value1.indexOf(":::") > -1) {
        const secondValue = value1.split(":::")[1];
        finalValue = `${value}:::${secondValue}`;
      } else {
        finalValue = value;
      }
    }

    // console.log("No Of Underscore:", this.state.basePriceData[index].name);
    console.log("finalValue", finalValue);
    console.log("name1:", name1);
    return [name1, finalValue];
  };

  handleContinue = () => {
    const url = `${altisUrl}configs/submitConfig`;
    const appParams = {
      user_id: this.props.location.state.id,
      configs: this.state.basePriceData,
    };
    PUTAltis(url, appParams)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          showAlert("Succès", "Données enregistrées avec succès");
          this.setState({
            navigateToContact: true,
          });
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  getValueForFirstPart = (value) => {
    if (value !== null && value.indexOf(":::") > -1) {
      return value.split(":::")[0];
    }
    return value;
  };

  getValueForSecondPart = (value) => {
    if (value !== null && value.indexOf(":::") > -1) {
      return value.split(":::")[1];
    }
    return 0;
  };

  // Go to Previous Page
  goToPreviousPage = () => {
    this.setState({
      navigateToContact: true,
    });
  };

  render() {
    if (this.state.navigateToContact) {
      return <Redirect to="/mycontacts" />;
    }
    console.log("BasePriceData :", this.state.basePriceData);
    const basePriceList = this.state.basePriceData.map((element, index) => {
      return (
        <div>
          <div className="contactRowContainerBaseDePrix">
            <span className="contactColumn contactCanton">
              {this.state.year[index]}
            </span>
            <span className="contactColumn contactName">
              <OutlinedInput
                className="manualInput settingsInputField"
                name="situation_marche"
                id={index}
                value={element.situation_marche}
                onChange={this.handleChange}
                labelWidth={0}
              />
            </span>
            <span className="contactColumn contactName">
              <OutlinedInput
                className="manualInput settingsInputField"
                name="couverture_1"
                id={index}
                value={this.getValueForFirstPart(element.couverture_1)}
                onChange={this.handleChange}
                labelWidth={0}
              />
            </span>
            <span className="contactColumn contactName">
              <OutlinedInput
                className="manualInput settingsInputField"
                name="couverture_2"
                id={index}
                value={this.getValueForFirstPart(element.couverture_2)}
                onChange={this.handleChange}
                labelWidth={0}
              />
            </span>
            <span className="contactColumn contactName">
              <OutlinedInput
                className="manualInput settingsInputField"
                name="couverture_3"
                id={index}
                value={this.getValueForFirstPart(element.couverture_3)}
                onChange={this.handleChange}
                labelWidth={0}
              />
            </span>
            <span className="contactColumn contactName">
              <OutlinedInput
                className="manualInput settingsInputField"
                name="couverture_4"
                id={index}
                value={this.getValueForFirstPart(element.couverture_4)}
                onChange={this.handleChange}
                labelWidth={0}
              />
            </span>
          </div>

          <div className="contactRowContainer">
            <span className="contactColumn contactCanton" />
            <span className="contactColumn contactName" />
            <span className="contactColumn contactName">
              <OutlinedInput
                className="manualInput settingsInputField"
                name="couverture_1_1"
                id={index}
                value={this.getValueForSecondPart(element.couverture_1)}
                onChange={this.handleChange}
                labelWidth={0}
              />
            </span>
            <span className="contactColumn contactName">
              <OutlinedInput
                className="manualInput settingsInputField"
                name="couverture_2_1"
                id={index}
                value={this.getValueForSecondPart(element.couverture_2)}
                onChange={this.handleChange}
                labelWidth={0}
              />
            </span>
            <span className="contactColumn contactName">
              <OutlinedInput
                className="manualInput settingsInputField"
                name="couverture_3_1"
                id={index}
                value={this.getValueForSecondPart(element.couverture_3)}
                onChange={this.handleChange}
                labelWidth={0}
              />
            </span>
            <span className="contactColumn contactName">
              <OutlinedInput
                className="manualInput settingsInputField"
                name="couverture_4_1"
                id={index}
                value={this.getValueForSecondPart(element.couverture_4)}
                onChange={this.handleChange}
                labelWidth={0}
              />
            </span>
          </div>
        </div>
      );
    });

    return (
      <div>
        <IconButton
          aria-label="back"
          class="backBtn"
          onClick={this.goToPreviousPage}
        >
          <ArrowBackIcon />
          Retour
        </IconButton>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="myAccountContainer"
        >
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ marginTop: "3em" }}
          >
            <div className="avtarLogo" />
            <SubTitle titleText="Mon compte" customeColor="#A7B6C6" />
            <div className="grayArrowIcon" />
            <SubTitle titleText="Base De Prix" />
          </Grid>
          <hr
            style={{
              backgroundColor: "#FFFFFF",
              height: 1,
            }}
          />

          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="center"
            style={{ height: "100%" }}
          >
            <div className="contactRowContainer">
              <span className="contactColumnHeader contactCanton configHeaders">
                Années
              </span>
              <span className="contactColumnHeader contactName configHeaders">
                Situation Marché
              </span>
              <span className="contactColumnHeader contactName configHeaders">
                Couverture 1
              </span>
              <span className="contactColumnHeader contactName configHeaders">
                Couverture 2
              </span>
              <span className="contactColumnHeader contactName configHeaders">
                Couverture 3
              </span>
              <span className="contactColumnHeader contactName configHeaders">
                Couverture 4
              </span>
            </div>
            {basePriceList}
          </Grid>
          <div className="myInfoRow" style={{ marginLeft: "0px" }}>
            <BlueButton
              btnName="Valider"
              className="marginLeftZero"
              btnClick={this.handleContinue}
            />
          </div>
        </Grid>
      </div>
    );
  }
}
BasePriceSetting.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.arrayOf({
      id: PropTypes.number.isRequired,
    }),
  }),
};

BasePriceSetting.defaultProps = {
  location: {
    state: {
      id: 1,
    },
  },
};
export default BasePriceSetting;
