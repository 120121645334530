import * as actionType from "../actions/actionTypes";
import updateObject from "../utility";

// Set Initial State
const intialState = {
    userDetail: null,
    error: null,
    isEditUser: false,
};

const user = (state = intialState, action) => {
    switch (action.type) {
        case actionType.GET_USER_DETAIL_SUCCESS:
            return updateObject(state, { userDetail: action.userDetail });
        case actionType.EDIT_USER_DETAIL_SUCCESS:
            return updateObject(state, {
                userDetail: action.userDetail,
                isEditUser: true,
            });
        case actionType.GET_USED_DETAIL_FAIL:
            return updateObject(state, { error: true });
        case actionType.HIDE_SUCCESSPOPUP_EDIT_USER:
            return updateObject(state, { isEditUser: false });
            
        default:
            return state;
    }
};

export default user;
